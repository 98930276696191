import request from '../utils/request'
import qs from 'qs'

let api = {
  //免登录gis
  gisLoginFree(params) {
    return request({
      url: 'caict/userNew/gisLoginFree',
      method: 'get',
      params
    })
  },
  // 登录接口
  loginApi(data) {
    return request({
      url: '/caict/userNew/login',
      method: 'post',
      data
    })
  },
  // 短信验证码登录
  loginBySms(data) {
    return request({
      url: '/caict/userNew/smsLogin',
      method: 'post',
      data
    })
  },
  // 短信验证码 获取验证码
  getLoginCode(data) {
    return request({
      url: '/caict/userNew/sendPhoneCode',
      method: 'post',
      data
    })
  },

  // 报告页菜单
  getReportListForIndex(params) {
    return request({
      url: '/caict/statisticReport/getReportListForIndex',
      method: 'get',
      params
    })
  },
  // 发送邮件
  sendReportMail(data) {
    return request({
      url: '/caict/statisticReport/sendReportMail',
      method: 'post',
      data
    })
  },

  // 获取上次邮件发送地址字符串
  getLastReportMail(params) {
    return request({
      url: '/caict/statisticReport/getLastReportMail',
      method: 'post',
      params
    })
  },

  // 获取页面内容
  getReportSectionContentByFirstLevel(params) {
    return request({
      url: 'caict/statisticReport/getReportSectionContentByFirstLevel',
      method: 'get',
      params
    })
  },
  // 保存页面
  savePreprocessReportHtml(data) {
    return request({
      url: 'caict/statisticReport/savePreprocessReportHtml',
      method: 'post',
      data
    })
  },
  // 获取树形数据
  getReportIndexListByReportId(params) {
    return request({
      url: '/caict/statisticReport/getReportIndexListByReportId',
      method: 'get',
      params
    })
  },
  // 获取home页面文档
  getReportHtmlPages(params) {
    return request({
      url: '/caict/statisticReport/getReportHtmlPages',
      method: 'get',
      params
    })
  },
  getZone(params) {
    return request({
      url: '/caict/areaAnalysis/getZone',
      method: 'get',
      params
    })
  },

  // 获取 街道数据
  getTownByZoneId(data) {
    return request({
      url: '/caict/userAnalysis/getTownByZoneId',
      method: 'post',
      data
    })
  },
  // 获取 自然村数量
  getVillageCount() {
    return request({
      url: '/caict/boundaryData/getVillageCount',
      method: 'get',
    })
  },
  // 获取 自然村数据
  getVillageByTownId(data) {
    return request({
      url: '/caict/userAnalysis/getVillageByTownId',
      method: 'post',
      data
    })
  },

  // 获取 搜索词数据
  getSearchWordList(data) {
    return request({
      url: '/caict/userAnalysis/getKeyWordByParam',
      method: 'post',
      data
    })
  },

  // 获取 搜索词 top数据
  getAllKeyWordsTop10(data) {
    return request({
      url: '/caict/userAnalysis/getAllKeyWordsTop10',
      method: 'post',
      data
    })
  },

  // 增加自定义场景
  addScene(params) {
    return request({
      url: '/caict/customSceneAnalysis/addScene',
      method: 'get',
      params
    })
  },
  // 删除自定义场景
  deleteScene(params) {
    return request({
      url: '/caict/customSceneAnalysis/deleteScene',
      method: 'get',
      params
    })
  },
  // 自定义场景列表
  getScene(params) {
    return request({
      url: '/caict/customSceneAnalysis/getScene',
      method: 'get',
      params
    })
  },
  // 根据场景查看数据分析
  getSceneData(params) {
    return request({
      url: '/caict/customSceneAnalysis/getSceneData',
      method: 'get',
      params
    })
  },
  // 修改图表分析是否生成
  updateDataAnalysis(params) {
    return request({
      url: '/caict/customSceneAnalysis/updateDataAnalysis',
      method: 'get',
      params
    })
  },
  updateScene(params) {
    return request({
      url: '/caict/customSceneAnalysis/updateScene',
      method: 'get',
      params
    })
  },
  getStationBusinessHallPage(params) {
    return request({
      url: '/caict/reference/getStationBusinessHallPage',
      method: 'get',
      params
    })
  },
  getReferenceByType(params) {
    return request({
      url: '/caict/reference/getReferenceByType',
      method: 'get',
      params
    })
  },

  // 保存 对标城市列表
  saveMarkCityList(data) {
    return request({
      url: '/caict/markCity/saveMarkCityList',
      method: 'post',
      data
    })
  },

  // 获取 对标城市列表
  getMarkCityList(data) {
    return request({
      url: '/caict/markCity/getMarkCityList',
      method: 'post',
      data
    })
  },

  // 删除 某一对标城市
  delMarkCity(data) {
    return request({
      url: '/caict/markCity/delMarkCity',
      method: 'post',
      data
    })
  },

  // 获取 可选对标城市列表库
  getProvinceCity(data) {
    return request({
      url: '/caict/markCity/getProvinceCity',
      method: 'post',
      data
    })
  },

  // 获取 报告是否生成的状态  0 无生成
  getReportByStatus(data) {
    return request({
      url: '/caict/statisticReport/getReportByStatus',
      method: 'post',
      data
    })
  },

  // 获取上次导入工参时间
  getReferenceLastTime(data) {
    return request({
      url: '/caict/reference/getReferenceLastTime',
      method: 'post',
      data
    })
  },

  // 获取基础配置 配置列表
  getCityMncConfig(data) {
    return request({
      url: '/caict/cityMncConfig/getCityMncConfig',
      method: 'post',
      data
    })
  },

  // 更新 基础配置 的配置
  updateCityMncConfig(data) {
    return request({
      url: '/caict/cityMncConfig/updateCityMncConfig',
      method: 'post',
      data
    })
  },

  // 更新 跳转路由的操作
  updateOperatLog(params) {
    return request({
      url: '/caict/userNew/operatLog',
      method: 'get',
      params
    })
  },

  // 获取 跳转路由的操作列表
  getQueryOperatLogList(params) {
    return request({
      url: '/caict/userNew/queryOperatLogList',
      method: 'get',
      params
    })
  },

  // 获取 省市列表
  getAllProvinceCity(params) {
    return request({
      url: '/caict/superAccountSetting/getAllProvinceCity',
      method: 'get',
      params
    })
  },

  // 获取 场景录入 搜索词列表
  getMapSearch(params) {
    return request({
      url: '/caict/superAccountSetting/getMapSearch',
      method: 'get',
      params
    })
  },

  // 新增 场景录入
  addArea(data) {
    return request({
      url: '/caict/superAccountSetting/addArea',
      method: 'post',
      data
    })
  },

  // 获取当前区县列表
  getZoneByCity(params) {
    return request({
      url: '/caict/superAccountSetting/getZoneByCity',
      method: 'post',
      params
    })
  },

  // 获取邮箱数据
  getEmailManage(data) {
    return request({
      url: '/caict/userNew/queryEmailManage',
      method: 'post',
      data
    })
  },

  // 保存邮箱数据
  saveEmailManage(data) {
    return request({
      url: '/caict/userNew/saveEmailManage',
      method: 'post',
      data
    })
  },

  // 自定义场景 获取场景类型
  getAreaType(params) {
    return request({
      url: '/caict/superAccountSetting/getAreaTypeNew',
      method: 'get',
      params
    })
  },

  // 自定义场景 更新场景边界
  updateArea(data) {
    return request({
      url: '/caict/superAccountSetting/updateArea',
      method: 'post',
      data
    })
  },

  // 自定义场景 获取场景边界列表
  queryAreaList(data) {
    return request({
      url: '/caict/superAccountSetting/queryAreaList',
      method: 'post',
      data
    })
  },
  // 自定义场景 查询所有场景
  queryCustomizeAreaBoundary(data) {
    return request({
      url: '/caict/superAccountSetting/queryCustomizeAreaBoundary',
      method: 'post',
      data
    })
  },
  // 自定义场景 获取场景边界列表
  queryCustomizeAreaPage(data) {
    return request({
      url: '/caict/superAccountSetting/queryCustomizeAreaPage',
      method: 'post',
      data
    })
  },

  // 自定义场景 删除场景边界
  delArea(data) {
    return request({
      url: '/caict/superAccountSetting/delArea',
      method: 'post',
      data
    })
  },
  // 自定义场景 生成统计
  statisticsScene(data) {
    return request({
      url: '/caict/superAccountSetting/statisticsScene',
      method: 'post',
      data
    })
  },

  // 获取 自定义场景 当前区
  getZoneByBoundaries(params) {
    return request({
      url: '/caict/superAccountSetting/getZoneByBoundaries',
      method: 'get',
      params
    })
  },

  // 获取 网格列表
  getNetworkGridByCity(params) {
    return request({
      url: '/caict/superAccountSetting/getNetworkGridByCity',
      method: 'get',
      params
    })
  },
  // 获取 微格列表
  getMicroByCity(params) {
    return request({
      url: '/caict/superAccountSetting/getMicroByCity',
      method: 'get',
      params
    })
  },
  // 获取树形数据
  getReportTableListByReportId(params) {
    return request({
      url: '/caict/statisticReport/getReportTableListByReportId',
      method: 'get',
      params
    })
  },

  //获取表格数据
  queryTableData(params) {
    return request({
      url: '/caict/downReport/queryTableData',
      method: 'get',
      params
    })
  },
  //获取圈画表格数据
  queryDrawData(params) {
    return request({
      url: '/caict/superAccountSetting/queryDrawData',
      method: 'post',
      data: params
    })
  },

  //商企撒点
  getAreaPointList(params) {
    return request({
      url: '/caict/companyEnterprise/getAreaPointList',
      method: 'post',
      data: params
    })
  },
  // 企业信息tab
  getCityCompanyTypeConfig(params) {
    return request({
      url: '/caict/superAccountSetting/getCityCompanyTypeConfig',
      method: 'post',
      data: params
    })
  },
  // 保存页面
  saveHighSchoolPreprocessReportHtml(data) {
    return request({
      url: 'caict/statisticHighSchoolReport/saveHighSchoolPreprocessReportHtml',
      method: 'post',
      data
    })
  },

  // 获取页面内容
  getHighSchoolReportSectionContentByFirstLevel(params) {
    return request({
      url: 'caict/statisticHighSchoolReport/getHighSchoolReportSectionContentByFirstLevel',
      method: 'get',
      params
    })
  },
  // 南京广电线上sso接口
  getSsoToken(params) {
    return request({
      url: '/caict/userNew/tokenAnasyis',
      method: 'get',
      params
    })
  },
  // 获取聚类场景列表
  getDwCluterAreaInfoList(data) {
    return request({
      url: 'caict/superAccountSetting/getDwCluterAreaInfoList',
      method: 'post',
      data
    })
  },
  // 删除聚类场景
  delDwCluterAreaInfo(params) {
    return request({
      url: 'caict/superAccountSetting/delDwCluterAreaInfo',
      method: 'get',
      params
    })
  },
  // 新增聚类场景
  insertDwCluterAreaInfo(data) {
    return request({
      url: 'caict/superAccountSetting/insertDwCluterAreaInfo',
      method: 'post',
      data
    })
  },
  // 修改聚类场景
  updateDwCluterAreaInfo(data) {
    return request({
      url: 'caict/superAccountSetting/updateDwCluterAreaInfo',
      method: 'post',
      data
    })
  },
  // 获取公共级联框数据
  getDropList(params) {
    return request({
      url: '/caict/userAnalysisNew/getDropList',
      method: 'get',
      params
    })
  },
  //改版 获取搜索词 top数据
  getSearchList(data) {
    return request({
      url: '/caict/userAnalysisNew/getSearchList',
      method: 'post',
      data
    })
  },
  //改版 获取用户总览数据
  getUserSummaryData(data) {
    return request({
      url: '/caict/userAnalysisNew/getUserSummaryData',
      method: 'post',
      data
    })
  },
  //新增工参
  addOneReference(data) {
    return request({
      url: '/caict/reference/addOneReference',
      method: 'post',
      data
    })
  },
  //修改工参
  updateReference(data) {
    return request({
      url: '/caict/reference/updateReference',
      method: 'post',
      data
    })
  },
  //删除工参
  deleteReference(data) {
    return request({
      url: '/caict/reference/deleteReference',
      method: 'post',
      data
    })
  },
    //新增营业厅
    addOneStationBusinessHall(data) {
      return request({
        url: '/caict/reference/addOneStationBusinessHall',
        method: 'post',
        data
      })
    },
    //修改营业厅
    updateStationBusinessHall(data) {
      return request({
        url: '/caict/reference/updateStationBusinessHall',
        method: 'post',
        data
      })
    },
    //删除营业厅
    deleteStationBusinessHall(data) {
      return request({
        url: '/caict/reference/deleteStationBusinessHall',
        method: 'post',
        data
      })
    },

    // 自定义场景  场景类型下拉框数据
    getCityAreaTypeList(data) {
      return request({
        url: '/caict/superAccountSetting/getCityAreaTypeList',
        method: 'post',
        data
      })
    },

    //浏览器token登录
    loginSubject(params) {
      return request({
        url: '/caict/userNew/loginSubject',
        method: 'get',
        params
      })
    },

    // 获取阈值设置
    getCityAreaConfigInfo(data) {
      return request({
        url: '/caict/boundaryData/getCityAreaConfigInfo',
        method: 'post',
        data
      })
    },

    // 更改阈值设置
    updateCityAreaConfigInfo(data) {
      return request({
        url: '/caict/boundaryData/updateCityAreaConfigInfo',
        method: 'post',
        data
      })
    },

    // 重置阈值设置
    delCityAreaConfigInfo(data) {
      return request({
        url: '/caict/boundaryData/delCityAreaConfigInfo',
        method: 'post',
        data
      })
    },

    // 新商企 
    queryCompanyInfoList(data) {
      return request({
        url: '/caict/companyEnterprise/queryCompanyInfoList',
        method: 'post',
        data
      })
    },

    // 新商企 
    generateWorkOrderList(data) {
      return request({
        url: '/caict/companyEnterprise/generateWorkOrderList',
        method: 'post',
        data
      })
    },
}

export default api
