const getters = {
  userInfo: state => state.home.userInfoVo,
  formData: state => state.gis.formData,
  // initMapFlag: state => state.gis.initMapFlag,
  // gis: state => state.gis.map,
  // context: state => state.gis.context,
  // dict: state => state.gis.dict,
  // offset: state => state.gis.offset,
  // radioFlag: state => state.gis.radioFlag,
  // clickId: state => state.gis.clickId,
  // clickItem: state => state.gis.clickItem,
  // compareMap: state => state.gis.compareMap,
  changeArea: state => state.gis.changeArea,
  getLocateCell:state => state.gis.getLocateCell,
  zoomToBigGrid: state => state.gis.zoomToBigGrid,
  // caches: state => state.gis.caches,
  // isCollapse: state => state.home.isCollapse,
  // menuType: state => state.home.menuType
}
export default getters
