<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      windowHeight: 0
    }
  },
  created() {
    this.windowHeight = window.innerHeight
    console.log(window.innerHeight)

    window.addEventListener('resize', () => {
      this.windowHeight = window.innerHeight
    })
  },
  mounted() {
    let userInfoVo = JSON.parse(localStorage.getItem('userInfoVo'))
    const mnc = userInfoVo ? userInfoVo.mnc : null
    if (mnc == 0) {
      document.body.className = 'custome-mobile'
    } else if (mnc == 1) {
      document.body.className = 'custome-unicom'
    } else {
      document.body.className = 'custome-telecom'
    }
  }
}
</script>

<style lang="scss">
html,
body {
  * {
    padding: 0;
    margin: 0;
  }
}
html,
body {
  width: 100%;
  height: 100%;
  position: relative;
  // overflow: hidden;
}
#app {
  font-family: '微软雅黑', SimSun, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #41485d;
  line-height: 20px;
  width: 100%;
  height: 100%;
  position: relative;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
