const state = {
  formData: {},
  map: {
    areaData: [],
    gridData: [],
    areaData5G: [],
    gridData5G: []
  },
  initMapFlag: true,
  context: {
    form: {},
    input: ''
  },
  dict: [],
  offset: '0-1',
  radioFlag: 0,
  clickId: '',
  clickItem: {
    type: '',
    data: {}
  },
  compareMap: {
    data: {}
  },
  changeArea: {
    type: '',
    data: '',
    level: '',
    temporaryAreaId:''
  },
  getLocateCell: {
    id: '',
    clickType:''
  },
  zoomToBigGrid: {
    id: ''
  },
  caches: {
    market: {
      grid: {
        g4: [],
        g5: []
      },
      area: {
        g4: [],
        g5: []
      }
    },
    other: {
      grid: {
        g4: [],
        g5: []
      },
      area: {
        g4: [],
        g5: []
      }
    }
  }
}

const mutations = {
  CHANGE_FORM: (state, data) => {
    state.formData = data
  },
  STORE_MAP_4G: (state, data) => {
    state.map.areaData.push(data)
  },
  STORE_MAP_GRID_4G: (state, data) => {
    state.map.gridData.push(data)
  },
  STORE_MAP_5G: (state, data) => {
    state.map.areaData5G.push(data)
  },
  STORE_MAP_GRID_5G: (state, data) => {
    state.map.gridData5G.push(data)
  },
  IS_FIRST: (state, data) => {
    state.initMapFlag = data
  },
  STORE_CACHE: (state, data) => {
    const { key = '', value = null, path = '', word = '' } = data
    state.caches[path][key][word].push(value)
  },
  CHANGE_CONTEXT: (state, data) => {
    state.context = data
  },
  GET_DICTIONARY: (state, data) => {
    state.dict = data
  },
  CLICK_ID: (state, data) => {
    state.clickId = data
  },
  CLICK_ITEM: (state, data) => {
    state.clickItem = data
  },
  CHANGE_RADIO: (state, data) => {
    state.offset = data
    ++state.radioFlag
  },
  COMPARE_MAP: (state, data) => {
    state.compareMap = data
  },
  CHANGE_AREA: (state, data) => {
    state.changeArea = data
  },
  ZOOM_TO_BIG_GRID: (state, data) => {
    state.zoomToBigGrid = data
  },
  GET_LOCATE_CELL:(state, data) => {
    console.log( data );
    state.getLocateCell = data
  },
}

const actions = {
  changeForm({ commit }, data) {
    commit('CHANGE_FORM', data)
  },
  changeRadio({ commit }, data) {
    commit('CHANGE_RADIO', data)
  },
  isFirst({ commit }, data) {
    commit('IS_FIRST', data)
  },
  storeCache({ commit }, data) {
    commit('STORE_CACHE', data)
  },
  clickId({ commit }, data) {
    commit('CLICK_ID', data)
  },
  clickItem({ commit }, data) {
    commit('CLICK_ITEM', data)
  },
  changeContext({ commit }, data) {
    commit('CHANGE_CONTEXT', data)
  },
  getDictionary({ commit }, data) {
    commit('GET_DICTIONARY', data)
  },
  storeMap4G({ commit }, data) {
    commit('STORE_MAP_4G', data)
  },
  storeMap5G({ commit }, data) {
    commit('STORE_MAP_5G', data)
  },
  storeMapGrid4G({ commit }, data) {
    commit('STORE_MAP_GRID_4G', data)
  },
  storeMapGrid5G({ commit }, data) {
    commit('STORE_MAP_GRID_5G', data)
  },
  compareMap({ commit }, data) {
    commit('COMPARE_MAP', data)
  },
  changeArea({ commit }, data) {
    commit('CHANGE_AREA', data)
  },
  zoomToBigGrid({ commit }, data) {
    commit('ZOOM_TO_BIG_GRID', data)
  },
  getLocateCell({ commit }, data) {
    commit('GET_LOCATE_CELL', data)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
