import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import api from './apis/index'
import echarts from 'echarts'
import '../public/font/iconfont.css'
import './styles/index.scss'
import './utils/directive.js'
// main.js 全局引入lodash
import _ from 'lodash'
import './icons' // icon
import { Plugin } from 'vue-fragment'

Vue.use(Plugin)

Vue.use(ElementUI, {
  size: 'medium'
})
Vue.prototype.api = api
Vue.prototype.echarts = echarts

Vue.prototype._ = _

Vue.config.productionTip = false

// 时间戳过滤器
Vue.filter('dateFormat', (dataStr, format) => {
  if (dataStr) {
    var time = new Date(dataStr)

    function timeAdd0(str) {
      if (str < 10) {
        str = '0' + str
      }
      return str
    }
    var y = time.getFullYear()
    var m = time.getMonth() + 1
    var d = time.getDate()
    var h = time.getHours()
    var mm = time.getMinutes()
    var s = time.getSeconds()
    if (format == 'M') {
      return (
        y +
        '.' +
        timeAdd0(m) +
        '.' +
        timeAdd0(d) +
        ' ' +
        timeAdd0(h) +
        ':' +
        timeAdd0(mm)
      )
    }

    return (
      y +
      '.' +
      timeAdd0(m) +
      '.' +
      timeAdd0(d) +
      ' ' +
      timeAdd0(h) +
      ':' +
      timeAdd0(mm) +
      ':' +
      timeAdd0(s)
    )
  } else {
    return ''
  }
})

// 按钮防抖
Vue.directive('debounce',{
  inserted(el,binding){
    el.addEventListener('click',e=>{
      el.classList.add('is-disabled')
      el.disabled = true
      setTimeout(() => {
        el.disabled = false
        el.classList.remove('is-disabled')
      },800)
    })
  }
})

// console.log(process.env.NODE_ENV);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
