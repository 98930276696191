import Vue from "vue";
// v-dialogDrag: 弹窗拖拽
Vue.directive("drag", {
  bind: function (el, binding, vnode, oldVnode) {
    //使用此自定义指令需要给弹窗的头部元素添加drag，class名
    // let oDiv = el.querySelector('.title'); // 获取当前div元素标题头部元素
    //若想点击标题拖拽 将el换成oDiv
    el.style.cursor = "move";
    el.onmousedown = (e) => {
      // 算出鼠标相对元素的位置
      let disX = e.clientX - el.offsetLeft;
      let disY = e.clientY - el.offsetTop;
      console.log("本身的宽高", el.clientHeight, el.clientWidth);

      document.onmousemove = (e) => {
        // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        let left = e.clientX - disX;
        let top = e.clientY - disY;

        if (left <= 0) {
          left = 0;
        } else if (
          left >=
          document.documentElement.clientWidth -
            el.clientWidth +
            el.clientWidth * 0.5
        ) {
          left =
            document.documentElement.clientWidth -
            el.clientWidth +
            el.clientWidth * 0.5;
        }
        if (top <= 0) {
          top = 0;
        } else if (
          top >=
          document.documentElement.clientHeight -
            el.clientHeight +
            el.clientHeight * 0.5
        ) {
          top =
            document.documentElement.clientHeight -
            el.clientHeight +
            el.clientHeight * 0.5;
        }

        el.style.left = left + "px";
        el.style.top = top + "px";
      };

      document.onmouseup = (e) => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    };
  },
});

