// 引入 gis模块 方法
import {
  getCompareDataList,
  getBounding,
  getStation,
  getLonAnalysis,
  getAZAnalysis
} from '@/apis/gis'

import api from '@/apis/index'

import {
  addGisData,
  delGisData,
  addGisCommunity,
  delGisCommunity, addGisCell, addGisCommunityRight
} from '@/utils/gisObjFun.js'

const state = {
  // 地图数据
  gisObj: null, //地图对象
  gisMapMounted: false,
  dictionary: [], //当前城市所有区域边界数组
  iframeUrl: 'index', //地图地址
  boundaryList: [], //存储边界值值，缓存
  businessHallLayer: null, //营业厅图层
  stationLayer: null, //车站图层
  busData: {
    a: null,
    b: null,
    c: null
  }
}

const mutations = {
  setGisObj(state, data) {
    state.gisObj = data
    // 给车站和营业厅创建图层
    if (data !== null && data.createCustomLayer) {
      this.commit('gisPage/setBusinessHallLayer', data.createCustomLayer())
      this.commit('gisPage/setBusinessHallLayer', data.createCustomLayer())
    }
  },
  setGisMapMounted(state, data) {
    state.gisMapMounted = data
  },
  setDictionary(state, data) {
    state.dictionary = data
  },
  setIframeUrl(state, data) {
    if (data !== 'index' && this.state.gisForm.compare) {
      this.commit('gisForm/setCompare', false)
    }
    // 关闭筛选列表选中
    if (data !== 'index' && this.state.gisForm.userLineId !== null)
      this.commit('gisForm/setUserLineId', {data: null, jumpMap: false})
    // 关闭地图加载完成的 标志
    if (data !== state.iframeUrl) this.commit('gisPage/setGisMapMounted', false)
    if (state.iframeUrl == data) return false //-t
    state.iframeUrl = data
  },
  setBoundaryList(state, data) {
    state.boundaryList = data
  },
  setBusinessHallLayer(state, data) {
    state.busData = data
  },
  setStationLayer(state, data) {
    state.stationLayer = data
  }
}

const actions = {
  changeFormData({commit}, data) {
    console.log(this, api, data)

    api
      .getZone({
        cityId: this.state.home.userInfoVo.cityId
      })
      .then(res => {
        console.log(res)
        let newData = this.state.gisPage.formData
        commit('setFormData', newData)
      })
  },
  // 用于获取 边界结果后的回调 保存边界数据并且调用地图加载方法
  changeBoundaryList(
    {commit},
    {data = null, oldFormData, gridData, oldStatus, boundary}
  ) {
    if (!data && this.state.gisPage.iframeUrl != null) {
      let gisObj = this.state.gisPage.gisObj
      // gisObj.hideLeftHeatMap()
      //  console.log( this, oldFormData, gridData, oldStatus );
      // 如果返回的是空数据则直接跳出方法；
      if (!gridData.data) return
      let list = this.state.gisPage.boundaryList
      let {cityId, zoneId, mnc, ptMonth, network, roleType, townId, companyId, networkGridId} = oldFormData
      if (list.length > 5) {
        list = list.slice(1, list.length)
      }
      // 首先判断 network 是否是小区参数
      if (network.split('+')[0] == 2) {
        // 判断与当前选项是否有冲突
        let communityList = this.state.gisForm.communityList
        if (communityList.find(v => v == network) !== undefined) {
          // 继续执行 调用gis方法
          addGisCommunity(gridData, network, gisObj)
        } else {
          // 取消执行 隐藏图层
          let delStatus = network.split('+')[1] == 4 ? 1 : 2
          delGisCommunity(delStatus, gisObj)
        }
      } else if (network.split('+')[0] == 12) {
        if(oldStatus == 2){
          addGisCommunityRight(gridData, network, gisObj)
        }else{
          addGisCommunity(gridData, network, gisObj)
        }
      } else {
        // 判断与当前选项是否有冲突
        // let currentNetwork = this.state.gisForm.network
        // if (!currentNetwork) {
        //   delGisData(oldStatus, gisObj)
        // } else if (currentNetwork === network) {
          // 调用 gis方法
          let ifDefault = false
          if (zoneId == cityId && roleType == 1) {
            ifDefault = true
          }
          // gisObj.hideHeatMap()
          addGisData(
            gridData,
            {network, status: oldStatus},
            ifDefault,
            gisObj,
            boundary
          )
      }

      // 删除重复缓存
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == gridData.id && list[i].network == network &&
            list[i].mnc == mnc && list[i].ptMonth == ptMonth) {
          list.splice(i,1)
          i--
        }
      }

      if(network.split('+')[0] == 12) {
        if((zoneId != null && zoneId != cityId && zoneId != '全部') || (townId != null && townId !== '全部')
          || (companyId != '全部' && companyId != cityId && companyId != null) || (networkGridId !== '全部' && networkGridId !== null)){
          return
        }
      }

      // 提交修改
      commit('setBoundaryList', [
        ...list,
        {
          id: gridData.id,
          mnc,
          ptMonth,
          network,
          data: gridData
        }
      ])
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
