const comstant = {
  // 主菜单
//   mainMenuList: [
//     {
//       main: '市场',
//       title: '用户',
//       img_icon: this.img_router + 'user-analyse.png',
//       children: [
//         {
//           name: '常驻分析',
//       img_icon: this.img_router + 'user-analyse.png',
//       authoritiesFlag: true,
//         },
//         {
//           name: '新增分析',
//       img_icon: this.img_router + 'user-analyse.png',
//       authoritiesFlag: true,
//         },
//         {
//           name: '漫入分析',
//       img_icon: this.img_router + 'user-analyse.png',
//       authoritiesFlag: true,
//         }
//       ]
//     },
//     {
//       title: '家宽',
//       img_icon: this.img_router + 'user-analyse.png',
//       children: [
//         {
//           name: '宽带',
//       authoritiesFlag: true,
//         }
//       ]
//     },
//     {
//       title: '商企',
//       img_icon: this.img_router + 'user-analyse.png',
//       children: [
//         {
//           name: '商务楼宇',
//       authoritiesFlag: true,
//         },
//         {
//           name: '沿街商铺',
//       authoritiesFlag: true,
//         },
//         {
//           name: '泛住宅',
//       authoritiesFlag: true,
//         }
//       ]
//     },
//     {
//       title: '渠道',
//       img_icon: this.img_router + 'user-analyse.png',
//       children: [
//         {
//           name: '营业厅',
//       authoritiesFlag: true,
//         }
//       ]
//     },

//     {
//       main: '网络',
//       title: '覆盖',
//       img_icon: this.img_router + 'user-analyse.png',
//       children: [
//         {
//           name: '覆盖评估',
//       authoritiesFlag: true,
//         },
//         {
//           name: '过覆盖',
//       authoritiesFlag: true,
//         },
//         {
//           name: '弱覆盖',
//       authoritiesFlag: true,
//         },
//         {
//           name: '覆盖黑点',
//       authoritiesFlag: true,
//         },
//         {
//           name: '覆盖差点',
//       authoritiesFlag: true,
//         },
//         {
//           name: '深度覆盖',
//       authoritiesFlag: true,
//         },
//         {
//           name: '楼宇覆盖',
//       authoritiesFlag: true,
//         },
//         {
//           name: '质差',
//       authoritiesFlag: true,
//         },
//         {
//           name: '5G覆盖指数',
//       authoritiesFlag: true,
//         }
//       ]
//     },
//     {
//       title: '站址',
//       img_icon: this.img_router + 'user-analyse.png',
//       children: [
//         {
//           name: '站址规划',
//       authoritiesFlag: true,
//         }
//       ]
//     },
//     {
//       title: '工参',
//       img_icon: this.img_router + 'user-analyse.png',
//       children: [
//         {
//           name: '工参分析',
//       authoritiesFlag: true,
//         }
//       ]
//     },
// ],
  // 新版用户tab栏
  userAnalysisTypeNewArr: [
    { label: '常驻分析', value: '3+', sourceType: 1, url: require('@/assets/usertab/user.png')   ,url1: require('@/assets/usertab/user1.png')    },
    { label: '新增用户', value: '7+', sourceType: 2, url: require('@/assets/usertab/useradd.png'),url1: require('@/assets/usertab/useradd1.png') },
    { label: '5G用户', value: '3+5', sourceType: 4,  url: require('@/assets/usertab/fiveG.png')  ,url1: require('@/assets/usertab/fiveG1.png')   },
    { label: '5G分流比', value: '6+', sourceType: 5, url: require('@/assets/usertab/fourG.png')  ,url1: require('@/assets/usertab/fourG1.png')   },
    { label: '4G网络', value: '1+4', sourceType: 6,  url: require('@/assets/usertab/fourGL.png') ,url1: require('@/assets/usertab/fourGL1.png')  },
    { label: '5G网络', value: '1+5', sourceType: 7,  url: require('@/assets/usertab/fiveGL.png') ,url1: require('@/assets/usertab/fiveGL1.png')  },
    { label: '宽带', value: '24+4', sourceType: 14,  url: require('@/assets/usertab/kd.png') ,url1: require('@/assets/usertab/kd1.png')  }
  ],
  // 新版网络tab栏
  userAnalysisTypeNetworkArr: [
    { label: '4G覆盖', value: '1+4', sourceType: 6,  url: require('@/assets/usertab/4net.png') ,url1: require('@/assets/usertab/4net1.png')  },
    { label: '5G覆盖', value: '1+5', sourceType: 7,  url: require('@/assets/usertab/5net.png') ,url1: require('@/assets/usertab/5net1.png')  },
    { label: '4G Cell', value: '12+4', sourceType: 8,  url: require('@/assets/usertab/san.png') ,url1: require('@/assets/usertab/san1.png')  },
    { label: '5G Cell', value: '12+5', sourceType: 9,  url: require('@/assets/usertab/san.png') ,url1: require('@/assets/usertab/san1.png')  },
    { label: '覆盖黑点', value: '13+4', sourceType: 10,  url: require('@/assets/usertab/bb.png') ,url1: require('@/assets/usertab/bb1.png')  },
    { label: '覆盖差点', value: '14+4', sourceType: 11,  url: require('@/assets/usertab/badnet.png') ,url1: require('@/assets/usertab/badnet1.png')  },
    { label: '4G深度覆盖', value: '15+4', sourceType: 12,  url: require('@/assets/usertab/cell.png') ,url1: require('@/assets/usertab/cell1.png')  },
    { label: '5G深度覆盖', value: '15+5', sourceType: 13,  url: require('@/assets/usertab/cell.png') ,url1: require('@/assets/usertab/cell1.png')  },
    { label: '4G深度栅格', value: '20+4', sourceType: 20,  url: require('@/assets/usertab/4net.png') ,url1: require('@/assets/usertab/4net1.png')  },
    { label: '5G深度栅格', value: '20+5', sourceType: 21,  url: require('@/assets/usertab/5net.png') ,url1: require('@/assets/usertab/5net1.png')  },
    { label: '站址规划', value: '19+4', sourceType: 19,  url: require('@/assets/usertab/san.png') ,url1: require('@/assets/usertab/san1.png')  },
    { label: '质差', value: '22+4', sourceType: 22,  url: require('@/assets/usertab/san.png') ,url1: require('@/assets/usertab/san1.png')  },
    // { label: '过覆盖cell', value: '17+4', sourceType: 17,  url: require('@/assets/usertab/san.png') ,url1: require('@/assets/usertab/san1.png')  },
    // { label: '覆盖监控', value: '18+4', sourceType: 18,  url: require('@/assets/usertab/monitor1.png') ,url1: require('@/assets/usertab/monitor.png')  },
    // { label: '工参检查', value: '19+4', sourceType: 19,  url: require('@/assets/usertab/ref.png') ,url1: require('@/assets/usertab/ref1.png')  },
  ],
  userAnalysisTypeArr: [
    { label: null, value: null },
    { label: '新增用户', value: '7+', permission: 'newUsers' },
    { label: '5G用户4G网络', value: '6+', permission: 'fiveInFour' }
  ],
  researchTypeArr: [
    { label: null, value: null },
    { label: '异地异网', value: 11 },
    { label: '漫入用户', value: 12 },
    { label: '异网用户', value: 13 },
    { label: '5G用户', value: 14 },
    { label: '总用户', value: 15 }
  ],
  userTypeArr: [
    null,
    '网络差用户好',
    '网络好用户差',
    '网络差用户差',
    '集团预警',
    '漫入预警',
    '常驻&漫入分析',
    '预警记录表'
  ],
  perspectiveList: ['经纬度分析', '方位角分析'],
  newIncomeList: ['新漫入热力图','新漫入撒点图'],
  stateTypeList: ['全部', '4G基站', '5G基站'],
  longitudeTypeList: [
    { label: '全部', value: 1 },
    { label: '经纬度偏差[1km，2km)', value: 2 },
    { label: '经纬度偏差[2km，5km)', value: 3 },
    { label: '经纬度偏差[5km，+∝)', value: 4 }
  ],
  azimuthTypeList: [
    { label: '全部', value: 1 },
    { label: '方位角偏差[120°,180°]', value: 2 },
    { label: '方位角偏差[60°,120°)', value: 3 }
  ],
  // coverage
  coverageList: [
    { name: '热力图', id: 0, type: 0, url: require('@/assets/coverage/热力图(2).png'), url1: require('@/assets/coverage/热力图(1).png'), url2: require('@/assets/coverage/热力图.png'), },
    { name: '栅格图', id: 1, type: 1, url: require('@/assets/coverage/栅格图(1).png'), url1: require('@/assets/coverage/栅格图(2).png'), url2: require('@/assets/coverage/栅格图.png'), },
    { name: '点位图', id: 13, type: 13, url: require('@/assets/coverage/点位图(1).png'), url1: require('@/assets/coverage/点位图(2).png'), url2: require('@/assets/coverage/点位图.png'), },
    { name: '色块图', id: 2, url: require('@/assets/coverage/色块图(1).png'), url1: require('@/assets/coverage/色块图(2).png'), url2: require('@/assets/coverage/色块图.png'), },
    { name: '卫星图', id: 3, url: require('@/assets/coverage/卫星图(1).png'), url1: require('@/assets/coverage/卫星图(2).png'), url2: require('@/assets/coverage/卫星图.png'), },
    { name: '区县边界', id: 4, url: require('@/assets/coverage/区县边界(1).png'), url1: require('@/assets/coverage/区县边界(2).png'), url2: require('@/assets/coverage/区县边界.png'), },
    { name: '街道边界', id: 5, url: require('@/assets/coverage/街道边界(1).png'), url1: require('@/assets/coverage/街道边界(2).png'), url2: require('@/assets/coverage/街道边界.png'), },
    { name: '乡村边界', id: 6, url: require('@/assets/coverage/乡村边界(1).png'), url1: require('@/assets/coverage/乡村边界(2).png'), url2: require('@/assets/coverage/乡村边界.png'), },
    { name: '网格边界', id: 7, url: require('@/assets/coverage/网络边界(1).png'), url1: require('@/assets/coverage/网络边界(2).png'), url2: require('@/assets/coverage/网络边界.png'), },
    { name: '微格边界', id: 8, url: require('@/assets/coverage/微格边界(1).png'), url1: require('@/assets/coverage/微格边界(2).png'), url2: require('@/assets/coverage/微格边界.png'), },
    { name: '4G小区工参', id: 9, url: require('@/assets/coverage/4G小区工参(1).png'), url1: require('@/assets/coverage/4G小区工参(2).png'), url2: require('@/assets/coverage/4G小区工参.png'), },
    { name: '5G小区工参', id: 10, url: require('@/assets/coverage/5G小区工参(1).png'), url1: require('@/assets/coverage/5G小区工参(2).png'), url2: require('@/assets/coverage/5G小区工参.png'), },
    { name: '楼宇', id: 11, url: require('@/assets/coverage/楼宇(1).png'), url1: require('@/assets/coverage/楼宇(2).png'), url2: require('@/assets/coverage/楼宇.png'), },
    { name: '营业厅', id: 12, url: require('@/assets/coverage/营业厅(1).png'), url1: require('@/assets/coverage/营业厅(2).png'), url2: require('@/assets/coverage/营业厅.png'), },
  ],
  mapList: [
    { src: '/img/floor-plan.png', name: '平面图', id: 1 },
    { src: '/img/satellite-imagery.png', name: '卫星图', id: 2 }
  ],
  // 筛选条件(用于除覆盖和份额的普通项)
  relationList: [
    { name: null, id: null },
    { name: '等于', id: 1 },
    { name: '不等于', id: 2 },
    { name: '大于', id: 3 },
    { name: '大于或等于', id: 4 },
    { name: '小于', id: 5 }
  ],
  // 筛选条件(用于覆盖)
  coverRelationList: [
    { name: null, id: null },
    { name: '等于', id: 1 },
    { name: '不等于', id: 2 },
    { name: '大于', id: 3 },
    { name: '大于或等于', id: 4 },
    { name: '小于', id: 5 },
    { name: '弱于竞对-任意一家', id: 6 },
    { name: '弱于竞对-两家', id: 7 },
    { name: '强于竞对-任意一家', id: 8 },
    { name: '强于竞对-两家', id: 9 }
  ],
  // 筛选条件(用于份额)
  ratioRelationList: [
    { name: null, id: null },
    { name: '等于', id: 1 },
    { name: '不等于', id: 2 },
    { name: '大于', id: 3 },
    { name: '大于或等于', id: 4 },
    { name: '小于', id: 5 },
    { name: '弱于全市均值(百分点)', id: 10 },
    { name: '高于全市均值(百分点)', id: 11 }
  ],
  typeIdList: [
    'zoneId',
    'townId',
    'areaId',
    'companyName',
    'networkGridId',
    'microGridId'
  ],
  typeIdLists: [
    'zoneId',
    'townId',
    'areaId',
    'networkGridId',
    'microGridId',
    'companyId'
  ],
  mncList: [
    { value: '0', label: '移动', color: '#afe26c' },
    { value: '1', label: '联通', color: '#ea3324' },
    { value: '11', label: '电信', color: '#487ec2' }
  ],
  tabList: [
    { label: 12, text: '漫入用户' },
    { label: 15, text: '总用户' },
    { label: 14, text: '5G用户' }
  ],
  analysList: [
    {
      label: '1',
      imgSrc: require('Public/img/correlation-user.png'),
      text: '用户'
    },
    {
      label: '2',
      imgSrc: require('Public/img/correlation-net.png'),
      text: '网络'
    },
    {
      label: '1b',
      imgSrc: require('Public/img/correlation-trend.png'),
      text: '用户趋势'
    },
    {
      label: '2b',
      imgSrc: require('Public/img/correlation-net-coverage.png'),
      text: '覆盖趋势'
    }
  ],
  analysGDList: [
    // {
    //   label: '1',
    //   imgSrc: require('Public/img/correlation-user.png'),
    //   text: '用户'
    // },
    {
      label: '1b',
      imgSrc: require('Public/img/correlation-trend.png'),
      text: '用户趋势'
    }
    // {
    //   label: '2b',
    //   imgSrc: require('Public/img/correlation-net-coverage.png'),
    //   text: '新增趋势'
    // }
  ],
  analysisList: [
    {
      label: '1',
      imgSrc: require('Public/img/correlation-user.png'),
      text: '用户'
    },
    {
      label: '2',
      imgSrc: require('Public/img/correlation-net.png'),
      text: '网络'
    },
    {
      label: '1b',
      imgSrc: require('Public/img/correlation-trend.png'),
      text: '用户趋势'
    },
    {
      label: '2b',
      imgSrc: require('Public/img/correlation-net-coverage.png'),
      text: '覆盖趋势'
    },
    {
      label: '7',
      imgSrc: require('Public/img/correlation-village.png'),
      text: '主覆盖小区'
    },
  ],
  roundList: [
    {
      label: '8',
      imgSrc: require('Public/img/correlation-user.png'),
      text: '月流量'
    },
  ],
  config1: [
    {
      label: '常驻用户',
      prop: 'residentNumCoverage',
      prop2: 'residentNum'
    },
    { label: '4g覆盖', prop: 'netCoverage4g' },
    { label: '5g覆盖', prop: 'netCoverage5g' }
  ],
  config: [
    {
      label: '常驻用户',
      prop: 'residentNumCoverage',
      prop2: 'residentNum'
    },
    { label: '本地新增', prop: 'addNum'},
    { label: '4g覆盖', prop: 'netCoverage4g' },
    { label: '5g覆盖', prop: 'netCoverage5g' },
    { label: '本地用户', prop: 'localNum' },
    { label: '省内用户', prop: 'provinceNum' },
    { label: '省际用户', prop: 'provincialNum' },
    // { label: '本地用户数占比', prop: 'localNumCoverage' },
    { label: '本地5G用户', prop: 'localNum5g' },
    // { label: '本地5g用户数占比', prop: 'localNum5gCoverage' },
    // { label: '本地234g用户数占比', prop: 'localNum234gCoverage' },
    // { label: '省内占比', prop: 'provinceNumCoverage' },
    { label: '省内5G用户', prop: 'provinceNum5g' },
    // { label: '省内5g占比', prop: 'provinceNum5gCoverage' },
    // { label: '省内2/3/4g占比', prop: 'provinceNum234gCoverage' },
    { label: '省际5G用户', prop: 'provincialNum5g' },
    // { label: '省际5g占比', prop: 'provincialNum5gCoverage' },
    { label: '本地2/3/4G用户', prop: 'localNum234g' },
    { label: '省内2/3/4G用户', prop: 'provinceNum234g' },
    { label: '省际2/3/4G用户', prop: 'provincialNum234g' },
    // 宽带
    { label: '宽带数', prop: 'broadbandNum' },
    { label: '宽带占比', prop: 'broadbandRatio' }
    // { label: '省际234g占比', prop: 'provincialNum234gCoverage' }
  ],
  filterLabel: {
    name: '区域'
  },
  filterAdd: ['name'],
  filterSortObj: {
    mobileCoverRate: 'custom',
    unicomCoverRate: 'custom',
    telecomCoverRate: 'custom',
    mobileBelongRatio: 'custom',
    unicomBelongRatio: 'custom',
    telecomBelongRatio: 'custom',
    mobileRatio: 'custom',
    unicomRatio: 'custom',
    telecomRatio: 'custom',
    mobileUserRatio: 'custom',
    unicomUserRatio: 'custom',
    telecomUserRatio: 'custom',
    mobileContrastRatio: 'custom',
    unicomContrastRatio: 'custom',
    telecomContrastRatio: 'custom',
    mobileUserDifference: 'custom',
    unicomUserDifference: 'custom',
    telecomUserDifference: 'custom',
    numAll: 'custom',
    mobileRatioDiff: 'custom',
    telecomRatioDiff: 'custom',
    unicomRatioDiff: 'custom',
    mobileRoamingRatio: 'custom',
    telecomRoamingRatio: 'custom',
    unicomRoamingRatio: 'custom',
    mobileRoamingRatioDiff: 'custom',
    telecomRoamingRatioDiff: 'custom',
    unicomRoamingRatioDiff: 'custom',
    mobileNum: 'custom',
    telecomNum: 'custom',
    unicomNum: 'custom',
    mobileCover4g: 'custom',
    mobileCover5g: 'custom',
    mobileFiveInFour: 'custom',
    unicomCover4g: 'custom',
    unicomCover5g: 'custom',
    unicomFiveInFour: 'custom',
    telecomCover4g: 'custom',
    telecomCover5g: 'custom',
    telecomFiveInFour: 'custom',
    bd_mobileCover4g: 'custom',
    bd_mobileCover5g: 'custom',
    bd_mobileFiveInFour: 'custom',
    bd_unicomCover4g: 'custom',
    bd_unicomCover5g: 'custom',
    bd_unicomFiveInFour: 'custom',
    bd_telecomCover4g: 'custom',
    bd_telecomCover5g: 'custom',
    bd_telecomFiveInFour: 'custom',
    mobileCoverRate4g: 'custom',
    unicomCoverRate4g: 'custom',
    telecomCoverRate4g: 'custom',
    mobileCoverRate5g: 'custom',
    unicomCoverRate5g: 'custom',
    telecomCoverRate5g: 'custom',
    diffNetNumProvOut: 'custom',
    diffNetNumProvIn: 'custom',
    localNetNumProvOut: 'custom',
    localNetNumProvIn: 'custom',
    mobileRatio5g: 'custom',
    unicomRatio5g: 'custom',
    telecomRatio5g: 'custom',
    mobileBroadNum: 'custom',
    mobileBroadRatio: 'custom',
    unicomBroadNum: 'custom',
    unicomBroadRatio: 'custom',
    telecomBroadNum: 'custom',
    telecomBroadRatio: 'custom',
    broadNum: 'custom',
    broadNumAll: 'custom',
    broadBelongNum: 'custom',
    broadUnBelongNum: 'custom',
    broadBelongRatio: 'custom',
    broadUnBelongRatio: 'custom',
    // 人流量
    numAll45g: 'custom',
    mobileNumAll4g: 'custom',
    telecomNumAll4g: 'custom',
    unicomNumAll4g: 'custom',
    mobileNumAll5g: 'custom',
    telecomNumAll5g: 'custom',
    unicomNumAll5g: 'custom',
    // 本地新增
    mobileNumLocal: 'custom',
    unicomNumLocal: 'custom',
    telecomNumLocal: 'custom',
    mobileNumNotLocal: 'custom',
    unicomNumNotLocal: 'custom',
    telecomNumNotLocal: 'custom',
    // 香港漫入
    hkMobile: 'custom',
    hjDx: 'custom',
    chnUnicom: 'custom',
    smtOne: 'custom',
    dxYk: 'custom',
    chnMobile: 'custom',
    chnTelecom: 'custom',
    scjTelecom: 'custom',
    hkTelecom: 'custom',
    qtNum: 'custom',
    mncNumAll: 'custom',
  },
  allAttrList: [
    { name: '移动4G覆盖率', id: 'mobileCoverRate' },
    { name: '联通4G覆盖率', id: 'unicomCoverRate' },
    { name: '电信4G覆盖率', id: 'telecomCoverRate' },
    { name: '移动常驻用户份额', id: 'mobileRatio' },
    { name: '联通常驻用户份额', id: 'unicomRatio' },
    { name: '电信常驻用户份额', id: 'telecomRatio' },
    { name: '移动归属用户份额', id: 'mobileBelongRatio' },
    { name: '联通归属用户份额', id: 'unicomBelongRatio' },
    { name: '电信归属用户份额', id: 'telecomBelongRatio' },
    { name: '总用户数', id: 'numAll' }
  ],
  defaultProps: {
    label: 'name'
  },
  lonLabel: {
    enbid: 'EnbID',
    dis: '偏差距离'
  },
  lonAdd: ['enbid', 'dis'],
  lonSortObj: { dis: 'custom' },

  azLabel: {
    eci: 'ECI',
    az: '工参',
    forecastAz: '预测',
    angleDiff: '偏差'
  },
  azAdd: ['eci', 'az', 'forecastAz', 'angleDiff'],
  coverageLabel: {
    gridId: 'ID',
    mobileCoverRate: '移动',
    unicomCoverRate: '联通',
    telecomCoverRate: '电信'
  },
  // 广电数据简报
  briefLabel: {
    id: '序号',
    name: '区县',
    broadcastNum: '广电常驻/人',
    broadcastAddNum: '广电新增/人'
  },
  briefAdd: ['id', 'name', 'broadcastNum', 'broadcastAddNum'],
  coverageAdd: [
    'gridId',
    'mobileCoverRate',
    'unicomCoverRate',
    'telecomCoverRate'
  ],
  coverageSortObj: {
    mobileCoverRate: 'custom',
    unicomCoverRate: 'custom',
    telecomCoverRate: 'custom'
  },
  mncFunction(val) {
    let a, b, c, a1, b1, c1
    if (val == 0) {
      a = '电信'
      b = '联通'
      c = '移动' //弱于电信 弱于联通 弱于电信和联通
      a1 = '11'
      b1 = '1'
      c1 = '0'
    } else if (val == 1) {
      a = '移动'
      b = '电信'
      c = '联通' //弱于移动 弱于电信 弱于移动和电信
      a1 = '0'
      b1 = '11'
      c1 = '1'
    } else {
      a = '移动'
      b = '联通'
      c = '电信' //弱于移动 弱于联通 弱于移动和联通
      a1 = '0'
      b1 = '1'
      c1 = '11'
    }
    return { a, b, c, a1, b1, c1 }
  },

  // 0:互斥，1:互容
  menuRuleArray : [
    [1,0,0,0,0,0,1,1,1,1,1,1,0,0,1,1,1,1],
    [0,1,0,0,0,0,1,1,1,1,1,1,0,0,1,1,1,1],
    [0,0,1,0,0,0,1,1,1,1,1,1,0,0,1,1,1,1],
    [0,0,0,1,0,0,1,1,1,1,1,1,0,0,1,1,1,1],
    [0,0,0,0,1,0,1,1,1,1,1,1,0,0,1,1,1,1],
    [0,0,0,0,0,1,1,1,1,1,1,1,0,0,1,1,1,1],
    [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],
    [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],
    [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],
    [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],
    [1,1,1,1,1,1,1,1,1,1,1,0,1,1,1,1,1,1],
    [1,1,1,1,1,1,1,1,1,1,0,1,1,1,1,1,1,1],
    [0,0,0,0,0,0,1,1,1,1,1,1,1,0,1,1,1,1],
    [0,0,0,0,0,0,1,1,1,1,1,1,0,1,1,1,1,1],
    [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]
  ],

  /**
   * @param curArray 当前tab数组
   * @param point 点击菜单
   */
  determineRules(curArray, point){
    // 参数校验
    if(point > comstant.menuRuleArray.length - 1){
      return curArray;
    }

    // 当前tab和菜单一致
    if(curArray.length == 1 && curArray[0] == point){
      return curArray;
    }

    let resultArray = [];
    for(let i=0; i<curArray.length; i++){
      if(curArray[i] > comstant.menuRuleArray.length - 1){
        continue;
      }
      // 保留共容
      if(comstant.menuRuleArray[curArray[i] - 1][point - 1] == 1){
        resultArray.push(curArray[i]);
      }
    }
    resultArray.push(point);
    return resultArray;
  }
}

export default comstant
