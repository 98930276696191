// 引入 gis模块 方法
import {
  getAttributeRespnse,
  getBounding,
  getAllBoundary,
  getAllBoundaryPageTotal,
  getAllBoundaryByPageNum,
  getDataAnalysis,
  getUserAnalysisDetailData,
  saveAccountAttributeTable,
  queryAccountAttributeTable,
  getBusinessRespnse,
  getAreaWarning,
  areaWarningThrend,
  areaWarningData, getGridDataByCell,queryCompareData,
} from '@/apis/gis'
import {setLegend} from '@/utils/gisObjFun'
import {getSubjectArea} from '@/apis/reports'
import api from '@/apis/index'

// 引入 gis方法
import {
  changeMap,
  compareFn,
  getGridBoundary,
  getLonAndAz,
  getLocateCell,
  getAllBoundaryNoClick,
  delGisCommunity,
  delGisCommunityRight,
  delGisCustom,
  getStations,
  bigGridContrast,
  zoomToBigGrid,
  setBoundary,
  clearBoundary,
  getAllBoundaryHaveClick,
  getAllBoundaryHaveClick2,
  locateArea,
  locateAreaBB,
  locateArea02,
  locateAreaCoor,
  locateCell
} from '@/utils/gisObjFun.js'

import {Message} from 'element-ui'
import Comstant from '@/views/gisPage/gisMap/comstant'
import {getBusinessRegionList, queryCompanySceneLevelCount, queryBusinessHallLevelCount, getFirePoint, getAreaFirePont } from "@/apis/company-enterprise";

// 查询 网格&热力&小区 图层
function queryBoundary(
  that,
  network,
  mnc,
  ptMonth,
  day,
  type,
  currentStatus,
  ifCommunity = false
) {
  if (
    that.state.gisPage.gisObj !== null &&
    that.state.gisPage.iframeUrl === 'index'
  ) {
    function getData(formData, gridData, status, boundary) {
      that.dispatch('gisPage/changeBoundaryList', {
        data: null,
        oldFormData: formData,
        gridData,
        oldStatus: status,
        boundary
      })
    }

    function setData(name, data) {
      that.commit('gisForm/setState', {
        name,
        data,
      })
    }

    let cityId = that.state.home.userInfoVo.cityId
    let roleType = that.state.home.userInfoVo.roleType
    let formData = {
      cityId,
      mnc,
      ptMonth,
      zoneId: that.state.gisForm.zoneId,
      townId: that.state.gisForm.townId,
      network,
      userLineId: that.state.gisPage.userLineId,
      companyId: that.state.gisForm.companyId,
      networkGridId: that.state.gisForm.networkGridId,
      roleType,
      usedNetwork: that.state.gisForm.usedNetwork,
      sourceType: that.state.gisForm.sourceType,
      boundaryFlag: that.state.gisForm.boundaryFlag,
    }
    let boundaryList = that.state.gisPage.boundaryList
    let gisObj = that.state.gisPage.gisObj
    let state = that.state.gisForm
    let boundary = that.state.gisPage.dictionary.find(
      v => v.id == that.state.home.userInfoVo.cityId
    )
    const {routeAreaType} = that.state.gisForm
    if (routeAreaType == 5) {
      formData['day'] = day
      formData['type'] = type
      formData['routeAreaType'] = routeAreaType
      const {zoneId, townId, villageId} = that.state.gisForm
      if (zoneId != null && zoneId !== '全部') {
        formData['zoneId'] = zoneId
      }
      if (townId != null && townId !== '全部') {
        formData['townId'] = townId
      }
      if (villageId != null && villageId !== '全部') {
        formData['villageId'] = villageId
      }
      boundaryList = []
    }
    if (state.zoneId !== cityId && state.companyId === null) {
      let label = 'zoneId'
      if (state.townId !== '全部' && state.townId !== null) {
        label = 'townId'
      }
      if (state.villageId !== null && state.villageId !== '全部') {
        label = 'villageId'
      }
      queryAloneArea(that, state, label, state[label]).then(data => {
        getGridBoundary(
          formData,
          boundaryList,
          currentStatus,
          gisObj,
          data,
          getData,
          ifCommunity,
          setData
        )
      })
    } else if (state.townId !== cityId && state.companyId === null) {
      let label = 'townId'
      if (state.villageId !== '全部' && state.villageId !== null) {
        label = 'villageId'
      }
      queryAloneArea(that, state, label, state[label]).then(data => {
        getGridBoundary(
          formData,
          boundaryList,
          currentStatus,
          gisObj,
          data,
          getData,
          ifCommunity,
          setData
        )
      })
    } else if (state.companyId !== cityId && state.zoneId === null) {
      let label = 'companyId'
      if (
        state.networkGridId !== '全部' &&
        state.networkGridId !== null &&
        state.microGridId == '全部'
      ) {
        label = 'networkGridId'
      } else if (state.microGridId != null) {
        label = 'microGridId'
      }
      queryAloneArea(that, state, label, state[label]).then(data => {
        getGridBoundary(
          formData,
          boundaryList,
          currentStatus,
          gisObj,
          data,
          getData,
          ifCommunity,
          setData
        )
      })
    } else {
      getGridBoundary(
        formData,
        boundaryList,
        currentStatus,
        gisObj,
        boundary,
        getData,
        ifCommunity,
        setData
      )
    }
  }
}

// 遍历添加小区图层
function queryCommunity(arr, that) {
  arr.forEach(item => {
    queryBoundary(
      that,
      item,
      that.state.gisForm.mnc,
      that.state.gisForm.ptMonth,
      that.state.gisForm.day,
      that.state.gisForm.type,
      1,
      true
    )
  })
}

// 遍历添加/删除 车站营业厅图层
function queryCustom(arr, type, that) {
  arr.forEach(item => {
    getStations(item, type, that)
  })
}

// 查询 经纬度 或方位角图层
function queryLonAndAz(that, state) {
  if (
    that.state.gisPage.gisObj !== null &&
    that.state.gisPage.iframeUrl !== 'index'
  ) {
    function getData(data) {
      let str =
        'gisForm/' + (state.perspective == 1 ? 'setLonList' : 'setAzList')
      that.commit(str, data)
    }

    let formData = {
      cityId: that.state.home.userInfoVo.cityId,
      mnc: state.mnc,
      ptMonth: state.ptMonth,
      perspective: state.perspective,
      stateType: state.stateType,
      longitudeType: state.longitudeType,
      azimuthType: state.azimuthType,
      sortType: state.lonAndAzSortType
    }
    let gisObj = that.state.gisPage.gisObj
    getLonAndAz(formData, gisObj, getData)
  }
}

// 查询 大栅格图层
function queryBigGrid(that, state) {
  if (state.routeAreaType == 2 || state.routeAreaType == 12) {
    function getData(data) {
      that.commit('gisForm/setOffsetCoverageList', data)
    }

    let formData = state.companyId ? {
        cityId: that.state.home.userInfoVo.cityId,
        ptMonth: state.ptMonth,
        network: state.network,
        offset: state.offset,
        offsetSort: state.offsetSort,
        newIncome: state.newIncome,
        mnc: state.mnc,
        status: 1,
        zoneId: null,
        townId: null,
        companyId: state.companyId,
        networkGridId: state.networkGridId == '全部' ? null : state.networkGridId,

      } : {
        cityId: that.state.home.userInfoVo.cityId,
        ptMonth: state.ptMonth,
        network: state.network,
        offset: state.offset,
        offsetSort: state.offsetSort,
        newIncome: state.newIncome,
        mnc: state.mnc,
        status: 1,
        zoneId: state.zoneId,
        townId: state.townId == '全部' ? null : state.townId,

      }
    let gisObj = that.state.gisPage.gisObj
    bigGridContrast(formData, gisObj, getData)

    // 对比
    if (state.compare) {
      let formDataC = state.companyId ? {
        cityId: that.state.home.userInfoVo.cityId,
        ptMonth: state.ptMonth,
        network: state.network,
        offset: state.offset,
        offsetSort: state.offsetSort,
        newIncome: state.newIncome,
        mnc: state.mnc,
        status: 2,
        zoneId: null,
        townId: null,
        companyId: state.companyId,
        networkGridId: state.networkGridId == '全部' ? null : state.networkGridId,

      } : {
        cityId: that.state.home.userInfoVo.cityId,
        ptMonth: state.ptMonth,
        network: state.network,
        offset: state.offset,
        offsetSort: state.offsetSort,
        newIncome: state.newIncome,
        mnc: state.mnc,
        status: 2,
        zoneId: state.zoneId,
        townId: state.townId == '全部' ? null : state.townId,

      }
      let gisObj = that.state.gisPage.gisObj
      bigGridContrast(formDataC, gisObj, getData)
    }
  }
}

// 查询两个数组中不重复的值 （长度相同的数组的不同值 返回空数组）
function getNoRepeatList(arr1, arr2) {
  let maxList = arr1.length > arr2.length ? arr1 : arr2
  let minList = arr1.length < arr2.length ? arr1 : arr2
  let arr3 = []

  for (let i in maxList) {
    let a = maxList[i]
    let count = 0
    for (let j in minList) {
      if (minList[j] == a) {
        count++
      }
    }
    if (count == 0) {
      arr3.push(a)
    }
  }

  return arr3
}

// 查询 单独区域边界 并且 跳转
async function queryLocateArea(that, state, label, value) {
  if (that.state.gisPage.gisObj !== null) {
    queryAloneArea(that, state, label, value).then(data => {
      let gisObj = that.state.gisPage.gisObj
      setBoundary(data, gisObj)

      //商企
      /*if(that.state.gisForm.businessLayer){
        that.state.gisForm.businessLayer.hide()
      }
      setTimeout(() => {
        that.commit('gisForm/setBusinessRegion', null)
      }, 1000);*/
    })

    if (state.activeMenu == '19') {
      if (state.sourceType == 12 || state.sourceType == 13) {
        that.commit('gisForm/setDeepCover', state.sourceType)
      }
    }
  }
}

// 查询单独区域边界
async function queryAloneArea(that, state, label, value) {
  
  if (label == null) {
    let label = null
    if (localStorage.getItem('subjectDataType') == 1) {
      label = 'zoneId'
    } else if (localStorage.getItem('subjectDataType') == 2) {
      label = 'townId'
    } else if (localStorage.getItem('subjectDataType') == 8) {
      label = 'villageId'
    } else if (localStorage.getItem('subjectDataType') == 3 || localStorage.getItem('subjectDataType') == 15) {
      label = 'areaId'
    }
    let params = {
      cityId: that.state.home.userInfoVo.cityId,
      mnc: state.mnc,
      ptMonth: state.ptMonth,
      [label == undefined ? 'villageId' : label]: value
    }
    let {data = ''} = await getBounding(params)
    return data
  } else {
    let params = {
      cityId: that.state.home.userInfoVo.cityId,
      mnc: state.mnc,
      ptMonth: state.ptMonth,
      [label ? label : label == undefined ? 'villageId' : 'areaId']: value
      // [label === 'undefined' ? 'villageId' : label]:
      //   typeof value === 'undefined' ? state.searchWordList[0].villageId : value
    }
    //区县级用户登陆展示图层为区县
    if(state.regionType==2){
      params.zoneId=that.state.home.userInfoVo.distinctId
    }

    let {data = ''} = await getBounding(params)
    return data
  }
}

// 绘制 场景 多边界
function queryAllBoundaryHaveClick(that, state) {
  if (that.state.gisPage.gisObj !== null) {
    let cityId = that.state.home.userInfoVo.cityId
    let formData = {}
    if (state.networkGridId !== null) {
      formData = {
        cityId,
        areaType: state.areaType,
        areaLevel: state.areaLevel,
        companyId: state.companyId,
        networkGridId: state.networkGridId,
        microGridId: state.microGridId,
        sourceType: state.sourceType,
        cellType: state.network ? (state.network?.split('+')[1] == 5 ? 5 : 4) : (state.oldNetwork?.split('+')[1] == 5 ? 5 : 4)
      }
    } else {
      formData = {
        cityId,
        areaType: state.areaType,
        areaLevel: state.areaLevel,
        zoneId: state.zoneId,
        townId: state.townId,
        villageId: state.villageId,
        sourceType: state.sourceType,
        cellType: state.network ? (state.network?.split('+')[1] == 5 ? 5 : 4) : (state.oldNetwork?.split('+')[1] == 5 ? 5 : 4)
      }
    }
    let gisObj = that.state.gisPage.gisObj
    formData.ptMonth = state.ptMonth
    getAllBoundaryHaveClick(formData, gisObj, state.routeAreaType, state)
  }
}

function queryAllBoundaryHaveClick2(that, state, status) {
  if (that.state.gisPage.gisObj !== null) {
    let cityId = that.state.home.userInfoVo.cityId
    let formData = {}
    let mnc = (status == 1 ? state.mnc : state.rMnc)
    let ptMonth = (status == 1 ? state.ptMonth : state.rMonth)
    if (state.networkGridId !== null) {
      formData = {
        cityId,
        mnc,
        ptMonth,
        status,
        areaType: state.areaType,
        companyId: state.companyId,
        networkGridId: state.networkGridId,
        microGridId: state.microGridId,
        sourceType: state.sourceType,
        buildingCoverType: state.buildingCoverType,
        xMin: state.xMin,
        yMin: state.yMin,
        xMax: state.xMax,
        yMax: state.yMax
      }
    } else {
      formData = {
        cityId,
        mnc,
        ptMonth,
        status,
        areaType: state.areaType,
        zoneId: state.zoneId,
        townId: state.townId,
        villageId: state.villageId,
        sourceType: state.sourceType,
        buildingCoverType: state.buildingCoverType,
        xMin: state.xMin,
        yMin: state.yMin,
        xMax: state.xMax,
        yMax: state.yMax
      }
    }
    let gisObj = that.state.gisPage.gisObj
    getAllBoundaryHaveClick2(formData, gisObj, state.routeAreaType)
  }
}
function showBusinessRegionList(that, arr, regionType, Alpha=false) {
    const gisObj = that.state.gisPage.gisObj
    if(that.state.gisForm.businessLayer){
      that.state.gisForm.businessLayer.hide()
    }else{
      that.state.gisForm.businessLayer = gisObj.createCustomLayer()
    }
    if(regionType == 'circle'){
      const lngLatArr = arr.map(item => {
        let location = { lng: item.lon, lat: item.lat }
        //多行拼接传参
        let count = item.num
        let label = null
        let companyLabel = null
        let companyNum = null
        let mncName = null
        if (that.state.gisForm.activeMenu == 18) {
          mncName = {
            mobileNum : '移动:' + item.mobileNum,
            telecomNum : '联通:' + item.telecomNum,
            unicomNum : '电信:' + item.unicomNum,
          } 
        } else {
          if (item.companyNum) {
            if(item.companyNum > 9999){
              const result = (item.companyNum / 10000).toFixed(1);
              companyLabel = result + "万";
            }
            companyNum = '企业：' + (companyLabel ? companyLabel : item.companyNum)
            if(item.num > 9999){
              const result = (item.num / 10000).toFixed(1);
              count = result + "万";
            }
            label = (that.state.gisForm.activeMenu == 39 ? '楼宇：' : '园区：') + count
          } else {
            if(item.num > 9999){
              const result = (item.num / 10000).toFixed(1);
              count = result + "万";
            }
            label = "企业数:" + count
          }
        }
        return {
          lng: location.lng, //经度
          lat: location.lat, //纬度
          boundary: location.lng + '_' + location.lat,
          name: item.name,
          id: item.id,
          level: item.level,
          label: label,
          companyNum: companyNum,
          mncName,
        }
      })

      if (lngLatArr && lngLatArr.length && gisObj) {
        that.state.gisForm.businessLayer.setDataBusiness(
          require('@/assets/circle.png'),
          { x: 0, y: -56 / 2, Alpha },
          lngLatArr,
        )
        that.state.gisForm.businessLayer.show() //展示
      }
    } else if(regionType == 'point'){
      const lngLatArr = arr.map(item => {
        let location = { lng: item.lon, lat: item.lat }
        //多行拼接传参
        return {
          lng: location.lng, //经度
          lat: location.lat, //纬度
          name: item.name,
          id: item.id,
          boundary: location.lng + '_' + location.lat
        }
      })

      if (lngLatArr && lngLatArr.length && gisObj) {
        that.state.gisForm.businessLayer.setDataBusiness(
          require('@/assets/water.png'),
          { x: 0, y: -56 / 2 },
          lngLatArr
        )
        that.state.gisForm.businessLayer.show() //展示
      }
    } else if(regionType == 'KindlingPoint') {
      const lngLatArr = arr.map(item => {
        let location = { lng: item.lon, lat: item.lat }
        //多行拼接传参
        return {
          lng: location.lng, //经度
          lat: location.lat, //纬度
          name: item.name,
          id: item.id,
          fireFlag: item.fireFlag,
          mncException: item.mncException,
          otherFireFlag: item.otherFireFlag,
          otherMncException: item.otherMncException,
          type: 'KindlingPoint',
          boundary: item.boundary,
          
        }
      })

      if (lngLatArr && lngLatArr.length && gisObj) {
        that.state.gisForm.businessLayer.setData(
          require('@/assets/Kindling.png'),
          { x: 0, y: -52 / 2 },
          lngLatArr
        )
        that.state.gisForm.businessLayer.show() //展示
      }
    }
}

function setNull(that, val) {
  const arr = ['不限', '全部', that.state.home.userInfoVo.cityId.toString()]
  return arr.indexOf(val) == -1 ? val : null
}


function saveOrReCoverState(that, val) {
  //保存
  if(val == 1){
    that.state.gisForm.businessState = {
      zoneId: that.state.gisForm.zoneId,
      townId: that.state.gisForm.townId,
      villageId: that.state.gisForm.villageId,
      companyId: that.state.gisForm.companyId,
      networkGridId: that.state.gisForm.networkGridId,
      microGridId: that.state.gisForm.microGridId
    }
  }else{
    that.state.gisForm.zoneId = that.state.gisForm.businessState.zoneId,
    that.state.gisForm.townId = that.state.gisForm.businessState.townId,
    that.state.gisForm.villageId = that.state.gisForm.businessState.villageId,
    that.state.gisForm.companyId = that.state.gisForm.businessState.companyId,
    that.state.gisForm.networkGridId = that.state.gisForm.businessState.networkGridId,
    that.state.gisForm.microGridId = that.state.gisForm.businessState.microGridId
  }
}

function getLevelName(that, type, level) {
  if(!level){
    return null
  }
  let result = ''
  //行政区维度
  if(type == 1) {
    if (level <= 6) {
      if (that.state.gisForm.villageId && that.state.gisForm.villageId != '全部') {
        result = "village"
      } else if (that.state.gisForm.townId && that.state.gisForm.townId != '全部') {
        result = "town"
      } else if (that.state.gisForm.zoneId && that.state.gisForm.zoneId != '全部' && that.state.gisForm.zoneId != that.state.home.userInfoVo.cityId) {
        result = "zone"
      } else {
        result = "cityZone"
      }
    } else if (level <= 9) {
      if (that.state.gisForm.villageId && that.state.gisForm.villageId != '全部') {
        result = "village"
      } else if (that.state.gisForm.townId && that.state.gisForm.townId != '全部') {
        result = "town"
      } else {
        result = "zone"
      }
    } else if (level <= 11) {
      if (that.state.gisForm.villageId && that.state.gisForm.villageId != '全部') {
        result = "village"
      } else {
        result = "town"
      }
    } else {
      result = "village"
    }
  }else if(type == 2){
    if (level <= 6) {
      if (that.state.gisForm.microGridId && that.state.gisForm.microGridId != '全部') {
        result = "micNetwork"
      } else if (that.state.gisForm.networkGridId && that.state.gisForm.networkGridId != '全部') {
        result = "network"
      } else if (that.state.gisForm.companyId && that.state.gisForm.companyId != '全部' && that.state.gisForm.companyId != that.state.home.userInfoVo.cityId) {
        result = "company"
      } else {
        result = "cityCompany"
      }
    } else if (level <= 9) {
      if (that.state.gisForm.microGridId && that.state.gisForm.microGridId != '全部') {
        result = "micNetwork"
      } else if (that.state.gisForm.networkGridId && that.state.gisForm.networkGridId != '全部') {
        result = "network"
      } else {
        result = "company"
      }
    } else if (level <= 11) {
      if (that.state.gisForm.microGridId && that.state.gisForm.microGridId != '全部') {
        result = "micNetwork"
      } else {
        result = "network"
      }
    } else {
      result = "micNetwork"
    }
  }
  return result
}

function getLevelMethod(that, level) {
    if(level == 'cityZone'){
      return {name:"gisForm/setZoneId", id:1}
    } else if(level == 'zone'){
      return {name:"gisForm/setTownId", id:1}
    } else if(level == 'town'){
      return {name:"gisForm/setVillageId", id:1}
    } else if(level == 'cityCompany'){
      return {name:"gisForm/setCompanyId", id:2}
    } else if(level == 'company'){
      return {name:"gisForm/setNetworkGridId", id:2}
    } else if(level == 'network'){
      return {name:"gisForm/setMicroGridId", id:2}
    }
}

function setDataDivisionId(data,that) {
  console.log(data,that,'wwttyy',that.state.gisForm.zoneId);
  let obj = {}
  let state = that.state.gisForm
  if (data == 1) {
    if (state.villageId && state.villageId != '全部') {
      obj.villageId = state.villageId
    } else if (state.townId && state.townId != '全部') {
      obj.townId = state.townId
    } else if (state.zoneId && state.zoneId != '全部' && state.zoneId != that.state.home.userInfoVo.cityId) {
      obj.zoneId = state.zoneId
    }
  } else {
    if (state.microGridId && state.microGridId != '全部') {
      obj.microGridId = state.microGridId
    } else if (state.networkGridId && state.networkGridId != '全部') {
      obj.networkGridId = state.networkGridId
    } else if (state.companyId && state.companyId != '全部' && state.companyId != that.state.home.userInfoVo.cityId) {
      obj.searchCompanyId = state.companyId
    }
  }
  return obj
}

const state = {
  mapClickList: [0],// 图层切换状态
  scoreType: 1,  //  1、综合建站指数  2、5G建站指数  3、4G建站指数  4、潜在建站价值指数
  // 楼宇名称
  sceneClickName: null,
  // 筛选项
  dayType: 1, // 三天，七天数据筛选
  routeType: 1, //用户： 1， 网络：2 其他: null
  routeAreaType: null, // 用户分析:1, 网络优化:2 ,场景: 3 ，  商机:4 ,
  showLayer: false, // 显示图层 true/false
  layerBoxTop: 185, //图层的top高度
  mnc: null, //运营商 0:移动, 1:联通， 11：电信

  //  1+4':4G网络覆盖,'1+5' :5G网络覆盖,'4+4':4G网络质量,'4+5':5G网络质量
  // '3+4':4G用户分布,'3+5':5G用户分布,'5+4':4G漫入用户分布,'5+5':5G漫入用户分布
  // 8+：新漫入热力图
  network: '3+',
  oldNetwork: null, //热力图关闭前network
  ptMonth: null, //月份
  zoneId: null, //区域
  townId: null, //街道id
  villageId: null, //自然村id
  companyId: null,
  networkGridId: null, //网格
  microGridId: null, //微格
  keyWord: null, // 搜索词

  // 右侧表格搜索词表格id
  userLineId: null,

  userAnalysisType: null, //用户分析类型

  areaType: null, //场景类型
  deepCoverType: null, //深度覆盖类型
  userType: null, //商机类型
  buildingCoverType: null, //楼宇覆盖类型
  offset: null, //筛选网络的条件
  newIncome: null, //新漫入图形条件
  type: null, //筛选春促栅格类型
  offsetCId: null, //网络覆盖率的 选中id
  offsetSort: {
    sortType: null,
    sortStr: null
  }, //网络覆盖率的sort 包含 sortType、sortStr

  perspective: 1, // 经纬度分析：1，方位角分析：2
  stateType: 1, //网络类型 1全部
  longitudeType: 1, //经纬度偏差 1全部
  azimuthType: 1, //方位角偏差 1全部
  cId: null, //经纬度/ 方位角 关键字段id，
  lonAndAzSortType: null, //经纬度方位角的 排序
  mapId: 1, // 平面图：1， 卫星图：2

  //是否竞对
  compare: false,
  //开启竞对 的列表
  rMnc: null, //右侧对比运营商
  rMonth: null, //右侧对比月份

  // 图层
  allBoundaryType: null, //绘制多边界的type (无点击事件);
  boundaryFlag: false,
  networkShow: true, //业务图层
  communityList: [], //小区工参的 多选列表；
  customList: [], //自定义参数的列表；
  businessLayer: null,
  businessState: {},
  // 筛选项所选列表
  mncList: [], // 运营商可选列表
  rMncList: [], //右侧竞对的运营商列表
  networkList: [], //图层列表
  monthList: [], //月份列表
  zoneList: [], //区县列表
  //zoneList: [], //区县列表
  companyList: [], //子公司列表
  networkGridList: [], //网格列表
  microGridList: [], //微格列表
  townList: [], //街道列表
  villageList: [], //自然村列表

  areaTypeList: [], //场景列表
  offsetList: [], //网络分析列表

  // 右侧菜单列表
  lonList: [], //经纬度 列表
  azList: [], //方位角 列表
  offsetCoverageList: [], //网络覆盖率列表
  searchWordList: [], //搜索词列表

  // 右侧筛选列表
  inUseAttrList: [], //正在使用的筛选项的列表
  filterList: [], //筛选列表的 表格
  filterPageNum: 1,
  filterPageSize: 10,
  filterTotal: 1,
  filterPtday: null, // 数据时间
  filterSort: {
    sortType: null,
    sortStr: null
  },
  dataSelectList: [], //筛选列表的 筛选项列表

  userLineDetails: null, //用户点击的详情列表
  userLineDetailsLabel: {
    //中文注解
    name: '区域',
    numAll: '用户数量',
    mobileBelongRatio: '移动归属',
    mobileCoverRate: '移动覆盖',
    mobileRatio: '移动常驻',
    unicomBelongRatio: '联通归属',
    unicomCoverRate: '联通覆盖',
    unicomRatio: '联通常驻',
    telecomBelongRatio: '电信归属',
    telecomCoverRate: '电信覆盖',
    telecomRatio: '电信常驻',
    mobileUserRatio: '移动用户占比',
    unicomUserRatio: '联通用户占比',
    telecomUserRatio: '电信用户占比',
    mobileContrastRatio: '移动份额异动比例',
    unicomContrastRatio: '联通份额异动比例',
    telecomContrastRatio: '电信份额异动比例',
    mobileUserDifference: '移动异网用户数差值',
    unicomUserDifference: '电信异网用户数差值',
    telecomUserDifference: '联通异网用户数差值',

    // 常驻漫入分析
    userNum: '用户数量',
    mobileRoamingRatio: '移动漫入',
    telecomRoamingRatio: '电信漫入',
    unicomRoamingRatio: '联通漫入',
    mobileRatioDiff: '常驻差值',
    telecomRatioDiff: '常驻差值',
    unicomRatioDiff: '常驻差值',
    mobileRoamingRatioDiff: '漫入差值',
    telecomRoamingRatioDiff: '漫入差值',
    unicomRoamingRatioDiff: '漫入差值',

    // 预警记录表
    message: '预警原因',
    ptDay: '时间',

    // 广告点位
    numAll45g: '人流总流量',
    mobileNumAll4g: '移动4G人流量',
    telecomNumAll4g: '电信4G人流量',
    unicomNumAll4g: '联通4G人流量',
    mobileNumAll5g: '移动5G人流量',
    telecomNumAll5g: '电信5G人流量',
    unicomNumAll5g: '联通5G人流量',

    // 新增用户分析
    mobileNumAll: '移动',
    unicomNumAll: '联通',
    telecomNumAll: '电信',
    // 4,5G用户分析
    /*  mobileNumAll4g: '移动4G',
      mobileNumAll5g: '移动5G',
      unicomNumAll4g: '联通4G',
      unicomNumAll5g: '联通5G',
      telecomNumAll4g: '电信4G',
      telecomNumAll5g: '电信5G'*/
    mobileCover4g: '移动4G覆盖',
    unicomCover4g: '联通4G覆盖',
    telecomCover4g: '电信4G覆盖',
    mobileCover5g: '移动5G覆盖',
    unicomCover5g: '联通5G覆盖',
    telecomCoverg: '电信5G覆盖',
    mobileFiveInFour: '移动5G覆盖指数',
    unicomFiveInFour: '联通5G覆盖指数',
    telecomFiveInFour: '电信5G覆盖指数',
  },
  coverLineDetailsLabel: {
    //中文注解
    name: '区域',
    numAll: '用户数量',
    mobileBelongRatio: '移动归属',
    mobileCoverRate: '移动覆盖',
    mobileRatio: '移动常驻',
    unicomBelongRatio: '联通归属',
    unicomCoverRate: '联通覆盖',
    unicomRatio: '联通常驻',
    telecomBelongRatio: '电信归属',
    telecomCoverRate: '电信覆盖',
    telecomRatio: '电信常驻',
    mobileUserRatio: '移动用户占比',
    unicomUserRatio: '联通用户占比',
    telecomUserRatio: '电信用户占比',

    // 新增用户分析
    mobileNumAll: '移动',
    unicomNumAll: '联通',
    telecomNumAll: '电信',
  },
  userLineDetailsAdd: [
    //详情列表 展示顺序
    'numAll',
    'mobileRatio',
    'unicomRatio',
    'telecomRatio',
    'mobileBelongRatio',
    'unicomBelongRatio',
    'telecomBelongRatio',
    'mobileCoverRate',
    'unicomCoverRate',
    'telecomCoverRate'
    // 'mobileUserRatio', 'unicomUserRatio', 'telecomUserRatio',
    // 'mobileContrastRatio', 'unicomContrastRatio', 'telecomContrastRatio',
    // 'mobileUserDifference','unicomUserDifference','telecomUserDifference'
  ],
  allAttrList: [
    //完整可显示属性
    'name',
    'numAll',
    'mobileRatio',
    'unicomRatio',
    'telecomRatio',
    'mobileBelongRatio',
    'unicomBelongRatio',
    'telecomBelongRatio',
    'mobileCoverRate',
    'unicomCoverRate',
    'telecomCoverRate'
  ],

  // echarts图表内容
  panelType: '1b', //详情里图表的第一行类型选项: 1用户，2网络,1b用户趋势，2b网络趋势
  panelCellType: 4, //详情 图表 的第二行选项: 0全部, 4 4g， 5 5g
  resultType: null, //详情图表的省内省际选项 null全部，10本地，11省内，12省际
  echartsData: [], //echarts 获取的数据
  echartsNumData: {}, //详情数量数据
  indicatorType: 0,
  goBackType: 0,
  day: null,
  modalVisible: false,
  dateList: [],
  scene_draw_data: {}, //圈画的数据
  current_draw_id: null,
  draw_round_arr: [],
  draw_table_arr: [], //圈画表格数据
  draw_table_loading: false,
  //场景数据
  dataMonth: [],
  sceneModal: {visible: false},
  assessmentModal: {visible: false},
  recordModal: {visible: false, isRemind: false},
  // 春促数据报告面板
  yearModal: {visible: false},
  quotaArr: [],
  quota: null,
  filterShowBox: false,
  briefingModal: {
    //企业信息和渠道选址-数据简报的弹窗
    visible: false,
    remindFlag: false
  },
  conditionParams: {},
  curAreaModal: false,
  companyParams: {},
  historySelectData:JSON.parse(localStorage.getItem("historySelectData"))||{},
  companyDetailParams: {},
  companyDetailChange: {},
  // 详情框label类别
  userLineType: null,
  userTypeName: '用户份额',
  // 用户分析tab类型
  sourceType: 1,
  // 用户总览数据
  userAllForm: {},
  sceneSwitch: false,
  xMin: null,
  yMin: null,
  xMax: null,
  yMax: null,
  _cellId: null,
  scoreId: null,
  bnetType: 0,
  drawShow: true,
  compareShow: false,
  shiftType: true,
  compareDetail: null,
  activeMenu: null,
  fourColor: '#37D17F',
  fiveColor: '#999',
  firstFour: null,
  middleFive:null,
  lastFour:null,
  successFirs: null,
  detailsShow: false,
  timeout: null,
  getLevel: null, // 地图缩放等级
  businessLevel: null,
  treeLevel: null,
  usedNetwork: null,// 45Gcell对比
  // caliberTypeShow: false, // 2024务工
  isMenuShow: true,// 菜单显示
  subjectType: null,// 专题类型
  subjectDataShow: false,//
  dialogFormVisible: false,// 弹窗dialog
  compare4gCoverType: null,// 4G 0好 1差
  compare5gCoverType: null,// 5G 0好 1差
  compareLocalRatioType: null,// 本地
  compareNotProvOutRatioType: null,// 本地+省内 0:高 1：低
  compareRatioType: null,// 本地+省内+省际 0:高 1：低
  tableLoding: false,// 表格加载
  compareBroadRatioType: null,// 宽带份额 0好 1差
  compareUserRatioType: null,// 用户份额 0好 1差
  informationType: null,// 详细信息
  areaName: null,// 居民区名称
  isActiveShow: false,// 边界显示
  areaLevel: null,// 场景等级
  locationVisible: null,// 定位
  draw_area: null, //
  areaNameShow: true,
  dialogVisibleFiltrate: false, // 筛选dialog
  districtOption: [],//行政区树形
  moveData: null,
  companyOption: [],
  multipleSelectionList: [],
  dialogVisibleCreate: false, // 创建企业dialog
  oldFiltrate: null,
  filtrateObj: null,// 筛选条件 类型与值
  activeFiltrate: true, // 是否跳转
  bnetId: null,// 
  oldCombinedParams: null,// 产业园区参数
  zoomType:0,
  tableParams:{},
}

const mutations = {
  setIsMenuShow(state, data) {
    state.isMenuShow = data
  },
  setMncOnly(state, data) {
    state.mnc = data
    // 关闭筛选列表选中
    if (state.userLineId !== null)
      this.commit('gisForm/setUserLineId', { data: null, jumpMap: false })
    // 修改运营商列表
    this.commit('gisForm/setMncList')
  },
  // 万能修改vuex state数据
  setState(state, { name, data, obj = false}) {
  // 使用逻辑或操作符简化对obj的检查
  // if (obj) {
  //   state[name] = { ...state[name], obj: data }
  // } else {
  //   state[name] = data
  // }
  state[name] = obj ? { ...state[name], obj: data } : data
  },
  // 根据字数移动距离
  setNumberSizi(state, data){
      state.NumberSizi = 6 + 3 * data
  },
  //更多详情id
  // setMoreDetailId(state, data) {
  //   state.moreDetailId = data
  // },
  // scoreType 建站type
  showScoreType(state, data) {
    state.scoreType = data
    if (data) {
      this.dispatch('gisForm/changeDetailsData')
    }
  },
  // 主菜单选项
  setActiveMenu(state, data) {
    state.activeMenu = data
  },
  // 对比详情数据
  setCompareDetail(state, { data, ifNav = false }) {
    state.compareDetail = data
  },
  // 拖动不点击
  setShiftType(state, data){
    state.shiftType = data
  },
  // 对比详情 切换
  setCompareShow(state, data) {
    state.compareShow = data
  },
  // esc取消圈画
  setEscShow(state, data){
    state.escShow = data
  },
  // 圈画
  setDrawShow(state, data){
    state.drawShow = data
  },
  // 商企 切换
  setBnetType(state, data) {
    state.bnetType = data
  },
  //
  setMapClickList(state, data) {
    if (state.mapClickList.some(item => item === data)) {
      state.mapClickList = state.mapClickList.filter(item => item !== data)
    } else {
      state.mapClickList.push(data)
    }
  },
  setScoreId(state, data){
    state.scoreId = data
    // 详情图表接口
    state.userLineDetails.id = data
    state.userLineId = data
  },
  // 查询展示小区覆盖范围
  showCellRange(state, {data,status}) {
    //console.log("showCellRange",state)
    let result = {}
    if(state._cellId == data){
      this.commit('gisForm/setCompanyDetailChange', {data})
      let gisObj = this.state.gisPage.gisObj
      if(this.state.home.userInfoVo.cityId == '320200'){
        result.data = ''
        gisObj.setGrids_custom(status, result)
      }else{
        gisObj.setGrids_50(status, '')
      }
      state._cellId = null
      return
    }else{
      state._cellId = data
    }
    this.cellId = data
    let mnc = state.mnc
    let ptMonth = state.ptMonth
    if (status == 2) {
      mnc = state.rMnc
      ptMonth = state.rMonth
    }
    // 详情数量接口
    let params = {
      cityId: this.state.home.userInfoVo.cityId,
      cellId: this.cellId,
      mnc: mnc,
      areaType: state.areaType,
      companyId: state.companyId,
      networkGridId: state.networkGridId,
      microGridId: state.microGridId,
      sourceType: state.sourceType,
      ptMonth: ptMonth
    }
    let gisObj = this.state.gisPage.gisObj
    getGridDataByCell(params).then(res => {
      if (res.code === 1 && res.data != null) {
        if (gisObj) {
          if(this.state.home.userInfoVo.cityId == '320200'){
            result.data = res.data.gridInfo
            gisObj.setGrids_custom(status, result)
          }else{
            gisObj.setGrids_50(status, res.data.gridInfo)
          }
        }
      }
    })
    this.commit('gisForm/setCompanyDetailParams', params)
  },
  displayCellDetail(state, data) {
    this.commit('gisForm/setCompanyDetailChange', {data})
  },
  showBadDetail(state, {data, jumpMap}) {
    // 黑点差点 点击栅格详情
    let params =
      {
        cellType: state.network.split('+')[1] == 5 ? 5 : 4,
        gridId: data,
        ptMonth: this.state.gisForm.ptMonth,
        cityId: this.state.home.userInfoVo.cityId,
        type: parseInt(this.state.gisForm.panelType)
      }

    let numParams = {
      cellType: state.network.split('+')[1] == 5 ? 5 : 4,
      gridId: data,
      ptMonth: this.state.gisForm.ptMonth,
      cityId: this.state.home.userInfoVo.cityId,
    }

    // 详情图表接口
    state.userLineDetails = {
      id: data,
    }
    state.userLineId = data
    getDataAnalysis(params).then(res => {
      if (res.code === 1) {
        this.commit('gisForm/setEchartsData', res.data)
      }
    })
    // 详情数量接口
    getUserAnalysisDetailData(numParams).then(res => {
      if (res.code === 1) {
        this.commit('gisForm/setEchartsNumData', res.data)
      }
    })
  },
  showDeepDetail(state, {data, network, jumpMap}) {
    // 4g 5g 深度覆盖 点击栅格详情
    let sourceType = this.state.gisForm.sourceType
    let params =
      {
        cellType: state.network.split('+')[1] == 5 ? 5 : 4,
        buildId: data,
        ptMonth: this.state.gisForm.ptMonth,
        cityId: this.state.home.userInfoVo.cityId,
        type: parseInt(this.state.gisForm.panelType)
      }

    let numParams = {
      cellType: state.network.split('+')[1] == 5 ? 5 : 4,
      ptMonth: this.state.gisForm.ptMonth,
      buildId: data,
      cityId: this.state.home.userInfoVo.cityId,
    }

    // 详情图表接口
    state.userLineDetails = {
      id: data,
    }
    state.userLineId = data
    state.panelCellType = '4'
    getDataAnalysis(params).then(res => {
      if (res.code === 1) {
        this.commit('gisForm/setEchartsData', res.data)
      }
    })
    // 详情数量接口
    getUserAnalysisDetailData(numParams).then(res => {
      if (res.code === 1) {
        this.commit('gisForm/setEchartsNumData', res.data)
      }
    })
  },

  setMapTreeLevel(state, {type}){
    state.treeLevel = type
  },

  setFiltrateList(state, {data,falg=true }){
    let obj = {}
    obj = setDataDivisionId(data,this)
    if (falg) {
      state.filtrateObj = {
      data,
      ...obj
      }
    }
    this.dispatch('gisForm/changeFiltrateList',{...obj})
  },

  businessZoomShow(state, {type, id, treeLevel, xmin, ymin, xmax, ymax, mapLevel}) {
    if (![37, 38, 39, 40, 18].includes(state.activeMenu)) return
    if (!treeLevel && id && [37, 38, 18].includes(state.activeMenu)) { //type == 1 &&
      return this.commit('gisForm/setState', {name: 'bnetId', data: {id}})
    } 
    // let sourceType = this.state.gisForm.sourceType
    //概览和新增 todo
    /*if(sourceType != 37 && sourceType != 38){
      return
    }*/
    if(type == 2){
      if(this.state.gisForm.businessLayer){
        this.state.gisForm.businessLayer.hide()
      }
      this.commit('gisForm/setBusinessRegion', null)
      return
    }
    console.log(type,mapLevel,'businessZoomShow');
    //**点击事件**
    if(id){
      let method = getLevelMethod(this, treeLevel)
      //保存原始值
      //saveOrReCoverState(this, 1)
      this.commit(method.name, {data: id})
      this.commit('gisForm/setFiltrateList',{data:method.id})
      //恢复原始值
      //saveOrReCoverState(this, 2)
      return
    }
    //**地图缩放事件**
    //取最小level
    state.businessLevel = mapLevel
    let curLevel = mapLevel
    if(state.treeLevel > curLevel){
      curLevel = state.treeLevel
    }
    let data = {
      xmin: xmin,
      ymin: ymin,
      xmax: xmax,
      ymax: ymax,
      level: curLevel
    }
    this.commit('gisForm/setBusinessRegion', data)
  },

  dynamicShowArea(state, {type, xmin, ymin, xmax, ymax, getLevel}) {
    console.log(getLevel,'getLevel');
    state.getLevel = getLevel
    let sourceType = this.state.gisForm.sourceType
    if (sourceType != 12 && sourceType != 13) {
      return
    }
    if(window.location.pathname != '/gisPage/network-new'){
      return
    }
    if (type == 1) {
      state.xMin = xmin
      state.yMin = ymin
      state.xMax = xmax
      state.yMax = ymax
      if ( !getLevel) {
        return
      }else if (getLevel <= 12) {
        return
      }
      queryAllBoundaryHaveClick2(this, state, 1)
      if (state.compare) {
        queryAllBoundaryHaveClick2(this, state, 2)
      }
      // 获取详情列表
      //this.dispatch('gisForm/changeFilterList', {})
    } else {
      state.xMin = null
      state.yMin = null
      state.xMax = null
      state.yMax = null
      let gisObj = this.state.gisPage.gisObj
      gisObj.clearAreaGrid2()
    }
  },
  initNetwork(state, {type}) {
    if(type == 4){
      state.fourColor = '#37D17F';
      state.fiveColor = '#999';
    }
  },
  //4 5G 切换
  submitNetwork(state, {type}) {
    if (type === 4) {
      state.fourColor = '#37D17F';
      state.fiveColor = '#999';
    } else if (type === 5) {
      state.fourColor = '#999';
      state.fiveColor = '#37D17F';
    }
    let sourceType = this.state.gisForm.sourceType
    let menu = {}
    //常驻
    if(sourceType == 1 || sourceType == 4){
      menu = (type == 4 ? Comstant.userAnalysisTypeNewArr[0] : Comstant.userAnalysisTypeNewArr[2])
    } else if(sourceType == 6 || sourceType == 7){
      menu = (type == 4 ? Comstant.userAnalysisTypeNewArr[4] : Comstant.userAnalysisTypeNewArr[5])
    } else if(sourceType == 12 || sourceType == 13){
      menu = (type == 4 ? Comstant.userAnalysisTypeNetworkArr[6] : Comstant.userAnalysisTypeNetworkArr[7])
      this.commit('gisForm/setDeepCover', menu.sourceType)
      // if (state.network.split('+')[0] == 15) {
      this.commit('gisForm/dynamicShowArea', {type:1, xmin:state.xMin, ymin:state.yMin, xmax:state.xMax, ymax:state.yMax, getLevel:state.getLevel})
      // }
    } else if (sourceType == 20 || sourceType == 21) { // 深度覆盖
      menu = type == 4 ? {
        value: '20+4', sourceType: 20,
      } : {
        value: '20+5', sourceType: 21,
      }
    } else if (sourceType == 22 || sourceType == 23) { // 质差
      menu = type == 4 ? {
        value: '22+4', sourceType: 22,
      } : {
        value: '22+5', sourceType: 23,
      }
    } else if (sourceType == 10) { // 黑点
      menu = type == 4 ? {
        value: '13+4', sourceType: 10,
      } : {
        value: '13+5', sourceType: 10,
      }
    } else if (sourceType == 11) { // 差点
      menu = type == 4 ? {
        value: '14+4', sourceType: 11,
      } : {
        value: '14+5', sourceType: 11,
      }
    } else if(sourceType==41) {
      menu = type == 4 ? {
        value: '', sourceType,
      } : {
        value: '', sourceType,
      }
    } else {

    }

    this.commit('gisForm/setPanelCellType', {data: type, changeEchartsData: false})
    this.commit('gisForm/setUserLineId', {data: null}) // 详情弹窗

    if (this.state.gisForm.networkShow == false) {
      this.state.gisForm.oldNetwork = menu.value
      this.commit('gisForm/setNetwork', null)
    } else {
      this.commit('gisForm/setNetwork', menu.value)
    }
    if (sourceType == 10 || sourceType == 11) { // 黑点 差点
      if (state.sceneSwitch || state.areaType == '行政村') {
        this.commit('gisForm/setAreaType', state.areaType)
      } else {
        queryBigGrid(this, state)
      }
    }
    console.log(menu,'menu1122');
    this.commit('gisForm/setUserSourceType', menu.sourceType)
  },
  setOffsetCId(state, {data, jumpMap}) {
    //console.log('setOffsetCId', data)
    state.offsetCId = data
    let gisObj = this.state.gisPage.gisObj

    if (
      this.state.gisPage.gisObj !== null &&
      this.state.gisPage.iframeUrl == 'index' &&
      data !== null
    ) {
      if (jumpMap) {
        // 跳转到当前列表点
        zoomToBigGrid(data, this.state.gisPage.gisObj)
      } else {
        // 地图点跳转到选中id页 组件内监控判断
        if (state.activeMenu == 41) {
          let xhr = new XMLHttpRequest()
          xhr.open(
            'GET',
            process.env.VUE_APP_API_URL +
              '/caict/boundaryData/getAreaFirePontNew?cityId=' +
              this.state.home.userInfoVo.cityId +
              '&areaId=' +
              data,
            true
          )
          xhr.setRequestHeader(
            'Authorization',
            'Bearer ' + localStorage.getItem('Authorization')
          )
          xhr.onloadend = function(_event) {
            const res = JSON.parse(xhr.response)
            if (res.data) {
              gisObj.setPoints(1, res.data)
              gisObj.showPoint(1)
            } else {
              gisObj.hidePoint(1)
            }
          }
          xhr.send()
        }
      }
    }
  },

  //用户分析tab框切换名
  setUserTypeName(state, data) {
    state.userTypeName = data
  },
  setUserSourceType(state, data) {
    state.sourceType = data
    this.dispatch('gisForm/changeFilterList', {})
  },
  setSceneSwitch(state, data) {
    state.sceneSwitch = data
    // this.dispatch('gisForm/changeFilterList', {})
  },
  //企业信息和渠道选址-数据简报的弹窗
  setBriefingModal(state, data) {
    state.briefingModal = data
  },
  //历史搜索栏
  setHistorySelectData(state, {data,searchType}){
    const historyData=state.historySelectData[searchType]||[]
    if(historyData.length>=10){
      historyData.shift()
    }
    if(historyData.every(item=>item.name!=data.name)){
      historyData.push(data)
    }
    state.historySelectData[searchType]=historyData
    localStorage.setItem("historySelectData",JSON.stringify(state.historySelectData))
  },
  setCompanyParams(state, data) {
    state.companyParams = data
  },
  setCompanyDetailParams(state, data) {
    state.companyDetailParams = data
  },
  setCompanyDetailChange(state, data) {
    state.companyDetailChange = data
  },
  setCurAreaModal(state, data) {
    state.curAreaModal = data
  },
  /**
   *企业名称（精确搜索）  "companyName":"",
   开始时间（新增公司成立时间）"startTime":"",
   结束时间（新增公司成立时间）"endTime",
   时间段（null:无限制；1:今天；2:本周；3:本月）"timeSlot":0,
   边界"boundary":"1231,123123",
   */
  setConditionParams(state, data) {
    state.conditionParams = data
  },
  setFilterShowBox(state, data) {
    state.filterShowBox = data
  },
  setQuotaArr(state, data) {
    state.quotaArr = data
  },
  setDataMonth(state, data) {
    state.dataMonth = data
  },
  setQuotaValue(state, data) {
    state.quota = data
  },
  setSceneModal(state, data) {
    state.sceneModal = data
  },
  setAssessmentModal(state, data) {
    state.assessmentModal = data
  },
  setRecordModal(state, data) {
    state.recordModal = data
  },
  setYearModal(state, data) {
    state.yearModal = data
  },
  setRouteType(state, data) {
    state.routeType = data
    // // 修改网络制式图层选择列表
    // this.commit('gisForm/setNetworkList');
  },
  setModalVisible(state, data) {
    state.modalVisible = data
  },

  setAreaIdqueryBoundary(state, data) {
    queryLocateArea(this, state, null, data)
  },
  setRouteAreaType(state, data) {
    // 选择任意值 需清空之前的选项
    let commitStr =
      'gisForm/' +
      (state.routeAreaType == 3
        ? 'setAreaType'
        : state.routeAreaType == 4
          ? 'setUserType'
          : (state.routeAreaType == 2 || state.routeAreaType == 12)
            ? 'setOffset'
            : state.routeAreaType == 5
              ? 'setType'
              : state.routeAreaType == 6
                ? 'setNewIncome'
                : '')
    if (commitStr.length > 8) {
      this.commit(commitStr, null)
    }
    state.routeAreaType = data
  },
  setShowLayer(state, data) {
    if (data) {
      // 设置图层 top
      let windowHeight = window.innerHeight
      let layerBoxHeight = 407
      this.commit('gisForm/setLayerBoxTop', windowHeight - layerBoxHeight)
    }
    state.showLayer = data
    if (!data) {
      // 设置成默认值 重新打开 重新计算
      this.commit('gisForm/setLayerBoxTop', 185)
    }
  },
  setLayerBoxTop(state, data) {
    state.layerBoxTop = data
  },

  setMnc(state, data) {
    state.mnc = data
    // 关闭筛选列表选中
    if (state.userLineId !== null)
      this.commit('gisForm/setUserLineId', {data: null, jumpMap: false})
    // 修改运营商列表
    this.commit('gisForm/setMncList')
    // 修改网络分析列表
    this.commit('gisForm/setOffsetList')
    // 对比
    if (state.compare) {
      this.dispatch('gisForm/changeCompareDetail', {})
    }
    // 修改完mnc 网络分析列表改变  offset需置空
    if (state.network.split('+')[0] == 19 || state.network.split('+')[0] == 13 || state.network.split('+')[0] == 14) {
      this.commit('gisForm/setOffset', state.offset)
    }
    if (state.network.split('+')[0] == 15) {
      //深度覆盖
     /* this.clearCellINNet(item.value)
      this.clearCellOther(item.value)*/
      let sourceType = '12'
      if (state.network.split('+')[1] == '5') {
        sourceType = '13'
      }
      this.commit('gisForm/setDeepCover', sourceType)
    }
    if (state.network.split('+')[0] == 15) {
      this.commit('gisForm/dynamicShowArea', {type:1, xmin:state.xMin, ymin:state.yMin, xmax:state.xMax, ymax:state.yMax, getLevel:state.getLevel})
      }
    // 查询当前 网格&热力 图层
    queryBoundary(
      this,
      state.network,
      state.mnc,
      state.ptMonth,
      state.day,
      state.type,
      1
    )
  },
  setNetwork(state, data) {
    state.network = data
    // 查询当前 网格&热力 图层
    queryBoundary(
      this,
      state.network,
      state.mnc,
      state.ptMonth,
      state.day,
      state.type,
      1
    )
    if (state.compare) {
      queryBoundary(
        this,
        state.network,
        state.rMnc,
        state.rMonth,
        state.day,
        state.type,
        2
      )
    }
  },
  setBusinessRegion(state, data) {
    //todo
    /*if(state.sourceType != 37 || state.sourceType != 38){
      return
    }*/
    let type = state.companyId ? 2 : 1
    let levelName = getLevelName(this, type, data ? data.level : null)
    const obj = setDataDivisionId(type,this)
    let params = {
      weekAdd: state.activeMenu == 38 ? true : null,
      cityId: this.state.home.userInfoVo.cityId,
      ...obj
    }
    let combinedParams
    if ([37, 38].includes(state.activeMenu)) {
      combinedParams = { ...params, ...data };
    } else if ([39, 40].includes(state.activeMenu)) {
      combinedParams = { ...params };
    } else if ([18 ].includes(state.activeMenu)) {
      combinedParams = { ...params };
    }
    //层级
    combinedParams.level = levelName
    if(!data && (!state.townId || state.townId == '全部')
      && (!state.villageId || state.villageId == '全部')
      && (!state.networkGridId || state.networkGridId == '全部')
      && (!state.microGridId || state.microGridId == '全部')){
      if(state.zoneId == this.state.home.userInfoVo.cityId || state.zoneId == '全部'){
        combinedParams.level = 'cityZone'
      }else if(state.companyId == this.state.home.userInfoVo.cityId || state.companyId == '全部'){
        combinedParams.level = 'cityCompany'
      }
    }


    let regionType = 'circle'
    if ([37, 38].includes(state.activeMenu)) { // 概览 新增
      getBusinessRegionList(combinedParams).then(res => {
        if (res.code == 1) {
          if(res.data.length > 0){
            if(!res.data[0].level){
              regionType = 'point'
            }
            showBusinessRegionList(this, res.data, regionType)
          }else{
            state.informationType = res
          }
        } else {
          // this.$message.error('商企数据获取异常！')
        }
      })
    } else if ([39, 40].includes(state.activeMenu)) { // 楼宇 产业
      // combinedParams.radioType = state.activeMenu == 39 ? 1 : 2
      queryCompanySceneLevelCount({...state.oldCombinedParams, sid:null, companyId:combinedParams.searchCompanyId, ...combinedParams, searchCompanyId:null}).then(res => {
        if (res.code == 1) {
          let gisObj = this.state.gisPage.gisObj
          if(res.data.length > 0){
            if(!res.data[0].level){
              if (res.data[0]?.boundary && gisObj) {
                //  有点击效果
                gisObj.unSelectArea()
                gisObj.setArea(res.data[0].boundary)
              } else if (!res.data[0]?.boundary) {
                gisObj.clearArea()
              }
            } else {
              gisObj.clearArea()
              showBusinessRegionList(this, res.data, regionType)
            }
          }else{
            state.informationType = res
          }
        } else {
          // this.$message.error('商企数据获取异常！')
        }
      })
    } else if (state.activeMenu == 18) { // 营业厅
      queryBusinessHallLevelCount({...state.oldCombinedParams, companyId:null, ...combinedParams}).then(res => {
        if (res.code == 1) {
          if(res.data.length > 0){
            let AlphaType = false
            if(!res.data[0].level){
              regionType = 'point'
            }
            showBusinessRegionList(this, res.data, regionType, AlphaType)
          }else{
            state.informationType = res
          }
        } else {
          // this.$message.error('营业厅数据获取异常！')
        }
      })
    }
  },
  setNetworkType(state, data) {
    queryBoundary(
      this,
      data,
      state.mnc,
      state.ptMonth,
      state.day,
      state.type,
      1
    )
    if (state.compare) {
      queryBoundary(
        this,
        data,
        state.rMnc,
        state.rMonth,
        state.day,
        state.type,
        2
      )
    }
  },
  clearCellINNet(state, data) {
    let gisObj = this.state.gisPage.gisObj
    if (gisObj) {
      gisObj.hideCell(1)
      gisObj.hideCell(2)
      gisObj.hideCellRight(1)
      gisObj.hideCellRight(2)
    }
  },
  clearBadCover(state, data) {
    let gisObj = this.state.gisPage.gisObj
    if (gisObj) {
      gisObj.closeBigGrid()
    }
  },
  clearArea2(state, data) {
    let gisObj = this.state.gisPage.gisObj
    if (gisObj) {
      gisObj.clearAreaGrid2()
      gisObj.clearAreaBB()
    }
  },
  clearArea(state, data) {
    let gisObj = this.state.gisPage.gisObj
    if (gisObj) {
      gisObj.clearArea()
    }
  },
  clearCellOther(state, data) {
    let gisObj = this.state.gisPage.gisObj
    if (gisObj) {
      const obj = {
        data: '',
        id: new Date().getTime(),
        type: 'cmcc'
      }
      gisObj.setGrids(obj)
      gisObj.setContrastGrids(obj)
      gisObj.closeBigGrid()
    }
  },
  clearData(state) {
    //重置页面清空数据
    state.ptMonth = null
    state.day = null
    state.type = null
  },
  setPtMonth(state, data) {
    state.ptMonth = data
    // 查询当前 网格&热力 图层
    queryBoundary(
      this,
      state.network,
      state.mnc,
      state.ptMonth,
      state.day,
      state.type,
      1
    )
    // 查询当前 经纬度或 方位角图层
    queryLonAndAz(this, state)
    // 查询 当前大栅格
    if (state.network.split('+')[0] == 19 || state.network.split('+')[0] == 13 || state.network.split('+')[0] == 14) {
      queryBigGrid(this, state)
    }
    // 查询 当前筛选列表
    this.dispatch('gisForm/changeFilterList', {})
    //对比打开
    if (state.compare) {
      this.dispatch('gisForm/changeCompareDetail', {})
    }
    if (state.network.split('+')[0] == 15) {
      //深度覆盖
      /* this.clearCellINNet(item.value)
       this.clearCellOther(item.value)*/
      let sourceType = '12'
      if (state.network.split('+')[1] == '5') {
        sourceType = '13'
      }
      this.commit('gisForm/setDeepCover', sourceType)
    }
    if (state.userLineId) {
      if (state.sourceType == 19) {
        return this.$store.dispatch('gisForm/changeDetailsData')
      }
      this.dispatch('gisForm/changeEchartsData')
    }
  },
  setSingleBoundary(state,{label}){
    queryLocateArea(this, state, label, state[label])
  },
  setZoneId(
    state,
    {data, ifNav = false, flag = false, townId = '全部', getBoundary = false}
  ) {
    let oldData = state.zoneId
    state.zoneId = data
    //将页码重置为1
    state.filterPageNum=1
    if (getBoundary) {
      //如果传入getBoundary 代表需要展示区域边界
      getAllBoundary({
        zoneId: data,
        type: 2
      }).then(res => {
        let gisObj = this.state.gisPage.gisObj
        if (res && gisObj) {
          //  有点击效果
          gisObj.unSelectArea()
          gisObj.setArea(res.data)
          gisObj.clearBoundary()
        } else if (!res) {
          gisObj.clearArea()
        }
      })
    }
    //对比打开
    if (state.compare) {
      this.dispatch('gisForm/changeCompareDetail', {})
    }
    // 获取区县列表
    if (oldData == null && data !== null) {
      // zoneId设置成时非null 网格id设置成null
      this.commit('gisForm/setCompanyId', {data: null})
      // this.dispatch('gisForm/changeZoneList')
    } else if (data == null && state.companyId == null) {
      this.commit('gisForm/setCompanyId', {
        data: this.state.home.userInfoVo.cityId
      })
    }

    if (data == null || data == this.state.home.userInfoVo.cityId) {
      // 当zoneId设置 成全部或null时 需置空 TownId
      if (data == this.state.home.userInfoVo.cityId) this.commit('gisForm/setTownId', {data: null, ifNav})
      // if (data == null) this.commit('gisForm/setState', {name:'townId',data:null})
      if (
        data == this.state.home.userInfoVo.cityId &&
        this.state.gisPage.gisObj !== null
      ) {
        // 选中本市 跳转回本市
        let item = this.state.gisPage.dictionary.find(
          v => v.id == this.state.home.userInfoVo.cityId
        )
        setBoundary(item, this.state.gisPage.gisObj)
      }
    } else if (state.zoneList.find(v => v.id == data) != undefined) {
      // 当zoneId 为区县列表中其他值时 则设置 街道为全部 调用获取街道列表
      if (state.routeAreaType == 5) {
        this.commit('gisForm/setTownId', {data: townId})
      // this.commit('gisForm/setState', {name:'townId',data:townId})
        if (townId == '全部') {
          this.commit('gisForm/setIndicator', 0)
          this.commit('gisForm/setModalVisible', false)
        }
      } else {
        this.commit('gisForm/setTownId', {data: '全部', ifNav})
        this.commit('gisForm/setVillageId', {data: '全部', ifNav})
      // this.commit('gisForm/setState', {name:'townId',data:'全部'})
      }
      // this.dispatch('gisForm/changeTownList', data)
    }
    // zoneId 改变会带来townId改动 所以和townId一起的不需要一起调接口(当前筛选列表)
    if (data != '全部' && data) {
      if (state.sourceType == 10 || state.sourceType == 11 || state.sourceType == 19) { // 黑点 差点
        if (state.sceneSwitch) {
        this.commit('gisForm/setAreaType', state.areaType)
        } else {
          queryBigGrid(this, state)
        }
      }
    }
  },
  setTownId(state, {data, ifNav = false, status}) {
    console.log("setTownId",data)
    //将页码重置为1
    state.filterPageNum=1
    state.townId = data
    if (state.routeAreaType == 5) {
      if (data == '全部' || !data) {
        this.commit('gisForm/setNetwork', null)
        this.commit('gisForm/setCompare', false)
        this.commit('gisForm/setHolidayType', 11)
      } else {
        if (state.dateList && state.dateList.length) {
          const day = state.dateList ? state.dateList[0].day : null
          this.commit('gisForm/setDay', day)
        }
        this.commit('gisForm/setIndicator', data)
        this.commit('gisForm/setAllBoundaryType', null)
      }
    }
    //对比打开
    if (state.compare) {
      this.dispatch('gisForm/changeCompareDetail', {})
    }
    if (state.zoneId !== '全部' && state.townId !== '全部') {
      // 当zoneId 为区县列表中其他值时 则设置 街道为全部 调用获取街道列表
      // if (state.routeAreaType == 5) {
      //   this.commit('gisForm/setVillageId', { data: villageId })
      //   if (villageId == '全部') {
      //     this.commit('gisForm/setIndicator', 0)
      //     this.commit('gisForm/setModalVisible', false)
      //   }
      // } else {
      this.commit('gisForm/setVillageId', {data: '全部', ifNav})
      // }
      // this.dispatch('gisForm/changeVillageList', data)
    }
    if (data != '全部' && data) {
      if (state.sourceType == 10 || state.sourceType == 11 || state.sourceType == 19) { // 黑点 差点 站址规划
        if (state.sceneSwitch) {
        this.commit('gisForm/setAreaType', state.areaType)
        } else {
          queryBigGrid(this, state)
        }
      }
    }
    if (state.zoneId !== null) {
      // 查询 当前筛选列表
      this.dispatch('gisForm/changeFilterList', {ifNav})
      if (state.routeAreaType == 5) {
        if (this.state.gisPage.gisObj) {
          this.state.gisPage.gisObj.clearArea()
        }
      } else {
        // 查询当前 网格&热力 图层
        queryBoundary(
          this,
          state.network,
          state.mnc,
          state.ptMonth,
          state.day,
          state.type,
          1
        )
        if (state.compare) {
          queryBoundary(
            this,
            state.network,
            state.rMnc,
            state.rMonth,
            state.day,
            state.type,
            2
          )
        }
      }

      // 绘制多边界
      if (state.areaType !== null) queryAllBoundaryHaveClick(this, state)
      // 筛选项跳转
      let label = state.townId == '全部' ? 'zoneId' : 'townId'
      if (!ifNav && state.activeFiltrate) queryLocateArea(this, state, label, state[label])
    }
  },
  setQueryLocateArea(state) {
    let label
    let roleType = this.state.home.userInfoVo.roleType // 2 区县 1 市 3 网格 4 分公司
    if (roleType == 1) {
      if (state.zoneId ) {
        label = state.townId == '全部' ? 'zoneId' : 'townId'
      } else {
        label = state.networkGridId == '全部' ? 'companyId' : 'networkGridId'
      }
    } else if (roleType == 2) {
      label = state.villageId == '全部' ? 'townId' : 'villageId'
    } else if (roleType == 3) {
      label = state.microGridId == '全部' ? 'networkGridId' : state.microGridId ? 'microGridId' : 'networkGridId'
    } else if (roleType == 4) {
      label = state.networkGridId == '全部' ? 'companyId' : state.networkGridId ? 'networkGridId' : 'companyId'
    }
    queryLocateArea(this, state, label, state[label])
  },
  setQueryLocateAreaOnly(state, {data}) {
    queryLocateArea(this, state, false, data)
  },
  setVillageId(state, {data, ifNav = false, status}) {
    state.xMin = null
    state.yMin = null
    state.xMax = null
    state.yMax = null
    state.villageId = data
    //将页码重置为1
    state.filterPageNum=1
    if (state.routeAreaType == 5) {
      if (data == '全部' || !data) {
        this.commit('gisForm/setNetwork', null)
        this.commit('gisForm/setCompare', false)
        this.commit('gisForm/setHolidayType', 11)
      } else {
        if (state.dateList && state.dateList.length) {
          const day = state.dateList ? state.dateList[0].day : null
          this.commit('gisForm/setDay', day)
        }
        this.commit('gisForm/setIndicator', data)
        this.commit('gisForm/setAllBoundaryType', null)
      }
    }
    //对比打开
    if (state.compare) {
      this.dispatch('gisForm/changeCompareDetail', {})
    }
    if (state.zoneId !== null) {
      // 查询 当前筛选列表
      this.dispatch('gisForm/changeFilterList', {ifNav})
      if (state.routeAreaType == 5) {
        if (this.state.gisPage.gisObj) {
          this.state.gisPage.gisObj.clearArea()
        }
      } else {
        // 查询当前 网格&热力 图层
        queryBoundary(
          this,
          state.network,
          state.mnc,
          state.ptMonth,
          state.day,
          state.type,
          1
        )
        if (state.compare) {
          queryBoundary(
            this,
            state.network,
            state.rMnc,
            state.rMonth,
            state.day,
            state.type,
            2
          )
        }
      }

      // 绘制多边界
      if (state.areaType !== null) queryAllBoundaryHaveClick(this, state)
      // 筛选项跳转
      let label = state.villageId == '全部' ? 'townId' : 'villageId'
      if (!ifNav && state.activeFiltrate) queryLocateArea(this, state, label, state[label])
    }
  },

  setCompanyId(state, {data, ifNav = false}) {
    let oldData = state.companyId
    state.companyId = data
    //将页码重置为1
    state.filterPageNum=1
    // 获取网格列表
    // if( oldData == null && data == this.state.home.userInfoVo.cityId ) {
    if (oldData == null && data !== null) {
      // networkGridId设置非null时 zoneId设置成null，
      this.commit('gisForm/setZoneId', {data: null})
      this.dispatch('gisForm/changeNetworkGridList', data)
    } else if (data == null && state.zoneId == null) {
      this.commit('gisForm/setZoneId', {
        data: this.state.home.userInfoVo.cityId
      })
      return
    }
    if (data == null || data == this.state.home.userInfoVo.cityId) {
      // 当networkGridId设置 成全部或null时 需置空 microGridId
      this.commit('gisForm/setNetworkGridId', {data: null, ifNav})
      if (
        data == this.state.home.userInfoVo.cityId &&
        this.state.gisPage.gisObj !== null
      ) {
        // 选中本市 跳转回本市
        let item = this.state.gisPage.dictionary.find(
          v => v.id == this.state.home.userInfoVo.cityId
        )
        setBoundary(item, this.state.gisPage.gisObj)
      }
    } else if (state.companyList.find(v => v.id == data) != undefined) {
      // 当zoneId 为区县列表中其他值时 则设置 微格为全部 调用获取微格列表
      this.commit('gisForm/setNetworkGridId', {data: '全部', ifNav})
      this.dispatch('gisForm/changeNetworkGridList', data)
    }
    if (data != '全部' && data) {
      if (state.sourceType == 10 || state.sourceType == 11 || state.sourceType == 19) { // 黑点 差点
        if (state.sceneSwitch) {
        this.commit('gisForm/setAreaType', state.areaType)
        } else {
          queryBigGrid(this, state)
        }
      }
    }
    //对比打开
    if (state.compare) {
      this.dispatch('gisForm/changeCompareDetail', {})
    }
    this.dispatch('gisForm/changeFilterList', {ifNav}) //-t
    // networkGridId 改变会带来microGridId改动 所以和microGridId一起的不需要一起调接口(当前筛选列表)
  },

  setNetworkGridId(state, {data, ifNav = false}) {
    let oldData = state.networkGridId
    state.networkGridId = data
    //将页码重置为1
    state.filterPageNum=1
    console.log(data,state.networkGridList,111111)
    if (data == null) {
      this.commit('gisForm/setMicroGridId', {data: null, ifNav})
    } else {
      if (data == '全部') {
        // 当networkGridId设置 成全部或null时 需置空 microGridId
        this.commit('gisForm/setMicroGridId', {data: null, ifNav})
        // this.dispatch('gisForm/changeFilterList', { ifNav })
        queryBoundary(
          this,
          state.network,
          state.mnc,
          state.ptMonth,
          state.day,
          state.type,
          1
        )
        if (state.compare) {
          queryBoundary(
            this,
            state.network,
            state.rMnc,
            state.rMonth,
            state.day,
            state.type,
            2
          )
        }
        // 绘制多边界
        if (state.areaType !== null) queryAllBoundaryHaveClick(this, state)
        // 筛选项跳转
        let label =
          state.networkGridId == '全部' || state.networkGridId == null
            ? 'companyId'
            : 'networkGridId'
        if (!ifNav && state.activeFiltrate) queryLocateArea(this, state, label, state[label])
      } else if (state.networkGridList.find(v => v.id == data) != undefined) {
        // 当zoneId 为区县列表中其他值时 则设置 微格为全部 调用获取微格列表
        this.commit('gisForm/setMicroGridId', {data: '全部', ifNav})
        this.dispatch('gisForm/changeMicroGridList', data)
      }else if(state.networkGridList.find(v => v.id == state.companyId)&&!state.networkGridList.find(v => v.id == data)){
        //直接选择网格则进入这个判断
        this.commit('gisForm/setMicroGridId', {data: '全部', ifNav})
        this.dispatch('gisForm/changeMicroGridList', data)

      }
    }
    if (data != '全部' && data) {
      if (state.sourceType == 10 || state.sourceType == 11 || state.sourceType == 19) { // 黑点 差点 站址规划
        if (state.sceneSwitch) {
        this.commit('gisForm/setAreaType', state.areaType)
        } else {
        queryBigGrid(this, state)
        }
      }
    }
    //对比打开
    if (state.compare) {
      this.dispatch('gisForm/changeCompareDetail', {})
    }
  },
  setMicroGridId(state, {data, ifNav = false}) {
    state.microGridId = data
    //将页码重置为1
    state.filterPageNum=1
    //对比打开
    if (state.compare) {
      this.dispatch('gisForm/changeCompareDetail', {})
    }
    // 查询 当前筛选列表
    if (state.microGridId != null) {
      this.dispatch('gisForm/changeFilterList', {ifNav})
      // 查询当前 网格&热力 图层

      queryBoundary(
        this,
        state.network,
        state.mnc,
        state.ptMonth,
        state.day,
        state.type,
        1
      )
      if (state.compare) {
        queryBoundary(
          this,
          state.network,
          state.rMnc,
          state.rMonth,
          state.day,
          state.type,
          2
        )
      }
      // 绘制多边界
      if (state.areaType !== null) queryAllBoundaryHaveClick(this, state)
      // 筛选项跳转
      let label = state.microGridId == '全部' ? 'networkGridId' : 'microGridId'
      if (!ifNav && state.activeFiltrate) queryLocateArea(this, state, label, state[label])
    }
  },
  setKeyWord(state, {keyWord, setSearchListToId = null}) {
    console.log('setKeyWord', keyWord,setSearchListToId);
    //将页码重置为1
    state.filterPageNum=1
    // // 使用搜索时 关闭覆盖率列表
    // if( keyWord && state.offset  ) this.commit('gisForm/setOffset', null);

    // 清除选中id, 当搜索列表从有值到没值的时候 需要清空选中id
    if (!keyWord && state.keyWord && state.userLineId)
      this.commit('gisForm/setUserLineId', {data: null, jumpMap: false})
    if (!keyWord && state.keyWord && state.userType > 5) {
      state.keyWord = null
      this.dispatch('gisForm/changeFilterList', {})
    }

    state.keyWord = keyWord
    // 请求 搜索词列表
    if (keyWord) {
      if (![37,38].includes(state.activeMenu)) {
        this.dispatch('gisForm/changeSearchWordList', {
          keyWord,
          setSearchListToId
        })
      } else {
        this.dispatch('gisForm/changeFiltrateList', {
          keyWord,
        })
      }
    } 


  },
  setUserLineId(
    state,
    {
      data,
      jumpMap,
      ifSearch = false,
      ifNav = false,
      navItem = null,
      jumpType = false
    }
  ) {
    state.userLineId = data
    if (state.routeAreaType == 5) {
      const count = state.townId
        ? state.indicatorType
          ? null
          : state.townId == '全部'
            ? 2
            : null
        : 2
      const BoundType =
        state.zoneId == this.state.home.userInfoVo.cityId ? null : count
      this.dispatch('gisForm/setTypeHoliday', state.type || 11)
      // this.commit('gisForm/setAllBoundaryType', BoundType)
    }
    if (
      this.state.gisPage.gisObj !== null &&
      this.state.gisPage.iframeUrl == 'index' &&
      data !== null
    ) {
      if (jumpMap) {
        let typeIdList = Comstant.typeIdLists

        // 跳转到当前列表点
        if (ifSearch) {
          let checkItem =
            state.searchWordList.find(v => v.typeId == state.userLineId) ||
            state.searchWordList[0]
          let label = checkItem?.level
          let userLineId =
            checkItem?.type == 7 ? checkItem.villageId : state.userLineId

          // 搜索列表跳转
          queryLocateArea(this, state, label, userLineId)
          // 获取详情列表
          this.dispatch('gisForm/changeUserLineDetails', {
            label: checkItem?.level,
            value: userLineId,
            type: checkItem?.type
          })
        } else if (ifNav) {
          // 导航跳转
          let type = typeIdList.findIndex(v => v == navItem.label) + 1
          // 获取详情列表
          this.dispatch('gisForm/changeUserLineDetails', {
            label: navItem.label,
            value: state.userLineId,
            type
          })
          // 搜索列表跳转
          if (navItem.id != this.state.home.userInfoVo.cityId) {
            // 不等于当前市id 才跳转
            queryLocateArea(this, state, navItem.label, state.userLineId)
          }
        } else {
          const flag = jumpType && state.routeAreaType == 5
          // 筛选项列表跳转
          if (state.areaType === null && !flag) {
            // 非场景跳转
            let label =
              state.userLineDetails !== null &&
              typeIdList[state.userLineDetails.type - 1]
            queryLocateArea(this, state, label, state.userLineId)
          } else {
            // 场景跳转
            locateArea(state.userLineId, this.state.gisPage.gisObj)
          }
          if (state.routeAreaType == 5) {
            this.state.gisPage.gisObj.clearArea()
          }
        }
      } else {
        // 地图点跳转到选中id页 只针对场景 (由于有分页 不好跳转 功能暂停开发)
        // 设置 地图点击点的 详情数据获取
        this.dispatch('gisForm/changeUserLineDetails', {
          label:
            this.state.gisForm.areaType == '行政村' ? 'villageId' : 'areaId',
          value: state.userLineId,
          type: 3
        })
      }
    }
  },

  setUserAnalysisType(state, data) {
    state.userAnalysisType = data
    state.network = data
    queryBoundary(
      this,
      state.network,
      state.mnc,
      state.ptMonth,
      state.day,
      state.type,
      1
    )
    if (state.compare) {
      queryBoundary(
        this,
        state.network,
        state.rMnc,
        state.rMonth,
        state.day,
        state.type,
        2
      )
    }
  },
  setAreaType(state, data) {
    state.areaType = data
    //将页码重置为1
    state.filterPageNum=1
    //黑点、差点
    if(state.sourceType == 10 || state.sourceType == 11){
      //场景打开
      if(state.areaType || state.areaType == '行政村'){
        this.commit('gisForm/clearBadCover', null)
      }else{
        state.sourceType == 10 ?
          this.commit('gisForm/setOffset', '13-4') :
          this.commit('gisForm/setOffset', '14-4')
      }
    }
    // 省2 市3 系统1
    if (data) {
      this.state.home.userInfoVo.cityId == '150100' ? (state.areaLevel == null ? state.areaLevel = 3 : '') : ''
    } else {
      state.areaLevel = null
      if (state.activeMenu == 41) {
        this.state.gisPage.gisObj.hidePoint(1)
      }
    }
    // 绘制多边界
    queryAllBoundaryHaveClick(this, state)
    // 开启场景级
    if (data != null && data != '行政村') {
      this.commit('gisForm/setSceneSwitch', true)
    } else {
      this.commit('gisForm/setSceneSwitch', false)
    }
    // if (!data) {
    state.tableLoding = true
    this.dispatch('gisForm/changeFilterList', {})
      // }
  },
  setDeepCover(state, data) {
    this.state.gisPage.gisObj.closeBigGrid()
    state.sourceType = Number(data)
    setLegend(state.network, this.state.gisPage.gisObj)
    let cityId = this.state.home.userInfoVo.cityId
    // 绘制多边界
    if ((state.zoneId != '全部' && state.zoneId != null && state.zoneId != cityId) ||
        (state.companyId != '全部' && state.companyId != null)) {
      queryAllBoundaryHaveClick2(this, state, 1)
      if (state.compare) {
        queryAllBoundaryHaveClick2(this, state, 2)
      }
    }
    this.commit('gisForm/clearCellOther', null)
  },
  setUserType(state, data) {
    state.userType = data
    // 查询 当前筛选列表
    this.dispatch('gisForm/changeFilterList', {})
  },
  setBuildingCoverType(state, data) {
    state.buildingCoverType = data
    // 查询 当前筛选列表
    this.dispatch('gisForm/changeFilterList', {})
  },
  setOffset(state, data) {
    // // 使用覆盖率关闭搜索词列表
    // if( data !== null && state.keyWord ) this.commit('gisForm/setKeyWord', null);

    state.offset = data
    // 需要开启大栅格
    // if(state.sourceType == 10 || state.sourceType == 11 ||state.sourceType == 19){
      if(!state.areaType){
        queryBigGrid(this, state)
      }
    // }
    if(state.areaType){
      this.commit('gisForm/setAreaType',state.areaType)
    }
    //切换网络分析 需切换右侧运营商列表
    this.commit('gisForm/setRMncList')
  },
  setNewIncome(state, data) {
    // // 使用覆盖率关闭搜索词列表
    // if( data !== null && state.keyWord ) this.commit('gisForm/setKeyWord', null);

    state.newIncome = data
    if (data == 2) {
      // 需要开启大栅格
      queryBigGrid(this, state)
    } else {
      // 开启热力图
      this.commit('gisForm/setNetwork', '3+8')
    }
  },
  setType(state, data) {
    state.type = data
    // 需要开启大栅格
    // queryBigGrid(this, state)
    queryBoundary(
      this,
      state.network,
      state.mnc,
      state.ptMonth,
      state.day,
      state.type,
      1
    )

    this.dispatch('gisForm/setTypeHoliday', data)
    if (state.dateList && state.dateList.length) {
      const day = state.dateList ? state.dateList[0].day : null
      this.commit('gisForm/setDay', day)
    }
    if (data == 11 || data == 13) {
      this.commit('gisForm/setCompare', false)
    }
  },
  setSceneName(state, {data}) {
    console.log('setSceneName', data)
    state.sceneClickName = data
  },

  setOffsetSort(state, data) {
    state.offsetSort = data
    // 查询大栅格数据
    queryBigGrid(this, state)
  },

  setPerspective(state, data) {
    state.perspective = data
    let iframeUrl = data == 1 ? 'sitePositionOffset' : 'cellAzOffset'
    this.commit('gisPage/setIframeUrl', iframeUrl)
  },
  setStateType(state, data) {
    state.stateType = data
    // 查询当前 经纬度或 方位角图层
    queryLonAndAz(this, state)
  },
  setLongitudeType(state, data) {
    state.longitudeType = data
    // 查询当前 经纬度或 方位角图层
    queryLonAndAz(this, state)
  },
  setAzimuthType(state, data) {
    state.azimuthType = data
    // 查询当前 经纬度或 方位角图层
    queryLonAndAz(this, state)
  },
  setCId(state, {data, jumpMap}) {
    console.log('setCId', data)
    state.cId = data

    if (
      this.state.gisPage.gisObj !== null &&
      this.state.gisPage.iframeUrl !== 'index' &&
      data !== null
    ) {
      if (jumpMap) {
        // 跳转到当前列表点
        getLocateCell(state.perspective, data, this.state.gisPage.gisObj)
      } else {
        // 地图点跳转到选中id页 组件内监控判断
      }
    }
  },
  setLonAndAzSortType(state, data) {
    state.lonAndAzSortType = data
    // 查询当前 经纬度或 方位角图层
    queryLonAndAz(this, state)
  },

  setMapId(state, data) {
    state.mapId = data
    // 设置完需要 gis跟换图层;
    if (this.state.gisPage.gisObj !== null)
      changeMap(data, this.state.gisPage.gisObj)
  },
  setCompare(state, data) {
    console.log('setCompare', data)
    state.compare = data
    // 如果 对比为true 需要 把左侧月份值赋给右侧
    if (data) this.commit('gisForm/setRMonth', state.ptMonth)
    // 设置完 gis开启对比或关闭
    if (
      this.state.gisPage.gisObj !== null &&
      this.state.gisPage.iframeUrl === 'index'
    )
      compareFn(data, this.state.gisPage.gisObj)
  },
  setRMnc(state, data) {
    state.rMnc = data
    //对比打开
    if (state.compare) {
      this.dispatch('gisForm/changeCompareDetail', {})
    }
    // 查询当前 网格&热力 图层
    if (state.compare) {
      let pre = state.network.split('+')[0]
      if (pre == '13' || pre == '14' || pre == '19') {
        function getData(data) {
          //this.commit('gisForm/setOffsetCoverageList', data)
        }

        let formDataC = state.companyId ? {
          cityId: this.state.home.userInfoVo.cityId,
          ptMonth: state.rMonth,
          network: state.network,
          offset: state.offset,
          offsetSort: state.offsetSort,
          newIncome: state.newIncome,
          mnc: state.mnc,
          status: 2,
          zoneId: null,
          townId: null,
          companyId: state.companyId,
          networkGridId: state.networkGridId == '全部' ? null : state.networkGridId,

        } : {
          cityId: this.state.home.userInfoVo.cityId,
          ptMonth: state.rMonth,
          network: state.network,
          offset: state.offset,
          offsetSort: state.offsetSort,
          newIncome: state.newIncome,
          mnc: state.mnc,
          status: 2,
          zoneId: state.zoneId,
          townId: state.townId == '全部' ? null : state.townId,

        }
        let gisObj = this.state.gisPage.gisObj
        //bigGridContrast(formDataC, gisObj, getData)
      }
      if (pre == '15') {
        //深度覆盖
        /* this.clearCellINNet(item.value)
         this.clearCellOther(item.value)*/
        let sourceType = '12'
        if (state.network.split('+')[1] == '5') {
          sourceType = '13'
        }
        this.commit('gisForm/setDeepCover', sourceType)
      }
    }
    if (state.compare)
      queryBoundary(
        this,
        state.network,
        state.rMnc,
        state.rMonth,
        state.day,
        state.type,
        2
      )
  },
  setRMonth(state, data) {
    state.rMonth = data
    //对比打开
    if (state.compare) {
      this.dispatch('gisForm/changeCompareDetail', {})
    }
    // 查询当前 网格&热力 图层
    console.log('setRMonth', data)
    if (state.compare) {
      if(!state.network){
        return
      }
      let pre = state.network.split('+')[0]
      if (pre == '13' || pre == '14' || pre == '19') {
        function getData(data) {
        }

        if (state.sceneSwitch) {
         this.commit('gisForm/setAreaType', state.areaType)
        } else {
          let formDataC = state.companyId ? {
            cityId: this.state.home.userInfoVo.cityId,
            ptMonth: state.ptMonth,
            network: state.network,
            offset: state.offset,
            offsetSort: state.offsetSort,
            newIncome: state.newIncome,
            mnc: state.mnc,
            status: 2,
            zoneId: null,
            townId: null,
            companyId: state.companyId,
            networkGridId: state.networkGridId == '全部' ? null : state.networkGridId,

          } : {
            cityId: this.state.home.userInfoVo.cityId,
            ptMonth: state.ptMonth,
            network: state.network,
            offset: state.offset,
            offsetSort: state.offsetSort,
            newIncome: state.newIncome,
            mnc: state.mnc,
            status: 2,
            zoneId: state.zoneId,
            townId: state.townId == '全部' ? null : state.townId,

          }
          let gisObj = this.state.gisPage.gisObj
          bigGridContrast(formDataC, gisObj, getData)
        }
      }
      if (pre == '15') {
        //深度覆盖
        /* this.clearCellINNet(item.value)
         this.clearCellOther(item.value)*/
        let sourceType = '12'
        if (state.network.split('+')[1] == '5') {
          sourceType = '13'
        }
        this.commit('gisForm/setDeepCover', sourceType)
      }
    }
    if (state.compare)
      queryBoundary(
        this,
        state.network,
        state.rMnc,
        state.rMonth,
        state.day,
        state.type,
        2
      )
      if(state.usedNetwork && state.usedNetwork.split('+')[0] == '12'){
        queryBoundary(
          this,
          state.usedNetwork,
          state.rMnc,
          state.rMonth,
          state.day,
          state.type,
          2
        )
      }
  },

  setAllBoundaryType(state, data) {
    // if (data == null) {
    //   setLegend(null, this.state.gisPage.gisObj)
          // this.state.gisForm.isActiveShow
    // }
    if (data && data.data) {
      state.allBoundaryType = data.data
    } else {
      state.allBoundaryType = data
    }
    this.dispatch('gisForm/setBoundaryData',data)

  },
  // 选择边界填充色
  setBoundaryFlag(state, data) {
    state.boundaryFlag = data
    sessionStorage.setItem('newBoundaryFlag', data)
    if (data && this.state.gisPage.gisObj) {
      setTimeout(() => {
        this.state.gisPage.gisObj.setLegendType(8)
      }, 200);
    } else {
      this.state.gisPage.gisObj.setLegendType()
    }
    if (this.state.gisPage.gisObj)
      getAllBoundaryNoClick(
        this.state.gisForm.ptMonth,
        this.state.home.userInfoVo.cityId,
        this.state.gisForm.allBoundaryType,
        this.state.gisPage.gisObj,
        data
      ).then(src => {
        state.informationType = src
        data.loading.close()
      })
  },
  setNetworkShow(state, data) {
    state.networkShow = data
    console.log('state.networkShow', data);
    if (data == false) {
      state.oldNetwork = state.network == null ? state.oldNetwork : state.network
      this.commit('gisForm/setNetwork', null)
    } else {
      this.commit('gisForm/setNetwork', state.oldNetwork)
    }
  },
  setCommunityList(state, data) {
    console.log('setCommunityList', data)
    // 不重复的数组
    let noRepeatList = getNoRepeatList(state.communityList, data)
    // 只存在一个一个选的情况下的 判断 （全选全不选需重新设计）
    if (data.length > state.communityList.length) {
      // 增加一个
      queryCommunity(noRepeatList, this)
    } else {
      // 减少一个
      let delStatus = noRepeatList[0].split('+')[1] == 4 ? 1 : 2
      delGisCommunity(delStatus, this.state.gisPage.gisObj)
    }

    state.communityList = data
  },
  setCustomList(state, data) {
    console.log('setCustomList', data)
    // 不重复的数组
    let noRepeatList = getNoRepeatList(state.customList, data)
    // 只存在一个一个选的情况下的 判断 （全选全不选需重新设计）
    if (data.length > state.communityList.length) {
      // 增加一个
      queryCustom(noRepeatList, 'show', this)
    } else {
      // 减少一个
      queryCustom(noRepeatList, 'hide', this)
      // delGisCustom(0, this.state.gisPage.gisObj)
    }
    state.customList = data
  },

  setMncList(state, data) {
    // console.log( state,  data, this );
    const mnc = state.mnc
    const y = {label: '移动', value: '0'},
      l = {label: '联通', value: '1'},
      d = {label: '电信', value: '11'}
    let arr = []
    // if (mnc == y.value) {
    //   arr = [l, d, y]
    // } else if (mnc == l.value) {
    //   arr = [d, y, l]
    // } else {
    //   arr = [y, l, d]
    // }
    arr = [y, l, d]

    state.mncList = arr
  },
  setRMncList(state, data) {
    // console.log( state,  data, this );
    // alert('设置右侧运营商列表')
    const mnc = state.mnc
    const y = {label: '移动', value: '0'},
      l = {label: '联通', value: '1'},
      d = {label: '电信', value: '11'}
    let arr = []

    if (state.offset == null || state.offset == '13-4' || state.offset == '14-4' || state.offset == '19-4') {
      if (mnc == y.value) {
        arr = [l, d, y]
      } else if (mnc == l.value) {
        arr = [d, y, l]
      } else {
        arr = [y, l, d]
      }
    } else {
      console.log("state.offsetList", state.offsetList, state.offset)
      let offsetItem = state.offsetList.find(v => v.value == state.offset)

      if (offsetItem.label.length == 4) {
        let mncStr = offsetItem.label.substring(2, 4)
        if (mncStr == y.label) {
          arr = [y]
        } else if (mncStr == l.label) {
          arr = [l]
        } else {
          arr = [d]
        }
      } else {
        let mncStr1 = offsetItem.label.substring(2, 4)
        let mncStr2 = offsetItem.label.substring(5)

        if (mncStr1 !== y.label && mncStr2 !== y.label) {
          arr = [l, d]
        } else if (mncStr1 !== l.label && mncStr2 !== l.label) {
          arr = [y, d]
        } else {
          arr = [y, l]
        }
      }
    }

    // 列表改变需切换右侧运营商为数组第一项,除非offset为空的情况下
    if (state.offset !== null) this.commit('gisForm/setRMnc', arr[0].value)

    state.rMncList = arr
  },

  setMonthList(state, data) {
    let num = 6 // 展示最近的月份
    let d = new Date()
    let y = d.getFullYear()
    let m = d.getMonth()
    let arr = [],
      arr1 = [],
      arr2 = []
    for (let i = 1; i <= m; i++) {
      let str = y + '.' + i
      let v = y + '' + i
      if (i < 10) {
        str = y + '.0' + i
        v = y + '0' + i
      }
      arr1.push({label: str, value: Number(v)})
    }
    if (arr1.length < num) {
      for (let i = 12; i > 12 - (num - arr1.length); i--) {
        let str = y - 1 + '.' + i
        let v = y - 1 + '' + i
        if (i < 10) {
          str = y - 1 + '.0' + i
          v = y - 1 + '0' + i
        }
        arr2.push({label: str, value: Number(v)})
      }
    }
    arr1 = arr1.reverse()
    arr = arr1.concat(arr2)

    // 如果月份值为null 不存在的情况 会给默认值
    if (!state.ptMonth) {
      if (d.getDate() <= 7) {
        // this.commit('gisForm/setPtMonth', arr[1]['value'])
        this.commit('gisForm/setState', {name:'ptMonth',data:arr[1]['value']})
      } else {
        this.commit('gisForm/setState', {name:'ptMonth',data:arr[0]['value']})
      }
    }
    state.monthList = arr
  },

  setZoneList(state, data) {
    state.zoneList = data
  },
  setIndicator(state, data) {
    state.indicatorType = data
  },
  setGoBackType(state, data) {
    state.goBackType = data
  },
  setDay(state, data) {
    state.day = data
    // 播放热力图
    this.commit('gisForm/setNetwork', '3+')
  },
  setDayListDate(state, data) {
    state.dateList = data
  },
  setHolidayType(state, data) {
    state.type = data
  },
  setTownList(state, data) {
    state.townList = data
  },
  setVillageList(state, data) {
    state.villageList = data
  },
  setCompanyList(state, data) {
    state.companyList = data
  },
  setNetworkGridList(state, data) {
    state.networkGridList = data
  },
  setMicroGridList(state, data) {
    state.microGridList = data
  },
  setAreaTypeList(state, data) {
    state.areaTypeList = data
  },
  setOffsetList(state, data) {
    // 当前的是c
    let mnc = state.mnc
    let m = Comstant.mncFunction(mnc)
    let obj0 = {label: null, value: null}
    const obj1 = {label: '弱于' + m.a, value: m.c1 + '-' + m.a1}
    const obj2 = {label: '弱于' + m.b, value: m.c1 + '-' + m.b1}
    const obj3 = {label: '弱于' + m.a + '&' + m.b, value: mnc}
    // const obj4={label:'弱于'+c,value:c1+'-'+c1}
    const arr = [obj0, obj1, obj2, obj3]
    state.offsetList = arr
  },

  setLonList(state, data) {
    // console.log( 'setLonList', data );
    state.lonList = data
  },
  setAzList(state, data) {
    // console.log( 'setAzList', data );
    state.azList = data
  },

  setOffsetCoverageList(state, data) {
    // console.log( 'setOffsetCoverageList', data );
    state.offsetCoverageList = data
  },
  setSearchWordList(state, {data, setSearchListToId}) {
    console.log('setSearchWordList', data, setSearchListToId)
    state.searchWordList = data
    // 设置从报告页面跳转过来的id
    if (setSearchListToId)
      this.commit('gisForm/setUserLineId', {
        data: setSearchListToId,
        jumpMap: true,
        ifSearch: true
      })
  },
  setInUseAttrList(state, data) {
    // console.log( 'setInUseAttrList', data );
    state.inUseAttrList = data
  },

  setFilterList(state, {data, ifNav = false}) {
    // console.log( 'setFilterList', data );
    // clearTimeout(state.timeout);
    // state.timeout = setTimeout(() => {
      state.filterList = data
    // }, 300);


    // 当前筛选属性表发生setFilterTimept变化 需清空选中id -t
    // if (state.userLineId && !ifNav) {
    //   this.commit('gisForm/setUserLineId', { data: null, jumpMap: false })
    // }
  },
  setFilterPageNum(state, data) {
    // console.log( 'setFilterPageNum', data );
    state.filterPageNum = data
    // 查询 当前筛选列表
    if ([37, 38].includes(state.activeMenu)) {
    this.dispatch('gisForm/changeFiltrateList',{...state.oldFiltrate})
    } else {
      this.dispatch('gisForm/changeFilterList', {})
    }
  },
  setFilterTime(state, data) {
    state.dayType = data
    this.dispatch('gisForm/changeFilterList', {})
  },
  setFilterPageSize(state, data) {
    // console.log( 'setFilterPageSize', data );
    state.filterPageSize = data
    // 查询 当前筛选列表
    this.dispatch('gisForm/changeFilterList', {})
  },
  setFilterTotal(state, data) {
    // console.log( 'setFilterTotal', data );
    state.filterTotal = data
  },
  // 数据时间
  setFilterPtDay(state, data) {
    // console.log( 'setFilterTotal', data );
    state.filterPtday = data
  },
  setFilterSort(state, data) {
    // console.log( 'setFilterSort', data );
    state.filterSort = data
    // 查询 当前筛选列表
    this.dispatch('gisForm/changeFilterList', {})
  },
  setDataSelectList(state, data) {
    console.log('setDataSelectList', data)
    state.dataSelectList = data

    // 查询 当前筛选列表
    this.dispatch('gisForm/changeFilterList', {})
  },

  setUserLineDetails(state, data) {
    // state.panelType = '1'
    // state.panelCellType = 0
    state.userLineDetails = data

    if(data == null){
      state.userLineType = null
    }else if (data.type == 3 || data.type == 7) {
      state.userLineType = 37
    } else if (data.type == null) {
      let val = this.state.gisForm.sourceType
      //需要排除的sourceType否则图表展示会有问题
      if (val != 10 && val != 11 && val != 12 && val != 13&&val!=41) {
        state.userLineType = '广告点位'
        state.panelType = '8'
        state.panelCellType = '4'
      }
    } else {
      state.userLineType = null
    }
    if (data) {
      this.dispatch('gisForm/changeEchartsData')
    }
  },
  setIndicatorType(state, data) {
    this.dispatch('gisForm/setIndicatorData', data)
  },
  setBackType(state, data) {
    this.dispatch('gisForm/setGoBackTypeData', data)
  },
  setDayDis(state, data) {
    this.dispatch('gisForm/setDayData', data)
  },
  setDayList(state, data) {
    this.dispatch('gisForm/setDateList', data)
  },
  setModalFlag(state, data) {
    this.dispatch('gisForm/setVisibleFlag', data)
  },
  setPanelType(state, data) {
    console.log('setPanelType', data, state)
    let oldData = state.panelType
    state.panelType = data
    // 当选中id 不为空且 panelType 不是同类型的 值 则调用 获取echarts数据
    if (state.userLineId != null && parseInt(oldData) != parseInt(data)) {
      this.dispatch('gisForm/changeEchartsData')
    } else if (state.userLineId != null && state.userType > 3) {
      this.dispatch('gisForm/changeEchartsData')
    } else if (state.routeAreaType == 5 || state.routeAreaType == 8) {
      this.dispatch('gisForm/changeEchartsData')
      console.log('setPanelCellType changeEchartsData');
    } else if (state.sourceType == 31) {
      this.dispatch('gisForm/changeEchartsData')
    }
  },
  setPanelCellType(state, {data, changeEchartsData = true}) {
    console.log('setPanelCellType', data)
    state.panelCellType = data
    if (state.userLineId != null && changeEchartsData) {
      this.dispatch('gisForm/changeEchartsData')
    } else if (state.routeAreaType == 5 || state.routeAreaType == 8) {
      this.dispatch('gisForm/changeEchartsData')
    }
  },
  setResultType(state, data) {
    let oldData = state.resultType
    state.resultType = data
    // 当选中id 不为空且 resultType 不是同类型的 值 则调用 获取echarts数据
    if (state.userLineId != null && parseInt(oldData) != parseInt(data)) {
      this.dispatch('gisForm/changeEchartsData')
    } else if (state.userLineId != null && state.userType > 3) {
      this.dispatch('gisForm/changeEchartsData')
    } else if (state.routeAreaType == 5 || state.routeAreaType == 8) {
      this.dispatch('gisForm/changeEchartsData')
    }
  },
  // 详情图表数据
  setEchartsData(state, data) {
    state.echartsData = data
  },
  // 详情数量数据
  setEchartsNumData(state, data) {
    state.echartsNumData = data
  },
  // 圈画
  getSceneDrawEnd(state, data) {
    state.scene_draw_data = data
  },
  // 当前选中的圈画id
  setCurrentDrawId(state, data) {
    state.current_draw_id = data
  },
  setDraw_table_arr(state, data) {
    state.draw_table_arr = data ? JSON.parse(JSON.stringify(data)) : data
  },
  setDraw_table_Loading(state, data) {
    state.draw_table_loading = data
  },
  //圈画区域数组
  set_draw_roundArr(state, data) {
    state.draw_round_arr = data
    localStorage.setItem(
      'draw_round_arr',
      JSON.stringify(data.filter(item => item.icon == 'el-icon-check'))
    )
  },

  //圈画场景都被清除后重新加载多个场景
  setAdd_scenesAll(state, data) {
    let arr =
      data && data.length
        ? data
        : JSON.parse(JSON.stringify(state.draw_round_arr))
    if (arr && arr.length) {
      arr = arr.map(item => {
        return {
          id: item.value,
          boundary: item.boundary,
          name: ''
        }
      })
      this.state.gisPage.gisObj.addScenes(arr)
    }
  },

  // 在点击圈画区域，查看相应信息时，点击右侧场景列表，地图切至相应场景位置，展示数据为该场景指标；关闭圈画区域的信息展示内容
  clear_scene_all(state, data) {
    this.commit('gisForm/setCurrentDrawId', null)
    state.draw_round_arr.forEach(item => {
      this.state.gisPage.gisObj.removeScene(item.value)
    })
    this.commit('gisForm/setCurrentDrawId', null)
  },
  setUserAllForm(state, data) {
    state.userAllForm = data
  }
}

const actions = {
  // 边界
  async setBoundaryData({commit, state}, bigData){
    let gisObj = this.state.gisPage.gisObj
    gisObj.clearAreaGrid()
    if (state.allBoundaryType == null) {
      return
    } else {
      let obj = {
        cityId: state.cityId,
        type: state.allBoundaryType,
        ptMonth: state.ptMonth,
        boundaryFlag: state.boundaryFlag,
        pageSize: 200,
      }
      if (state.allBoundaryType == 8) {
        const { data:pageNum } = await getAllBoundaryPageTotal(obj)
        pageNum ? '' : bigData.loading.close()
        for (let i = 0; i < pageNum; i++) {
          const { data } = await getAllBoundaryByPageNum({...obj,pageNum:i+1})
          i == 0 ? bigData.loading.close() : ''
          if (data) gisObj.setAreaGrid({data,total:pageNum,pageNum:i+1})
        }
      } else {
        const { data } = await getAllBoundary(obj)
        bigData.loading.close()
        if (state.routeAreaType == 5) {
          if (data) {
            //  有点击效果
            gisObj.setArea(data)
            gisObj.unSelectArea()
            gisObj.clearBoundary()
          } else {
            gisObj.clearArea()
          }
        } else {
          // 无点击效果
          if (data) {
            gisObj.setAreaGrid(data)
          } else {
            commit('setState',{name:'informationType',data:state.allBoundaryType})
          }
        }
      }
    }
  },

  // 修改图列数据
  setIndicatorData({commit, state}, data) {
    commit('setIndicator', data)
  },
  setGoBackTypeData({commit, state}, data) {
    commit('setGoBackType', data)
  },
  // 修改日期数据
  setDayData({commit, state}, data) {
    commit('setDay', data)
  },
  // 修改地图数据
  setDateList({commit, state}, data) {
    commit('setDayListDate', data)
  },
  // 更改显示隐藏状态
  setVisibleFlag({commit, state}, data) {
    commit('setModalVisible', data)
  },
  // 修改星期状态
  setTypeHoliday({commit, state}, data) {
    commit('setHolidayType', data)
  },
  // 查询省级
  changeZoneList({commit}, data) {
    let distinctId = this.state.home.userInfoVo.distinctId
    let authorities = this.state.home.userInfoVo.authorities
    api
      .getZone({
        cityId: this.state.home.userInfoVo.cityId
      })
      .then(res => {
        let list = []
        if (
          authorities.indexOf('zone-sort') !== -1 &&
          authorities.indexOf('net-grid-sort') !== -1
        ) {
          list.push({name: '不限', id: null})
        }
        let distinctIdList = distinctId ? distinctId.split(',') : []
        if (res.data.length !== 0) {
          // 根据权限显示相应列表
          if (distinctIdList.length == 0) {
            list = list.concat(res.data)
          } else {
            list.push({name: '全部', id: this.state.home.userInfoVo.cityId})
            res.data.forEach(item => {
              if (distinctIdList.includes(item.id)) {
                list.push(item)
              }
            })
          }
        }
        commit('setZoneList', list)
      })
  },
  // 查询城市
  changeTownList({commit}, zoneId) {
    let params = {
      zoneId,
      cityId: this.state.home.userInfoVo.cityId
    }
    api.getTownByZoneId(params).then(res => {
      if (res.code === 1) {
        let townList = [{name: '全部', id: '全部'}]
        if (res.data.length !== 0) {
          townList = townList.concat(res.data)
        }
        commit('setTownList', townList)
      }
    })
  },
  // 查询自然村
  changeVillageList({commit}, data) {
    let params = {
      zoneId: state.zoneId,
      townId: data,
      cityId: this.state.home.userInfoVo.cityId
    }
    api.getVillageByTownId(params).then(res => {
      if (res.code === 1) {
        let villageList = [{name: '全部', id: '全部'}]
        if (res.data.length !== 0) {
          villageList = villageList.concat(res.data)
        }
        commit('setVillageList', villageList)
      }
    })
  },
  // 查询子公司
  changeCompanyList({commit}, companyId) {
    let distinctId = this.state.home.userInfoVo.distinctId
    let authorities = this.state.home.userInfoVo.authorities
    api
      .getNetworkGridByCity({
        // companyName:this.state.companyName,
        cityId: this.state.home.userInfoVo.cityId
      })
      .then(res => {
        let list = []
        if (
          authorities.indexOf('zone-sort') !== -1 &&
          authorities.indexOf('net-grid-sort') !== -1
        ) {
          list.push({name: '不限', id: null})
        }
        let distinctIdList = distinctId ? distinctId.split(',') : []
        if (res.data.length !== 0) {
          // 根据权限显示相应列表
          // if (distinctIdList.length == 0) {
          //   list = list.concat(res.data)
          // } else {
          //   list.push({ name: '全部', id: this.state.home.userInfoVo.cityId })
          //   res.data.forEach(item => {
          //     if (distinctIdList.includes(item.id)) {
          //       list.push(item)
          //     }
          //   })
          // }
          list = list.concat(res.data)
        }
        commit('setCompanyList', list)
      })
  },
  // 查询场景网格
  changeNetworkGridList({commit}, data) {
    api
      .getNetworkGridByCity({
        companyId: data,
        cityId: this.state.home.userInfoVo.cityId
      })
      .then(res => {
        if (res.code === 1) {
          let list = [{name: '全部', id: '全部'}]
          if (res.data.length !== 0) {
            list = list.concat(res.data)
          }
          commit('setNetworkGridList', list)
        }
      })
  },
  // 查询微格
  changeMicroGridList({commit}, networkGridId) {
    let params = {
      networkGridId,
      cityId: this.state.home.userInfoVo.cityId
    }
    api.getMicroByCity(params).then(res => {
      if (res.code === 1) {
        let microGridList = [{name: '全部', id: '全部'}]
        if (res.data.length !== 0) {
          microGridList = microGridList.concat(res.data)
        }
        commit('setMicroGridList', microGridList)
      }
    })
  },
  // 修改场景类型
  changeAreaTypeList({commit}, data) {
    let params = {
      cityId: this.state.home.userInfoVo.cityId,
      mnc: this.state.home.userInfoVo.mnc
    }
    api.getAreaType(params).then(res => {
      let areaArr = []
      if (res.code == 1) {
        areaArr = res.data
        // areaArr.unshift(null)
      } else {
        areaArr.unshift(null)
      }
      commit('setAreaTypeList', areaArr)
    })
  },
  // 更换搜索类型，
  async changeSearchWordList({commit}, {keyWord, setSearchListToId}) {
    console.log('keyWord', keyWord)
    if (keyWord == '' || keyWord == null) {
      return
    }
    let typeNameList = ['区县', '街道', '场景', '网格', '微格', '行政村']
    // let typeIdList = Comstant.typeIdLists
    let searchForType = localStorage.getItem('keyWordType')
    let rout = sessionStorage.getItem('rout')
    let params = {
      keyWord,
      cityId: this.state.home.userInfoVo.cityId,
      searchForType,
      areaType: this.state.gisForm.areaType
    }
    if (rout == 'false') {
      params.reportTobox = false
    } else {
      params.reportTobox = true
    }
    await api.getSearchList(params).then(res => {
      if (res.code === 1) {
        let searchList = []

        res.data.map(item => {
          searchList.push({
            ...item,
            typeName: typeNameList[item.type - 1],
            typeId: item[item.level]
          })
        })

        commit('setSearchWordList', {data: searchList, setSearchListToId})
      }
    })
    console.log(this.state.gisForm.userLineId, 'this.state.gisForm.userLineId');
  },

  changeUserLineDetails({commit}, {label, value, type}) {
    let params = {
      ptMonth: this.state.gisForm.ptMonth,
      pageNum: 1,
      pageSize: 20,
      companyId: null,
      type: this.state.gisForm.areaType == '行政村' ? 7 : type,
      cityId: this.state.home.userInfoVo.cityId,
      [type == 7 ? 'villageId' : label]: value,
      areaType: this.state.gisForm.areaType,
      sourceType: this.state.gisForm.sourceType
      // [areaType == '行政村'? villageId:label]: value,
    }
    /* if (sourceType == '8' || sourceType == '9') {
       commit('showCellRange', {data: value})
       return
     }*/
    if (state.userType > 5) {
      let cityId = this.state.home.userInfoVo.cityId
      // let zoneId = this.state.home.userInfoVo.zoneId
      // let townId = this.state.home.userInfoVo.townId
      let {
        filterSort,
        filterPageNum,
        filterPageSize,
        dataSelectList,
        allAttrList,
        dayType,
        zoneId,
        townId,
        villageId,
        mnc
      } = this.state.gisForm
      // 查找当前列 所有的筛选项
      let copyList = JSON.parse(JSON.stringify(dataSelectList))
      let currentList = []
      allAttrList
        .filter(x => {
          if (
            !(this.state.home.userInfoVo.superAccount == 2 && x == 'numAll')
          ) {
            return x
          }
        })
        .forEach(item => {
          let selectItem = copyList.find(v => v.selectName == item)
          if (selectItem) {
            if (
              selectItem.conditionalValueList[1].value == null ||
              selectItem.conditionalValueList[1].condition == null
            ) {
              selectItem.conditionalValueList = [
                selectItem.conditionalValueList[0]
              ]
            }
            currentList.push(selectItem)
          }
        })
      let baseParams = {
        ptMonth: this.state.gisForm.ptMonth,
        pageNum: 1,
        pageSize: 10,
        cityId,
        zoneId,
        townId,
        villageId,
        sortType: filterSort.sortType,
        sortStr: filterSort.sortStr,
        dataSelectList: currentList,
        dayType,
        keyWord: state.keyWord,
        mnc
      }
      if (state.userType == 6) {
        getAreaWarning(baseParams).then(res => {
          if (res.code == 1) {
            if (res.data == null || res.data.list.length == 0) {
              Message.warning('查询场景数据异常，该地区数据待更正')
              commit('setUserLineDetails', null)
            } else {
              commit('setFilterTotal', res.data.total)
              if (res.data.lastPage < filterPageNum) {
                commit('setFilterPageNum', res.data.lastPage)
              } else if (res.data && res.data.list) {
                commit('setFilterList', {data: res.data.list})
                commit('setUserLineDetails', null)
              }
            }
          }
        })
      } else if (state.userType == 7) {
        areaWarningData(baseParams).then(res => {
          if (res.code == 1) {
            if (res.data == null || res.data.list.length == 0) {
              Message.warning('查询场景数据异常，该地区数据待更正')
              commit('setUserLineDetails', null)
            } else {
              commit('setFilterTotal', res.data.total)
              if (res.data.lastPage < filterPageNum) {
                commit('setFilterPageNum', res.data.lastPage)
              } else if (res.data && res.data.list) {
                commit('setFilterList', {data: res.data.list})
                commit('setUserLineDetails', null)
              }
            }
          }
        })
      }
    } else {
      getAttributeRespnse(params).then(res => {
        if (res.code === 1) {
          if (res.data == null || res.data.list == null || res.data.list.length == 0) {
            // Message.warning('暂无详情数据')
            commit('setUserLineDetails', null)
          } else {
            commit('setUserLineDetails', res.data.list[0])
            // commit('setFilterList', { data: res.data.list })
          }
        } else {
          // Message.warning('暂无详情数据')
          commit('setUserLineDetails', null)
        }
      })
    }
  },

  changeEchartsData({commit, state}, data) {
    if (!state.userLineDetails) return
    let value = state.userLineDetails.id //当前项的id
    commit('setScoreId', value)
    let typeIdList = Comstant.typeIdLists
    let label = typeIdList[this.state.gisForm.userLineDetails.type - 1] //根据type判断

    let type = parseInt(this.state.gisForm.panelType) //判断tab 1 用户 2网络 。。。
    let panelCellType = this.state.gisForm.panelCellType //详情 图表 的第二行选项: 0全部, 4 4g， 5 5g
    let resultType = this.state.gisForm.resultType
    let sourceType = this.state.gisForm.sourceType
    let ptMonth = this.state.gisForm.ptMonth
    let network = this.state.gisForm.network

    let cellType =
      type == 1 && panelCellType != '0'
        ? '0'
        : (type == 2 && panelCellType == '0') ||
        (type == 7 && panelCellType == '0')
          ? (sourceType == '7' ? '5' : '4')
          : panelCellType
    // 如果值 不同需要提示 更换值 且不可继续调用这个接口
    if (cellType != panelCellType && sourceType != '7' && sourceType == '6')
      commit('setPanelCellType', {data: cellType, changeEchartsData: false})

    let params =
      state.areaType == '广告点位'
        ? {
          cellType: sourceType === 2 ? '0' : cellType,
          type,
          resultType,
          sourceType,
          cityId: this.state.home.userInfoVo.cityId,
          areaId: value
        }
        : {
          cellType: sourceType === 2 ? '0' : cellType,
          type,
          resultType,
          sourceType,
          cityId: this.state.home.userInfoVo.cityId,
          [label == undefined ? 'villageId' : label]: value
          // [label == undefined ? 'villageId' : label]: typeof label === 'undefined' ? state.searchWordList[0].villageId:  value
          // [typeof label === 'undefined' ? 'villageId' : label]:typeof label === 'undefined' ? state.searchWordList[0].villageId:  value
        }

    let numParams = {
      sourceType,
      ptMonth,
      cityId: this.state.home.userInfoVo.cityId,
      [label == undefined ? 'villageId' : label]: value
    }

    if (state.userType == 6 || state.userType == 7) {
      let areaParams = {
        cellType,
        type,
        cityId: this.state.home.userInfoVo.cityId,
        areaId: value
      }
      areaWarningThrend(areaParams).then(res => {
        if (res.code === 1) {
          commit('setEchartsData', res.data)
        }
      })
      // commit('setEchartsData', [state.userLineDetails])
    } else {
      // 集团预警 趋势图
      if (state.userType == 4 && state.panelType == '1b') {
        params.type = 5
      }
      // 漫入预警 趋势图
      if (state.userType == 5 && state.panelType == '1b') {
        params.type = 6
      }
      if (state.areaType == '广告点位') {
        params.type = 8
      }
      if (params.type == 7) {
        params.ptMonth = this.state.gisForm.ptMonth
      }
      //cell
      if (sourceType == '8' || sourceType == '9') {
        params.cellId = value
        numParams.cellId = value
        let sourceType = this.state.gisForm.sourceType
        if (sourceType == '8' || sourceType == '9') {
          if(sourceType == '8'){
            locateCell(1, value, this.state.gisPage.gisObj)
          }
          if(sourceType == '9'){
            locateCell(2, value, this.state.gisPage.gisObj)
          }
          this.commit('gisForm/showCellRange', {data: value,status: 1})
          return
        }
      }
      //黑点、差点
      if (sourceType == '10' || sourceType == '11' || sourceType == '19') {
        if (this.state.gisForm.areaType) {
          if(this.state.gisForm.areaType == '行政村'){
            params.villageId = value
            numParams.villageId = value
            locateAreaBB(value, this.state.gisPage.gisObj)
          }else{
            params.areaId = value
            numParams.areaId = value
            locateAreaBB(value, this.state.gisPage.gisObj)
          }
        } else {
          params.gridId = value
          numParams.gridId = value
          zoomToBigGrid(value, this.state.gisPage.gisObj)
        }
      }
      console.log("sourceType-12");
      //深度覆盖
      if (sourceType == '12' || sourceType == '13') {
        params.buildId = value
        numParams.buildId = value
        if(this.state.gisForm.userLineDetails.lon != undefined && this.state.gisForm.userLineDetails.lat != undefined ){
          locateAreaCoor(value,this.state.gisForm.userLineDetails.lon,this.state.gisForm.userLineDetails.lat,this.state.gisPage.gisObj)
        }
      }
      if (sourceType == '8' || sourceType == '9' || sourceType == '12' || sourceType == '13') {
        params.villageId = null
        numParams.villageId = null
        //params.networkGridId = this.state.gisForm.networkGridId
      }
      if (sourceType == '10' || sourceType == '11' || sourceType == '19') {
        if (this.state.gisForm.areaType != '行政村') {
          params.villageId = null
          numParams.villageId = null
        }
      }
      if (sourceType == '19') {
        numParams.scoreType = this.state.gisForm.scoreType || 1
        numParams.mnc = this.state.gisForm.mnc
      }
      if (sourceType == '10' || sourceType == '11') {
        params.cellType = network ? (network.split('+')[1] == 5 ? 5 : 4) : 4
      }
      //判断是否是着火点
      if(sourceType==41){
        const {panelType}=state
        //非场景
        if(panelType==41||panelType==42){
          numParams.type=panelType==41?1:2
        }else if(panelType==43){
          numParams={
            ...numParams,
            sourceType:43,
            villageId:undefined,
            areaId:state.offsetCId
          }
          params={
            ...params,
            sourceType:43,
            villageId:undefined,
            areaId:state.offsetCId,
            type:28
          }
        }
      }
      if (this.state.gisForm.sourceType != 1 && this.state.gisForm.sourceType != 5 ) {
          numParams.cellType = network ? (network.split('+')[1] == 5 ? 5 : 4) : 4
      }
      if (this.state.gisForm.sourceType == 31) {
        params.cellType = null
        if (state.panelType == '1c') {
          params.resultType = 12
        }
        numParams.cellType = null
      }
      if (this.state.gisForm.sourceType == 31 || this.state.gisForm.sourceType == 24 ||this.state.gisForm.sourceType == 25 ||
        this.state.gisForm.sourceType == 26 ||this.state.gisForm.sourceType == 27 ) {
        params.cellType = null
        numParams.cellType = null
      }
      // if (this.state.gisForm.sourceType == 24) {
      //   params.cellType = null
      //   numParams.cellType = null
      // }
      console.log(value,'value');
      if (sourceType == 22 || sourceType == 23) return
      // 详情图表接口
      getDataAnalysis(params).then(res => {
        if (res.code === 1) {
          commit('setEchartsData', res.data)
        }
      })
      // 详情数量接口
      getUserAnalysisDetailData(numParams).then(res => {
        if (res.code === 1) {
          commit('setEchartsNumData', res.data)
        }
      })
    }
  },

  changeDetailsData({commit, state}, data) {
    let value = state.userLineId //当前项的id
    let type = parseInt(this.state.gisForm.panelType) //判断tab 1 用户 2网络 。。。
    let panelCellType = this.state.gisForm.panelCellType //详情 图表 的第二行选项: 0全部, 4 4g， 5 5g
    // let resultType = this.state.gisForm.resultType
    let sourceType = this.state.gisForm.sourceType
    let ptMonth = this.state.gisForm.ptMonth

    let cellType =
      type == 1 && panelCellType != '0'
        ? '0'
        : (type == 2 && panelCellType == '0') ||
        (type == 7 && panelCellType == '0')
          ? (sourceType == '7' ? '5' : '4')
          : panelCellType
    // 如果值 不同需要提示 更换值 且不可继续调用这个接口
    if (cellType != panelCellType && sourceType != '7' && sourceType == '6')
      commit('setPanelCellType', {data: cellType, changeEchartsData: false})

    // let params = {
    //       cellType,
    //       type,
    //       resultType,
    //       sourceType,
    //       cityId: this.state.home.userInfoVo.cityId,
          // [label == undefined ? 'villageId' : label]: typeof label === 'undefined' ? state.searchWordList[0].villageId:  value
        // }

    let numParams = {
      sourceType,
      ptMonth,
      cityId: this.state.home.userInfoVo.cityId,
    }
      //站址
        if (this.state.gisForm.areaType) {
          if(this.state.gisForm.areaType == '行政村'){
            // params.villageId = value
            numParams.villageId = value
            locateAreaBB(value, this.state.gisPage.gisObj)
          }else{
            // params.areaId = value
            numParams.areaId = value
            locateAreaBB(value, this.state.gisPage.gisObj)
          }
        } else {
          // params.gridId = value
          numParams.gridId = value
          zoomToBigGrid(value, this.state.gisPage.gisObj)
        }
        if (this.state.gisForm.areaType != '行政村') {
          // params.villageId = null
          numParams.villageId = null
        }
        numParams.scoreType = this.state.gisForm.scoreType || 1
        numParams.mnc = this.state.gisForm.mnc
        numParams.cellType = state.network.split('+')[1] == 5 ? 5 : 4
      // 详情图表接口
      // getDataAnalysis(params).then(res => {
      //   if (res.code === 1) {
      //     commit('setEchartsData', res.data)
      //   }
      // })
      // 详情数量接口
      getUserAnalysisDetailData(numParams).then(res => {
        if (res.code === 1) {
          commit('setEchartsNumData', res.data)
        }
      })

  },
  changeInUseAttrList({commit}, attributeStr) {
    let params = {
      attributeStr: attributeStr
    }
    saveAccountAttributeTable(params).then(res => {
      if (res.code == 1) {
        this.dispatch('gisForm/getInUseAttrList')
      }
    })
  },
  getInUseAttrList({commit}, data) {
    queryAccountAttributeTable().then(res => {
      if (res.code == 1) {
        let list = res.data !== null ? res.data.attributeStr.split(';') : []
        // 去除 只有个一个数据 数组最有一个为空的情况
        if (list.length > 0 && !list[list.length - 1])
          list.length = list.length - 1
        commit('setInUseAttrList', list)
      }
    })
  },
  changeFiltrateList({commit, state},data=null) {
    if (![37,38].includes(state.activeMenu)) return
    commit('setState', {name:'tableLoding',data:true})
    api.queryCompanyInfoList({
      pageNum: state.filterPageNum,
      pageSize: 10,
      weekAdd: state.activeMenu == 38 ? true : null,
      ...data
    }).then(res => {
      commit('setFilterTotal', res.data.total)
      commit('setFilterList', {data: res.data.list, ifNav:false})
      commit('setState', {name:'oldFiltrate',data:{
        pageSize: 10,
        ...data
      }})
    })
  },
  changeFilterList({commit, state}, {ifNav = false}) {
    if (state.activeMenu == 37 || state.activeMenu == 38) return
    commit('setState', {name:'tableLoding',data: true})
    let cityId = this.state.home.userInfoVo.cityId
    let roleType = this.state.home.userInfoVo.roleType
    let {
      zoneId,
      townId,
      villageId,
      networkGridId,
      microGridId,
      areaType,
      userType,
      filterSort,
      filterPageNum,
      filterPageSize,
      dataSelectList,
      allAttrList,
      routeAreaType,
      companyId,
      dayType,
      mnc,
      keyWord,
      sourceType,
      sceneSwitch,
      buildingCoverType,
      areaLevel,
    } = this.state.gisForm
    // 查找当前列 所有的筛选项
    let copyList = JSON.parse(JSON.stringify(dataSelectList))
    let currentList = []
    allAttrList
      .filter(x => {
        if (!(this.state.home.userInfoVo.superAccount == 2 && x == 'numAll')) {
          return x
        }
      })
      .forEach(item => {
        let selectItem = copyList.find(v => v.selectName == item)
        if (selectItem) {
          if (
            selectItem.conditionalValueList[1].value == null ||
            selectItem.conditionalValueList[1].condition == null
          ) {
            selectItem.conditionalValueList = [
              selectItem.conditionalValueList[0]
            ]
          }
          currentList.push(selectItem)
        }
      })
    let baseParams = {
      ptMonth: this.state.gisForm.ptMonth,
      pageNum: filterPageNum,
      pageSize: filterPageSize,
      cityId,
      sortType: filterSort.sortType,
      sortStr: filterSort.sortStr,
      dataSelectList: currentList,
      sceneSwitch,
      userType,
      compareSwitch: buildingCoverType,
      mnc,
      areaLevel,
    }
    if (zoneId == null && companyId !== null) {
      baseParams.companyId = companyId
      baseParams.networkGridId = networkGridId
      baseParams.microGridId = microGridId
    } else {
      baseParams.zoneId = zoneId
      baseParams.townId = townId
      // baseParams.villageId = state.villageId
    }
    // if (userType) {
    //   if (userType < 5) {
    //     // 调用商机接口
    //     let params = {
    //       ...baseParams,
    //       userType
    //     }
    //     getBusinessRespnse(params).then(res => {
    //       if (res.code == 1) {
    //         commit('setFilterTotal', res.data.total)
    //         if (res.data.lastPage < filterPageNum) {
    //           commit('setFilterPageNum', res.data.lastPage)
    //         } else {
    //           commit('setFilterList', { data: res.data.list, ifNav })
    //         }
    //       }
    //     })
    //   } else if (userType == 6) {
    //     let params = {
    //       ...baseParams,
    //       keyWord,
    //       dayType,
    //       mnc
    //     }
    //     getAreaWarning(params).then(res => {
    //       if (res.code == 1) {
    //         commit('setFilterTotal', res.data.total)
    //         if (res.data.lastPage < filterPageNum) {
    //           commit('setFilterPageNum', res.data.lastPage)
    //         } else if (res.data && res.data.list) {
    //           commit('setFilterList', { data: res.data.list, ifNav })
    //           commit('setFilterPtDay', res.data.list[0].ptDay)
    //         }
    //       }
    //     })
    //   } else if (userType == 7) {
    //     let params = {
    //       ...baseParams,
    //       keyWord,
    //       dayType,
    //       mnc
    //     }
    //     areaWarningData(params).then(res => {
    //       if (res.code == 1) {
    //         commit('setFilterTotal', res.data.total)
    //         if (res.data.lastPage < filterPageNum) {
    //           commit('setFilterPageNum', res.data.lastPage)
    //         } else if (res.data && res.data.list) {
    //           commit('setFilterList', { data: res.data.list, ifNav })
    //           commit('setFilterPtDay', res.data.list[0].ptDay)
    //         }
    //       }
    //     })
    //   }
    // } else {
    // 调用普通接口
    let type = 1
    let params = {}
    if (sourceType == '6' || sourceType == '7') {
      baseParams.cellType = null
    }
    if (sourceType == '8') {
      baseParams.cellType = '4'
    }
    if (sourceType == '9') {
      baseParams.cellType = '5'
    }
    if (sourceType == '19') {
      baseParams.cellType = '4'
    }
    if (sourceType == '20') {
      baseParams.cellType = '5'
    }
    if (sourceType == '10' || sourceType == '11') {
      if (state.network.split('+')[1] == 4) {
        baseParams.cellType = '4'
      } else {
        baseParams.cellType = '5'
      }
    }
    if (baseParams.zoneId) {
      if (townId != '全部' && townId != null) {
        if (villageId != '全部' && villageId != null) {
          type = 3
        } else {
          type = routeAreaType == 5 ? 2 : 7
        }

      } else if (zoneId != '全部' && zoneId != cityId) {
        type = 2
      } else if (areaType != null) {
        if (zoneId == cityId) {
          type = 1
        } else {
          type = 3
        }
      }
      params = {
        ...baseParams,
        type: this.state.gisForm.areaType == '行政村' ? 7 : type,
        zoneId: type == 1 ? null : zoneId,
        townId:
          (townId != '全部' && townId != null) ||
          (type == 7 && townId != '全部' && townId != null)
            ? townId
            : null,
        // areaType: type == 3 ? areaType : null,
        areaType,
        villageId: villageId !== '全部' ? villageId : null,
        sourceType: window.location.pathname == '/gisPage/user' || window.location.pathname == '/gisPage/network-new' ? sourceType : 1
      }
    } else {
      if (!this.state.gisForm.ptMonth) return
      type = 6
           // 如果区域类型不为null或者微网ID不为'全部'且微网ID不为null
     if (areaType != null || (microGridId != '全部' && microGridId != null)) {
       // 如果场景切换为true
       if (sceneSwitch == true) {
         // 如果区域类型为5，则类型为2，否则为3
         type = routeAreaType == 5 ? 2 : 3
       // 如果网络网格ID不为'全部'且公司ID不等于城市ID
       } else if (networkGridId != '全部' && companyId != cityId) {
         // 类型为5
         type = 5
         // 如果微网ID不为null或者微网ID不为'全部'
         if (microGridId != null || microGridId != '全部') {
           // 类型为3
           type = 3
         }
       // 如果网络网格ID不为null
       } else if (networkGridId != null) {
         // 类型为4
         type = 4
         // 如果公司ID等于城市ID
         if (companyId == cityId) {
           // 类型为6
           type = 6
         }
       }
      }else if(companyId != cityId && companyId !='全部' && networkGridId ==null) {
        type = 4
      } else if(companyId == cityId && (companyId =='全部' || companyId ==null) && networkGridId ==null) {
        type = 6
      } else if (networkGridId != '全部' && companyId != cityId) {
        type = 5
      } else if (networkGridId != null) {
        type = 4
        if (companyId == cityId) {
          type = 6
        }
      }
      params = {
        ...baseParams,
        type: this.state.gisForm.areaType == '行政村' ? 7 : type,
        companyId: companyId,
        networkGridId: type == 4 || networkGridId == '全部' ? null : networkGridId,
        // microGridId:
        //   type == 7 && microGridId != '全部' && microGridId != null
        //     ? microGridId
        //     : null,
        microGridId: microGridId == '全部' ? null : microGridId,
        areaType,
        sourceType: window.location.pathname == '/gisPage/user' || window.location.pathname == '/gisPage/network-new' ? sourceType : 1,
      }
    }

    //宽带
  /*  if(sourceType == 14){
      params.sourceType = 1
    }*/
    // if (params.sourceType === 20 || params.sourceType === 21) return
    if (params.sourceType == 26) {
      params = {
        ...params,
        compare4gCoverType: state.compare4gCoverType,
        compare5gCoverType: state.compare5gCoverType,
        compareLocalRatioType: state.compareLocalRatioType,
        compareNotProvOutRatioType: state.compareNotProvOutRatioType,
        compareRatioType: state.compareRatioType,
      }
    }

    if (params.sourceType == 33) {
      params = {
        ...params,
        compareBroadRatioType: state.compareBroadRatioType,
        compareUserRatioType: state.compareUserRatioType
      }
    }

    //着火点sourceType区分
    if (params.sourceType == 41) {
      params = {
        ...params,
        sourceType:state.panelCellType==4?41:42
      }
    }

    state.tableParams=params

    // 列表接口
    getAttributeRespnse(params).then(res => {
      if (res.code === 1) {
        commit('setFilterTotal', res.data.total)
        if(!sceneSwitch && (sourceType == '10' || sourceType == '11' || sourceType == '19')){
          //手动分页不能走下面的傻鸟判断,完全搞不懂干啥用的
          commit('setFilterList', {data: res.data?.list||[], ifNav})
        } else{
          // if (res.data.lastPage < filterPageNum) {
          //   commit('setFilterPageNum', res.data.lastPage)
          // } else {
            commit('setFilterList', {data: res.data?.list||[], ifNav})
          // }
        }
      }
    })
    // 用户总览接口
    // let summaryParams = {
    //   zoneId: zoneId == cityId ? '全部' : zoneId,
    //   townId: townId == '全部' ? null : townId,
    //   villageId: villageId == '全部' ? null : villageId,
    //   companyId: companyId == '全部' ? '全部' : companyId,
    //   networkGridId: networkGridId == '全部' ? null : networkGridId,
    //   microGridId: microGridId == '全部' ? null : microGridId,
    //   mnc,
    //   ptMonth: this.state.gisForm.ptMonth,
    //   sourceType: window.location.pathname == '/gisPage/user' ? sourceType : 1
    // }
    // api.getUserSummaryData(summaryParams).then(res => {
    //   if (res.code == 1 && res.data) {
    //     commit('setUserAllForm', res.data)
    //   }
    // })
  },

  // 初始化加载调用  查询 网格&热力 图层
  initBoundary() {
    //------
    const {
      routeAreaType,
      network,
      mnc,
      ptMonth,
      day,
      type
    } = this.state.gisForm

    if (routeAreaType != 5) {
      queryBoundary(this, network, mnc, ptMonth, day, type, 1)
    } else {
      this.commit('gisForm/setNetwork', null)
    }
    // const allBoundaryType =
    //   routeAreaType == 5 ? 2 : this.state.gisForm.allBoundaryType
    // 加载绘制多边界的方法
    // if (this.state.gisPage.gisObj)
    //   getAllBoundaryNoClick(
    //     this.state.gisForm.ptMonth,
    //     this.state.home.userInfoVo.cityId,
    //     allBoundaryType,
    //     this.state.gisPage.gisObj,
    //     routeAreaType
    //   )
    let directLocationAreaId = localStorage.getItem('directLocationAreaId')
    if(directLocationAreaId){
      this.commit('gisForm/setQueryLocateAreaOnly', {data : directLocationAreaId})
      localStorage.setItem('directLocationAreaId', null)
    }
    if ([25,26,27].includes(this.state.gisForm.activeMenu)) {
      this.commit('gisForm/setAreaType', '全部')
    } else if ([32,33,34,35].includes(this.state.gisForm.activeMenu)) {
      this.commit('gisForm/setAreaType', this.state.gisForm.areaName)
    } else {
      // this.commit('gisForm/setUserSourceType', this.state.gisForm.sourceType) // 高亮
    }
    // 遍历 小区工参 调用方法
    queryCommunity(this.state.gisForm.communityList, this)
    // 遍历 车站营业厅
    queryCustom(this.state.gisForm.customList, 'show', this)
  },
  // 初始化加载调用  查询 经纬度 或方位角图层
  initLonAndAz() {
    queryLonAndAz(this, this.state.gisForm)
  },
  // 获取对比数据
  changeCompareDetail({ commit }, { ifNav = false }) {
    let cityId = this.state.home.userInfoVo.cityId
    let {
      companyId,
      networkGridId,
      microGridId,
      zoneId,
      townId,
      villageId,
      leftMonth,
      rightMonth,
      leftMnc,
      rightMnc
    } = this.state.gisForm
    // 查找当前列 所有的筛选项
    let baseParams = {
      leftMonth: this.state.gisForm.ptMonth,
      leftMnc: this.state.gisForm.mnc,
      rightMonth: this.state.gisForm.rMonth,
      rightMnc: this.state.gisForm.rMnc,
      cityId,
      zoneId,
      townId,
      villageId,
      companyId,
      networkGridId,
      microGridId
    }
    if (cityId == companyId || companyId == null || companyId == '全部') {
      baseParams.companyId = null
    }
    if (networkGridId == null || networkGridId == '全部') {
      baseParams.networkGridId = null
    }
    if (microGridId == null || microGridId == '全部') {
      baseParams.microGridId = null
    }
    if (cityId == zoneId || zoneId == null || zoneId == '全部') {
      baseParams.zoneId = null
    }
    if (townId == null || townId == '全部') {
      baseParams.townId = null
    }
    if (villageId == null || villageId == '全部') {
      baseParams.villageId = null
    }
    // 列表接口
    queryCompareData(baseParams).then(res => {
      if (res.code === 1) {
        commit('setCompareDetail', { data: res.data, ifNav })
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
