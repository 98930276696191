import request from '@/utils/request'

// 专题报告提交接口
export const addOrUpdateSubjectInfo = data =>
  request({
    url: '/caict/subjectInfo/addOrUpdateSubjectInfo',
    method: 'post',
    data
  })
// 专题报告基本信息接口
export const getSubjectInfo = data =>
  request({
    url: '/caict/subjectInfo/getSubjectInfo',
    method: 'post',
    data
  })
// 获取场景类型
export const getAreaTypeList = data =>
  request({
    url: '/caict/subjectInfo/getAreaTypeList',
    method: 'post',
    data: {
      cityId: data
    }
  })
// 获取左侧区县列表
export const getZoneList = data =>
  request({
    url: '/caict/subjectInfo/getZoneList',
    method: 'post',
    data: {
      cityId: data
    }
  })
// 获取左侧场景信息
export const getAreaList = data =>
  request({
    url: '/caict/subjectInfo/getAreaList',
    method: 'post',
    data
  })
// 添加专题研究场景信息
export const addSubjectArea = data =>
  request({
    url: '/caict/subjectInfo/addSubjectArea',
    method: 'post',
    data
  })
// 查询专题研究场景信息
export const getSubjectArea = data =>
  request({
    url: '/caict/subjectInfo/getSubjectArea',
    method: 'post',
    data
  })
// 获取专题报告指标配置
export const getSubjectIndicatorConfiguration = data =>
  request({
    url: '/caict/subjectInfo/getSubjectIndicatorConfiguration',
    method: 'post',
    data
  })
// 新增或修改专题报告指标配置
export const addOrUpdateSubjectIndicatorConfig = data =>
  request({
    url: '/caict/subjectInfo/addOrUpdateSubjectIndicatorConfig',
    method: 'post',
    data
  })
// 获取预警监测指标
export const getSubjectWarningList = data =>
  request({
    url: '/caict/subjectInfo/getSubjectWarningList',
    method: 'post',
    data
  })
// 保存预警监测指标
export const saveSubjectWarningList = data =>
  request({
    url: '/caict/subjectInfo/saveSubjectWarningList',
    method: 'post',
    data
  })
// 获取报告配置
export const getReportSubjectIndicatorList = data =>
  request({
    url: '/caict/subjectInfo/getReportSubjectIndicatorList',
    method: 'post',
    data
  })
// 保存报告配置
export const saveReportSubjectIndicator = data =>
  request({
    url: '/caict/subjectInfo/saveReportSubjectIndicator',
    method: 'post',
    data
  })
// 筛选报告配置
export const getIndicatorDetail = data =>
  request({
    url: '/caict/subjectInfo/getIndicatorDetail',
    method: 'post',
    data
  })
// 获取报告列表信息
export const getSubjectInfoList = data =>
  request({
    url: '/caict/subjectInfo/getSubjectInfoList',
    method: 'post',
    data
  })
// 删除专题研究信息
export const deleteSubjectInfo = data =>
  request({
    url: '/caict/subjectInfo/deleteSubjectInfo',
    method: 'post',
    data
  })
// 删除专题研究信息
export const updateSubjectInfoStatus = data =>
  request({
    url: '/caict/subjectInfo/updateSubjectInfoStatus',
    method: 'post',
    data
  })
