import axios from "axios";
import {
  Message,
  Loading
} from "element-ui";
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL, // api 的 base_url
  timeout: 600000 // 请求超时时间
});
let loading; //定义loading变量
let needLoadingRequestCount = 0; //接口请求个数。
function startLoading() {
  loading = Loading.service({
    lock: true,
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.5)"
  });
}

function endLoading() {
  //使用Element loading-close 方法
  loading.close();
}

function showFullScreenLoading() {
  if (needLoadingRequestCount === 0) {
    startLoading();
  }
  needLoadingRequestCount++;
}

function tryHideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return;

  needLoadingRequestCount--;
  if (needLoadingRequestCount === 0) {
    endLoading();
  }
}

// 请求体
service.interceptors.request.use(
  config => {
    // showFullScreenLoading();
    if (localStorage.getItem('Authorization')) {
      config.headers.Authorization = 'Bearer ' + localStorage.getItem('Authorization')
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
// 响应体

service.interceptors.response.use(
  response => {
    // console.log(response);
    if( response.config.responseType == 'blob' ) {
      tryHideFullScreenLoading();
      return response.data
    } else if (response.data) {
      if(response.data.code !=1){
        Message.error(response.data.message);
      }
      tryHideFullScreenLoading();
      return response.data
    } else {
      tryHideFullScreenLoading();
      return response;
    }
  },
  error => {
    tryHideFullScreenLoading();
    return Promise.reject(error);
  }
);

export default service;
