import zlib from 'zlib'
import {
  getCompareDataList,
  getDwDaysRoaminginGrid,
  geChuncuGrid,
  getStation,
  getLonAnalysis,
  getAZAnalysis,
  getAllBoundary,
  getAllBoundaryPageTotal,
  getAllBoundaryByPageNum,
  getGisPageInfo
} from '@/apis/gis'
// let datas = '' //数据拼接集合-字符串
/**
 *  清除 地图痕迹
 *  value:0清除所有 1清除左右栅格 2清除所有的小区 3清除大栅格
 *  4清除小区4g 5清除小区5g 6清除营业厅 7清除车站 8清除左侧栅格
 *  9清除右侧栅格 10清除站址规划
 */
export function clearMap(value, gisObj, that = null) {
  const obj = {
    data: '',
    id: new Date().getTime(),
    type: 'cmcc'
  }
  if (gisObj) {
    switch (value) {
      case 0:
        gisObj.clearArea() // 清除 绘制多区域(点击)
        gisObj.setGrids(obj)
        gisObj.clearGrids_50('left')
        gisObj.clearGrids_50('right')
        gisObj.setContrastGrids(obj)
        gisObj.hideHeatMap()
        gisObj.hideCell(1)
        gisObj.hideCell(2)
        gisObj.hideCellRight(1)
        gisObj.hideCellRight(2)
        gisObj.closeBigGrid()
        gisObj.closeContrast()
        gisObj.setLegendType(0)
        that && that.businessHallLayer && that.businessHallLayer.hide()
        that && that.stationLayer && that.stationLayer.hide()
        gisObj.clearAreaGrid() //清除 多选区域 (无点击)
        gisObj.hidePoint(1)
        gisObj.hidePoint(2)
        break
      case 1:
        gisObj.setGrids(obj)
        gisObj.setGrids_fifty(1, obj)
        gisObj.setGrids_fifty(2, obj)
        gisObj.setContrastGrids(obj)
        gisObj.hideHeatMap()
        gisObj.leftGridData = null
        break
      case 2:
        gisObj.hideCell(1)
        gisObj.hideCell(2)
        break
      case 12:
        gisObj.hideCellRight(1)
        gisObj.hideCellRight(2)
        break
      case 3:
        gisObj.closeBigGrid()
        break
      case 4:
        gisObj.hideCell(1)
        break
      case 5:
        gisObj.hideCell(2)
        break
      case 14:
        gisObj.hideCellRight(1)
        break
      case 15:
        gisObj.hideCellRight(2)
        break
      case 6:
        that && that.businessHallLayer && that.businessHallLayer.hide()
        break
      case 7:
        that && that.stationLayer && that.stationLayer.hide()
        break
      case 8:
        gisObj.hideLeftHeatMap()
        gisObj.setGrids(obj)
        gisObj.clearGrids_50('left')
        gisObj.clearGrids('left')
        gisObj.hidePoint(1)
        break
      case 9:
        gisObj.hideRightHeatMap()
        gisObj.setContrastGrids(obj)
        gisObj.clearGrids('right')
        gisObj.clearGrids_50('right')
        gisObj.hidePoint(2)
        break
      case 10:
        gisObj.closeSiteGrid()
        break
    }
  }
}

/**
 *  修改平面图/卫星图
 *  id: 1平面 2卫星
 */
export function changeMap(id, gisObj) {
  id == 2 ? gisObj.showSatelliteMap() : gisObj.showStreetMap()
}

/**
 * 点击竞对 value:true/false
 */
export function compareFn(compare, gisObj) {
  switch (compare) {
    case true:
      if (gisObj) {
        gisObj.showContrast()
      }
      break
    case false:
      if (gisObj) {
        gisObj.closeContrast()
      }
      clearMap(9, gisObj)
      break
  }
}

/**
 * 图例
 */
export function setLegend(network, gisObj) {
  if (network) {
    const type = Number(network.split('+')[0])
    gisObj.setLegendType(type)
  }  else {
    gisObj.setLegendType(0)
  }
}

/**
 * 修改图列类型
 */
export function setGridIndicatorType(network, gisObj) {
  if (network) {
    const type = Number(network.split('+')[0])
    gisObj.setGridIndicatorType(type)
  } else {
    gisObj.setGridIndicatorType(0)
  }
}

/**
 * 判断是否需要使用缓存还是新数据
 * formData 整合的form对象 包含 cityId, mnc, ptMonth, network
 * boundaryList： 保存缓存的数组 超过
 * status: 状态值 0左右，1左 2右
 * gisObj
 * boundary 当前筛选区域
 * callback 获取接口的方法回调
 * ifCommunity 是否是小区
 */
export function getGridBoundary(
  formData,
  boundaryList,
  status,
  gisObj,
  boundary,
  callback,
  ifCommunity = false,
  setData
) {
let { cityId, zoneId, mnc, ptMonth, network, roleType, companyId, networkGridId,boundaryFlag } = formData
  // 暂时不知道干嘛的
  if (network != null) {
    const networkStr = network.split('+')[0]
    if (networkStr != 3 && networkStr != 5) {
      setLegend(boundaryFlag ? "8+" : network, gisObj)
      // if (networkStr == 6 || networkStr == 7) {
      setGridIndicatorType(network, gisObj)
      // }
    } else {
      setLegend(boundaryFlag ? "8+" :null, gisObj)
    }
  } else {
    setLegend(boundaryFlag ? "8+" :null, gisObj)
    // setGridIndicatorType(null, gisObj)
  }
  // 首先清空下 图层
  if (network && ifCommunity) {
    let a = network.split('+')[1] == 4 ? 1 : 2
    delGisCommunity(a, gisObj)
  }else if (network != null && network.split('+')[0] == 12) {
    if(status != 2){
      delGisCommunity(3, gisObj)
    }
  } else if (!ifCommunity) {
    // // 绘制边界
    // if( boundary ) setBoundary( boundary, gisObj )
    // 清除 热力图 网格图层
    delGisData(status, gisObj)
  }

  if (!network) {
    return
  }

  // 判断是否存在缓存,并且缓存时间是否超时 缓存时限1小时
  let maxTime = new Date().getTime() - 60 * 60 * 1000
  let item = boundaryList.filter(v => {
    if (v.mnc == mnc && v.ptMonth == ptMonth && v.network == network) {
      return v
    }
  })
  if (item && item.length > 0 && item[0].id > maxTime && network.split('+')[0] != 12) {
  // 未调用接口 不做二层判断
    if (ifCommunity || network.split('+')[0] == 12) {
      addGisCommunity(item[0]['data'], network, gisObj)
    } else {
      let ifDefault = false
      if (zoneId == cityId && roleType == 1) {
        ifDefault = true
      }
      addGisData(
        item[0]['data'],
        { network, status },
        ifDefault,
        gisObj,
        boundary
      )
    }
  } else if (mnc != null && ptMonth != null && network != null) {
  // 调用 接口获取

    getBoundary(formData, status, callback, boundary, gisObj,setData)
  }
}

/**
 * 获取栅格和小区和区域图层 接口
 * formData 整合的form对象 包含 cityId, mnc, ptMonth, network
 * * network: 样例'1+4' ( type: 1, cellType: 4);
 * status: 状态值 0左右，1左 2右
 * callback 方法回调
 * setData 修改数据方法
 */
export async function getBoundary(
  formData,
  status,
  callback,
  boundary,
  gisObj,
  setData
) {
  let {
    cityId,
    mnc,
    ptMonth,
    network,
    type,
    day,
    townId,
    zoneId,
    routeAreaType,
    ptDay,
    companyId,
    networkGridId,
    usedNetwork,
    sourceType,
  } = formData
  const id = new Date().getTime()
  type = type ? type : network.split('+')[0]
  //小区信息
  if(type == 12){
    //后台传值30
    type = 30;
  }
  //黑点 差点 站址
  if(type == 13 || type == 14 || type == 19){
    type = 1;
  }
  let cellType = network.split('+')[1]
  // let oReq = new XMLHttpRequest()
  if (sourceType == 26) {
    cellType = ''
  } else {
    cellType = cellType ? cellType : ''
  }
  day ? day : ''
  const path =
    routeAreaType == 5 &&
    day &&
    townId &&
    townId != '全部' &&
    zoneId &&
    zoneId != '全部'
      ? '&type=' +
        type +
        '&day=' +
        day +
        '&townId=' +
        townId +
        '&zoneId=' +
        zoneId
      : '&type=' + type
  let dayTime = ptDay ? '&ptDay=' + ptDay : ''
  let gisData = {
    cityId: cityId,
    mnc: mnc,
    ptMonth: ptMonth + dayTime,
    cellType: cellType,
    id: id,
    type: type
  }
  let url2 = ''
  //cell图层过滤判断
  if(type == 30){
    zoneId = zoneId && zoneId != '全部' && zoneId != cityId ? zoneId : ''
    townId = townId && townId != '全部' ? townId : ''
    companyId = companyId ? companyId : ''
    networkGridId = networkGridId ? networkGridId : ''
    gisData = Object.assign({}, {zoneId : zoneId,townId : townId,companyId: companyId,networkGridId: networkGridId}, gisData)
    url2 = '&zoneId='+zoneId+'&townId='+townId+'&companyId='+companyId+'&networkGridId='+networkGridId
  }
  let { data: pageNum } = await getGisPageInfo(gisData)
  setData('informationType',pageNum ? null : 1)
  console.log('pageNum',pageNum);
  let oReq = []
  let datas = []
  let num = []
  for (let i = 1; i <= pageNum; i++) {
    oReq[i] = new XMLHttpRequest()
    oReq[i].open(
      'GET',
      process.env.VUE_APP_API_URL +
        '/caict/gisAnalysis/getGisLayerByStream?cityId=' +
        cityId +
        '&mnc=' +
        mnc +
        '&ptMonth=' +
        ptMonth +
        dayTime +
        '&cellType=' +
        cellType +
        '&id=' +
        id +
        url2+
        '&pageNum=' +
        i +
        path,
      true
    )
    oReq[i].responseType = 'arraybuffer'
    oReq[i].setRequestHeader(
      'Authorization',
      'Bearer ' + localStorage.getItem('Authorization')
    )
    oReq[i].onloadend = function(_event) {
      const ab = oReq[i].response
      const buff = Buffer.from(ab)
      let data = null

      try {
        data = zlib.gunzipSync(buff).toString('utf-8') + ','
        num.push(i)
      } catch (error) {
        console.error(error)
        return
      }
      if (data) {
          datas += data
      }


      let gridData = {
        id: id,
        type: mnc == '0' ? 'cmcc' : mnc == '1' ? 'cucc' : 'cmcc',
        data
      }


      let gridDatas = {
        id: id,
        type: mnc == '0' ? 'cmcc' : mnc == '1' ? 'cucc' : 'cmcc',
        data: datas
      }

      callback(formData, gridData, status, boundary)

      if (num.length == pageNum) {
        gridDatas.cacheFlag = 1
        gridDatas.lastFlag = 1
          setTimeout(() => {
            callback(formData, gridDatas, status, boundary)
          }, 400);
      }
    }
    oReq[i].send()
  }
}

/**
 * 渲染 栅格
 * status 状态 0同时开启左侧和右侧栅格
 * 1 开启左侧栅格 2开启右侧栅格
 * type 1网络 3用户 4网络质量 5漫入用户
 */
export function addGisData(
  s,
  { network, status },
  ifDefault = false,
  gisObj,
  boundary
) {
  let type = network.split('+')[0]
  console.log(123);
  // console.log('调用 gis方法',s,status,type,boundary)
  // 调用加载方法之前 先清理 旧数据
  // 这段代码会引发栅格图出现颜色断层问题，隐掉就好了，未知原因
  // delGisData(status, gisObj)
  // 除小区之外 筛选项的图层

  //热力图清除栅格图
  if(type == 3 || type == 5 || type == 7 || type == 16 || type == 24){
    delGisData(status, gisObj)
    clearMap(3, gisObj)
  }
  // 同名计时器未执行方法会被覆盖
  // *****************热力图重新绘制会有错误数据的bug 为GIS 自带bug 调用计时器可以看到 图层删除操作与bug无关， 重绘图层自己产生bug*********************************
  setTimeout(() => {
    //console.log(type, status, s, ifDefault, boundary,'11111')
    switch (status) {
      case 0:
        // 基本不可能0
        if (type == 3 || type == 5 || type == 16 || type == 24 || type == 33 || type == 7) {
            gisObj.setHeatMap(s)
            gisObj.setContrastHeatMap(s)
        } else {
          if(s.lastFlag == 1){
            gisObj.setGridsLast(s)
            gisObj.setContrastGridsLast(s)
          }else{
            gisObj.setGrids(s)
            gisObj.setContrastGrids(s)
          }
        }
        break
      case 1:
        if (type == 3 || type == 5 || type == 16 || type == 24 || type == 33 || type == 7) {
          if (ifDefault) {
              gisObj.setHeatMap(s,boundary)
            return
          }
          if (boundary) {
              gisObj.setHeatMapAndBoundary(s.data, boundary.boundary,boundary)
          } else {
              gisObj.setHeatMap(s,boundary)
          }
        // } else if (type == 7){
        //   if (ifDefault) {
        //     gisObj.setPoints(status, s.data)
        //     gisObj.showPoint(status)
        //     return
        //   }
        //   if (boundary) {
        //     gisObj.setPointAndBoundary(status, s.data, boundary.boundary)
        //     gisObj.showPoint(status)
        //   }else{
        //     gisObj.setPoints(status, s.data)
        //     gisObj.showPoint(status)
        //   }
        } else if(type == 22){
          if (ifDefault) {
            if(s.lastFlag == 1){
              gisObj.setGrids_fifty_last(status, s)
            }else{
              gisObj.setGrids_fifty(status, s)
            }
            return
          }
          if (boundary) {
            if(s.lastFlag == 1){
              gisObj.setGridsFiftyMapLast(status, s.data, boundary.boundary)
            }else{
              gisObj.setGridsFiftyMap(status, s.data, boundary.boundary)
            }
          } else {
            if(s.lastFlag == 1){
              gisObj.setGrids_fifty_last(status, s)
            }else{
              gisObj.setGrids_fifty(status, s)
            }
          }
        } else {
          if (ifDefault) {
            if(s.lastFlag == 1){
              gisObj.setGridsLast(s,boundary)
            }else{
              gisObj.setGrids(s,boundary)
            }
            return
          }
          if (boundary) {
            if(s.lastFlag == 1){
              gisObj.setGridsMapLast(s.data, boundary.boundary,boundary)
            }else{
              gisObj.setGridsMap(s.data, boundary.boundary,boundary)
            }
          } else {
            if(s.lastFlag == 1){
              gisObj.setGridsLast(s,boundary)
            }else{
              gisObj.setGrids(s,boundary)
            }
          }
        }
        break
      case 2:
        if (type == 3 || type == 5 || type == 24 || type == 7) {
          if (ifDefault) {
              gisObj.setContrastHeatMap(s)
              return
          }
          if (boundary) {
              gisObj.setContrastHeatMapAndBoundary(s.data, boundary.boundary)
          } else {
              gisObj.setContrastHeatMap(s)
          }
        // } else if (type == 7){
        //   if (ifDefault) {
        //     gisObj.setPoints(status, s.data)
        //     gisObj.showPoint(status)
        //     return
        //   }
        //   if (boundary) {
        //     gisObj.setPointAndBoundary(status, s.data, boundary.boundary)
        //     gisObj.showPoint(status)
        //   }else{
        //     gisObj.setPoints(status, s.data)
        //     gisObj.showPoint(status)
        //   }
        } else if(type == 22){
          if (ifDefault) {
            if(s.lastFlag == 1){
              gisObj.setGrids_fifty_last(status, s)
            }else{
              gisObj.setGrids_fifty(status, s)
            }
            return
          }
          if (boundary) {
            if(s.lastFlag == 1){
              gisObj.setGridsFiftyMapLast(status, s.data, boundary.boundary)
            }else{
              gisObj.setGridsFiftyMap(status, s.data, boundary.boundary)
            }
          } else {
            if(s.lastFlag == 1){
              gisObj.setGrids_fifty_last(status, s)
            }else{
              gisObj.setGrids_fifty(status, s)
            }
          }
        } else {
          if (ifDefault) {
            if(s.lastFlag == 1){
              gisObj.setContrastGridsLast(s)
            }else{
              gisObj.setContrastGrids(s)
            }
            return
          }
          if (boundary) {
              gisObj.setContrastGridsMap(s.data, boundary.boundary)
          } else {
            if(s.lastFlag == 1){
              gisObj.setContrastGridsLast(s)
            }else{
              gisObj.setContrastGrids(s)
            }
          }
        }
        break
    }
  },100)
}
/**
 * 渲染 小区
 * status 状态 0同时开启左侧和右侧栅格
 * 1 开启左侧栅格 2开启右侧栅格
 * type 2小区
 */
export function addGisCommunity(s, network, gisObj) {
  // 小区的 图层
  let cellType = network.split('+')[1]
  const a = cellType == 4 ? 1 : 2
  const b = cellType == 4 ? 2 : 1
  // console.log(a)
  if (!s) return
  delGisCommunity(a, gisObj)
  gisObj.setCells(a, s.data)
  gisObj.showCell(a)
  gisObj.hideCell(b)
}
/**
 * 渲染 小区
 * status 状态 0同时开启左侧和右侧栅格
 * 1 开启左侧栅格 2开启右侧栅格
 * type 2小区
 */
export function addGisCommunityRight(s, network, gisObj) {
  // 小区的 图层
  let cellType = network.split('+')[1]
  const a = cellType == 4 ? 1 : 2
  // console.log(a)
  if (!s) return
  delGisCommunityRight(a, gisObj)
  gisObj.setCellsRight(a, s.data)
  gisObj.showCellRight(a)
  gisObj.hideCellRight(b)
}
/**
 * 清空 栅格
 * status 状态 0同时关闭左侧和右侧栅格
 * 1 关闭左侧栅格 2关闭右侧栅格
 */
export function delGisData(status, gisObj) {
  // 清空 旧图层
  if (status == 1) {
    clearMap(8, gisObj)
  } else if (status == 2) {
    clearMap(9, gisObj)
  } else {
    clearMap(8, gisObj)
    clearMap(9, gisObj)
    clearMap(16, gisObj)
  }
}

export function delGisCustom(status, gisObj) {
  // 清空 旧图层
  clearMap(0, gisObj)
}

/**
 * 清空 小区
 * status 状态 0同时关闭4g和右5g
 * 1 关闭4G 2关闭5g
 */
export function delGisCommunity(status, gisObj) {
  // 清空 旧图层
  if (status == 1) {
    clearMap(4, gisObj)
  } else if (status == 2) {
    clearMap(5, gisObj)
  } else {
    clearMap(4, gisObj)
    clearMap(5, gisObj)
  }
}
/**
 * 清空 小区
 * status 状态 0同时关闭4g和右5g
 * 1 关闭4G 2关闭5g
 */
export function delGisCommunityRight(status, gisObj) {
  // 清空 旧图层
  if (status == 1) {
    clearMap(14, gisObj)
  } else if (status == 2) {
    clearMap(15, gisObj)
  } else {
    clearMap(14, gisObj)
    clearMap(15, gisObj)
  }
}
/**
 * 经纬度分析 接口 方位角分析 接口
 * perspective: 1经纬度分析 2方位角分析
 */
export async function getLonAndAz(formData, gisObj, callback) {
  const {
    cityId,
    mnc,
    ptMonth,
    perspective,
    stateType,
    longitudeType,
    azimuthType,
    sortType
  } = formData
  let obj = {
    cityId,
    zoneId: cityId,
    type: perspective == 1 ? longitudeType : azimuthType, //经纬度距离偏差类型
    cellType: stateType == 1 ? 0 : stateType == 2 ? 4 : 5,
    mnc,
    ptMonth
  }
  if (perspective == 1) {
    const { data } = await getLonAnalysis({ ...obj, sortType })
    gisObj.setOffsetSites(data)
    callback(data)
  } else {
    const { data } = await getAZAnalysis(obj)
    gisObj.setOffsetCells(data)
    callback(data)
  }
}

/**
 * 经纬度、方位角 定位到地图的接口
 * perspective: 1经纬度分析 2方位角分析
 */
export function getLocateCell(perspective, cId, gisObj) {
  switch (perspective) {
    case 1: //经纬度
      gisObj.locateSite(cId)
      break
    case 2: //方位角
      gisObj.locateCell(cId)
      break
  }
}

/**
 * 绘制多边界 无点击效果
 */
export async function getAllBoundaryNoClick(
  ptMonth,
  cityId,
  type,
  gisObj,
  boundaryFlag,
  routeAreaType,
) {
  gisObj.clearAreaGrid()
  if (type == null) {
    return
  } else {
    let obj = {
      cityId,
      type,
      ptMonth,
      boundaryFlag,
      pageSize: 150,
    }
    if (type == 8) {
      const { data:pageNum } = await getAllBoundaryPageTotal(obj)
      for (let i = 0; i < pageNum; i++) {
        const { data } = await getAllBoundaryByPageNum({...obj,pageNum:i+1})
        if (data) gisObj.setAreaGrid({data,total:pageNum,pageNum:i+1})
      }
    } else {
      const { data } = await getAllBoundary(obj)
      if (routeAreaType == 5) {
        if (data) {
          //  有点击效果
          gisObj.setArea(data)
          gisObj.unSelectArea()
          gisObj.clearBoundary()
        } else {
          gisObj.clearArea()
        }
      } else {
        // 无点击效果
        if (data) {
          gisObj.setAreaGrid(data)
        } else {
          return type
        }
      }
    }
  }
}

/**
 * 车站/营业厅 接口
 *
 * v 2:车站 1:营业厅
 * type: hide / show
 */
export async function getStations(custom, type, that = null) {
  let v = custom == 11 ? 1 : 2

  if (that && that.state.gisPage) {
    let gis = that.state.gisPage
    gis.busData.a ??= gis.gisObj.createCustomLayer()
    gis.busData.b ??= gis.gisObj.createCustomLayer()
    gis.busData.c ??= gis.gisObj.createCustomLayer()
    if (type === 'hide') {
      // let a = gis.gisObj.createCustomLayer()
      //   let b = gis.gisObj.createCustomLayer()
      //   let c = gis.gisObj.createCustomLayer()
      // if (v == 1) {
      // gis.businessHallLayer && gis.businessHallLayer.hide()
      // } else {
      //   gis.stationLayer && gis.stationLayer.hide()
      // }
      // clearMap(6, gisObj)
      // let b = gis.gisObj.createCustomLayer()
      // b.setData([])
      gis.busData.a?.hide()
      gis.busData.b?.hide()
      gis.busData.c?.hide()
    } else {
      const { data = [] } = await geChuncuGrid({
        cityId: that.state.home.userInfoVo.cityId
      })
      // const { data = '' } = await getStation({
      //   type: 2,
      //   cityId: that.state.home.userInfoVo.cityId,
      //   mnc:0
      // })

      if (data) {
        // if (v == 2) {
        //   // 车站
        //   gis.stationLayer.setData(data)
        //   gis.stationLayer.show()
        // } else {
        //   // 营业厅

        let aData = []
        let bData = []
        let cData = []

        data.forEach(d => {
          d['lng'] = d.lon
          if (d.mnc == '0') {
            aData.push(d)
          } else if (d.mnc == '1') {
            bData.push(d)
          } else {
            cData.push(d)
          }
        })

        gis.busData.a.setData('./assets/images/cmcc.png', { x: 0, y: 0 }, aData)
        gis.busData.b.setData('./assets/images/cucc.png', { x: 0, y: 0 }, cData)
        gis.busData.c.setData('./assets/images/ctcc.png', { x: 0, y: 0 }, bData)
        gis.busData.a.show()
        gis.busData.b.show()
        gis.busData.c.show()
      }
    }
  }
}

/**
 * 5*5栅格 接口 渲染 大栅格
 */
export async function bigGridContrast(formData, gisObj, callback) {
  const { ptMonth, cityId, mnc, network, offset, offsetSort, newIncome, status, zoneId, townId, companyId = null, networkGridId = null } = formData

  const obj = {
    ptMonth,
    cityId,
    mnc,
    cellType: network != null ? network.split('+')[1] : '4',
    compareType: offset,
    sortType: offsetSort.sortType,
    sortStr: offsetSort.sortStr,
    zoneId,
    townId,
    companyId,
    networkGridId,
  }
  if (offset != null) {
    const { data = [] } = await getCompareDataList(obj)
    if (data && data.length > 0) {
      const datas = data.map(v => {
        return {
          grid_id: v.gridId,
          cmcc: v.mobileCoverRate ? v.mobileCoverRate.toFixed(2) : '0.00',
          ctcc: v.telecomCoverRate ? v.telecomCoverRate.toFixed(2) : '0.00',
          cucc: v.unicomCoverRate ? v.unicomCoverRate.toFixed(2) : '0.00',
          cmccAvg: v.mobileRsrpAvg ? v.mobileRsrpAvg.toFixed(2) : '0.00',
          ctccAvg: v.telecomRsrpAvg ? v.telecomRsrpAvg.toFixed(2) : '0.00',
          cuccAvg: v.unicomRsrpAvg ? v.unicomRsrpAvg.toFixed(2) : '0.00',
          Type: offset
        }
      })
      callback(data)
      gisObj.setBigGrid(status,datas)
    } else {
      clearMap(3, gisObj)
    }
  } else if (newIncome != null) {
    const { data = [] } = await getDwDaysRoaminginGrid({ cityId })
    if (data && data.length > 0) {
      const datas = data.map(v => {
        return {
          grid_id: v.gridId,
          cmcc: v.mobileRatio.toFixed(2),
          ctcc: v.telecomRatio.toFixed(2),
          cucc: v.unicomRatio.toFixed(2),
          cmccnum: v.mobileNum,
          ctccnum: v.telecomNum,
          cuccnum: v.unicomNum
        }
      })

      callback(data)
      gisObj.setBigGrid(status, datas)
      console.log('获取新漫入撒点图')
    } else {
      clearMap(3, gisObj)
    }
    // 获取新漫入撒点图
  } else {
    if (gisObj) gisObj.setBigGrid([])
  }
}
/**
 * 5*5栅格 id跳转
 */
export function zoomToBigGrid(cId, gisObj,flag) {
  if (gisObj) gisObj.zoomToBigGrid(cId,flag)
}

export function zoomToMercator(cId, gisObj) {
  if (gisObj) gisObj.zoomToMercator(cId)
}

/**
 * 绘制单独区域 跳转
 */
export function setBoundary(data, gisObj) {
  if (data && gisObj) gisObj.setBoundary(data)
}

/**
 * 绘制单独区域 跳转 清除
 */
export function clearBoundary(gisObj) {
  if (gisObj) gisObj.clearBoundary()
}

/**
 * 绘制多边界 有点击效果
 */
export async function getAllBoundaryHaveClick2(formData, gisObj, routeAreaType) {
  let {
    cityId,
    zoneId,
    townId,
    mnc,
    ptMonth,
    status,
    companyId,
    networkGridId,
    microGridId,
    villageId,
    areaType,
    sourceType,
    buildingCoverType,
    xMin,
    yMin,
    xMax,
    yMax
  } = formData

    let obj = {}
    if (zoneId) {
      let townId2 = townId == '全部' ? null : townId
      obj = {
        cityId,
        zoneId,
        townId: townId2,
        areaType,
        type: areaType == '行政村' && routeAreaType == 1 ? 8 : 3,
        ptMonth,
        mnc,
        boundaryFlag: true,
        compareSwitch: buildingCoverType,
        villageId,
        xmin: xMin,
        ymin: yMin,
        xmax: xMax,
        ymax: yMax
      }
    } else {
      let companyId2 = companyId == '全部' ? null : companyId
      let microGridId2 = microGridId == '全部' ? null : microGridId
      let networkGridId2 = networkGridId == '全部' ? null : networkGridId
      obj = {
        cityId,
        companyId: companyId2,
        networkGridId: networkGridId2,
        microGridId: microGridId2,
        areaType,
        type: areaType == '行政村' && routeAreaType == 1 ? 8 : 3,
        ptMonth,
        mnc,
        boundaryFlag: true,
        compareSwitch: buildingCoverType,
        xmin: xMin,
        ymin: yMin,
        xmax: xMax,
        ymax: yMax
      }
    }
    if(sourceType == 12 || sourceType == 13){
      if(sourceType == 12){
        obj.cellType = '4'
      }else{
        obj.cellType = '5'
      }
      obj.type = 9
    }
    const { data } = await getAllBoundary(obj)
    gisObj.closeBigGrid()
    if (data) {
      gisObj.setAreaGrid2(status, data)
    } else {
      gisObj.clearAreaGrid2()
    }
}

/**
 * 绘制多边界 有点击效果
 */
export async function getAllBoundaryHaveClick(formData, gisObj,routeAreaType, state) {
  let {
    cityId,
    zoneId,
    townId,
    companyId,
    networkGridId,
    microGridId,
    villageId,
    areaType,
    ptMonth,
    sourceType,
    cellType,
    areaLevel,
  } = formData

  if (areaType == null) {
    gisObj.clearArea()
    gisObj.clearAreaBB()
  } else {
    let obj = {}
    if (zoneId) {
      let townId2 = townId == '全部' ? null : townId
      obj = {
        cityId,
        zoneId,
        townId: townId2,
        areaType,
        areaLevel,
        type: areaType == '行政村' && (routeAreaType == 1 || routeAreaType == 12) ? 8 : 3,
        ptMonth,
        boundaryFlag: true,
        villageId,
        sourceType,
        cellType,
        compare4gCoverType: state.compare4gCoverType,
        compare5gCoverType: state.compare5gCoverType,
        compareLocalRatioType: state.compareLocalRatioType,
        compareNotProvOutRatioType: state.compareNotProvOutRatioType,
        compareRatioType: state.compareRatioType,
        compareBroadRatioType: state.compareBroadRatioType,
        compareUserRatioType: state.compareUserRatioType
      }
      if(areaType == '行政村'){
        obj.boundaryFlag = false
      }
    } else {
      let companyId2 = companyId == '全部' ? null : companyId
      let microGridId2 = microGridId == '全部' ? null : microGridId
      let networkGridId2 = networkGridId == '全部' ? null : networkGridId
      obj = {
        cityId,
        companyId: companyId2,
        networkGridId: networkGridId2,
        microGridId: microGridId2,
        areaType,
        areaLevel,
        type: areaType == '行政村' && (routeAreaType == 1 || routeAreaType == 12) ? 8 : 3,
        ptMonth,
        sourceType,
        cellType,
        compare4gCoverType: state.compare4gCoverType,
        compare5gCoverType: state.compare5gCoverType,
        compareLocalRatioType: state.compareLocalRatioType,
        compareNotProvOutRatioType: state.compareNotProvOutRatioType,
        compareRatioType: state.compareRatioType,
        compareBroadRatioType: state.compareBroadRatioType,
        compareUserRatioType: state.compareUserRatioType
      }
    }
    // console.log(8888)

    if(sourceType == 10 || sourceType == 11){
      const { data } = await getAllBoundary(obj)
      gisObj.setAreaBB(data)
    } else {
      if (obj.type == 8) {
        const { data:pageNum } = await getAllBoundaryPageTotal(obj)
        for (let i = 0; i < pageNum; i++) {
          const { data } = await getAllBoundaryByPageNum({...obj,pageNum:i+1})
          if (data) {
            gisObj.setArea({data,total:pageNum,pageNum:i+1})
          } else {
            gisObj.clearArea()
            gisObj.clearAreaBB()
          }
        }
      } else {
        const { data } = await getAllBoundary(obj)
        if(data) gisObj.setArea(data)
      }
    }
  }
}

/**
 * 绘制多边界 单独区域 跳转
 */
export function locateArea(id, gisObj) {
  if (!id) {
    if (gisObj) gisObj.unSelectArea()
  } else {
    if (gisObj) gisObj.locateArea(id)
  }
}

/**
 * 绘制多边界 单独区域 跳转
 */
export function locateArea02(id, gisObj) {
  if (!id) {
    if (gisObj) gisObj.unSelectArea02()
  } else {
    if (gisObj) gisObj.locateArea02(id)
  }
}

/**
 * 绘制多边界 单独区域 跳转
 */
export function locateAreaCoor(id, lon, lat, gisObj) {
  if (!id) {
    if (gisObj) gisObj.unSelectArea02()
  } else {
    if (gisObj) gisObj.locateAreaCoor(id, lon, lat)
  }
}

export function locateAreaBB(id, gisObj) {
  if (!id) {
    if (gisObj) gisObj.unSelectAreaBB()
  } else {
    if (gisObj) gisObj.locateAreaBB(id)
  }
}
export function locateCell(type,id, gisObj) {
    if (gisObj) gisObj.locateCell(type,id)
}
