// import gisPage from '@/views/gisPage/gisMap/gisMap.vue'

const page = () =>
  import(/* webpackChunkName:'gis'*/ '@/views/gisPage/gisMap/content/index.vue')

const gis = {
  path: 'gisPage',
  name: 'Gis',
  redirect: '/gisPage/user',
  component: () => import('@/views/gisPage/gisMap/gisMap.vue'),
  children: [
    // {
    //   path: '',
    //   redirect: 'user' //默认
    // },
    {
      path: 'user',
      name: '用户分析',
      meta: {
        title: '用户分析',
        icon: '',
        routeType: 2,
        keepAlive: false
      },
      component: page
    },
    {
      path: 'changjing',
      name: '场景营销',
      meta: {
        title: '场景营销',
        icon: '',
        routeType: 2,
        keepAlive: false
      },
      component: page
    },
    {
      path: 'shangji',
      name: '商机预警',
      meta: {
        title: '商机预警',
        icon: '',
        routeType: 2,
        keepAlive: false
      },
      component: page
    },
    {
      path: 'network-new',
      name: '网络分析',
      meta: {
        title: '网络分析',
        icon: '',
        routeType: 2,
        keepAlive: false
      },
      component: page
    },
    {
      path: 'network',
      name: '弱覆盖',
      meta: {
        title: '弱覆盖',
        icon: '',
        routeType: 2,
        keepAlive: false
      },
      component: page
    },
    {
      path: 'channel-selection',
      name: '渠道选址',
      meta: {
        title: '渠道选址',
        icon: '',
        routeType: 2,
        keepAlive: false
      },
      component: page
    },
    {
      path: 'enterprise-information',
      name: '企业信息',
      meta: {
        title: '企业信息',
        icon: '',
        routeType: 2,
        keepAlive: false
      },
      component: page
    },
    {
      path: 'healthCheck',
      name: '健康检查',
      meta: {
        title: '健康检查',
        icon: '',
        routeType: 2,
        keepAlive: false
      },
      component: page
    },
    {
      path: 'thematic-research',
      name: '专题研究',
      meta: {
        title: '专题研究',
        routeType: 2,
        keepAlive: false
      },
      component: page
    },
    {
      path: 'sceneData', //场景数据 sx
      name: '场景数据',
      // component: () => import('@/views/data-analysis/scene-data/index.vue'),
      component: page,
      meta: {
        title: '场景数据',
        routeType: 4,
        keepAlive: false
      }
    }
  ]
}

export default gis
