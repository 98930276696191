import Vue from 'vue'
import VueRouter from 'vue-router'

// import Login from '@/views/login.vue'
// import LoginAdmin from '@/views/login-old.vue'
// import UserPage from '@/views/userPage.vue'
// import Home from '../views/Home.vue'
import gis from './module/gis'
import report from './module/report'
import dataMap from './module/dataMap'
import dataAnalysis from './module/data-analysis'

import api from '@/apis/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: '登录',
    component: () => import('@/views/login.vue')
  },
  {
    path: '/login-admin',
    name: '管理员登录',
    component: () => import('@/views/login-old.vue')
  },
  {
    path: '/login-free',
    name: '免登录',
    component: () => import('@/views/login-free.vue')
  },
  {
    path: '/locationArea',
    name: '场景定位',
    component: () => import('@/views/login-area.vue')
  },
  {
    path: '/gisLoginFree',
    name: 'gis免登录',
    component: () => import('@/views/timeout.vue')
  },
  {
    path: '/login-subject',
    name: '专题登录',
    component: () => import('@/views/data-analysis/data-aggregation/login-subject.vue')
  },
  {
    path: '/login-academy',
    name: '紫金研究院登录',
    component: () => import('@/views/login-academy.vue')
  },
  {
    path: '/',
    redirect: '/home',
    component: () => import('../views/Home.vue'),
    children: [
      report,
      gis,
      dataMap,
      dataAnalysis,
      {
        path: 'gisPage/intelligentOptimization',
        name: '智能优化',
        meta: {
          title: '智能优化',
          routeType: 2
        },
        component: () =>
          import(
            '@/views/gisPage/intelligentOptimization'
          )
      },
      // {
      //   path: 'gisPage/thematic-research',
      //   name: '专题研究',
      //   meta: {
      //     title: '专题研究',
      //     routeType: 2,
      //     keepAlive: false
      //   },
      //   component: () => import('@/views/gisPage/gisMap/content/index.vue')
      // },
      {
        path: 'gisPage/immersionAndOverflow',
        name: '漫入漫出',
        meta: {
          title: '漫入漫出',
          routeType: 2
        },
        component: () =>
          import(
            /* webpackChunkName:'gis'*/
            '@/views/gisPage/immersionAndOverflow'
          )
      },
      {
        path: 'collegesReport/dataSet',
        name: '高校报告',
        meta: {
          title: '高校报告',
          routeType: 2,
          keepAlive: false
        },
        component: () => import('@/views/collegesReport/dataSet')
      }
    ]
  },
  {
    path: '/userPage',
    name: '',
    component: () => import('@/views/userPage.vue')
  },
  {
    path: '/hello',
    name: '',
    component: () => import('@/views/hello')
  },
  {
    path: '/userPage-high-school',
    name: '',
    component: () => import('@/views/userPage-high-school.vue')
  },
  {
    path: '/timeout',
    name: '',
    component: () => import('@/views/timeout.vue')
  },
  {
    path: '/401',
    name: '',
    component: () => import('@/views/401.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  base: '/'
})

router.beforeEach((to, from, next) => {
  router.formPath = from
  if (
    to.path === '/login' ||
    to.path === '/userPage' ||
    to.path == '/login-admin'||
    to.path === '/userPage-high-school' ||
    to.path === '/401' ||
    to.path == '/login-free'||
    to.path == '/locationArea'||
    to.path == '/login-subject' ||
    to.path == '/login-academy' ||
    to.path == '/gisLoginFree' ||
    to.path == '/timeout' ||
    to.path == '/dataAnalysis/dataDroadcasting'
  ) {
    next()
  } else {
    if(to.path == '/gisPage/network-new' && from.path == '/'){
      next('/gisPage/user')
    }
    let Authorization = localStorage.getItem('Authorization')
    // 设置过期时间  天
    let expiresDay = 1
    let time = expiresDay * 24 * 60 * 60 * 1000
    let startDate = sessionStorage.getItem('startDate')
    let ifExpires = new Date().getTime() - time > startDate

    if (Authorization === null || Authorization === '' || ifExpires) {
      next('/login')
    } else {
      let userInfoVo = JSON.parse(localStorage.getItem('userInfoVo'))
      if (!userInfoVo.authorities) {
        next('/login')
      }
      let authorities = userInfoVo.authorities.split(';')
      //路由权限鉴定
      if (authorities.includes(to.path)) {
        sessionStorage.setItem('rout',to.name)
        if (to.name) {
          let str = '查看' + to.name + '页面'
          api.updateOperatLog({ rout: str })
        }
        next()
      } else {
        // next(authorities[0])
        next('/gisPage/user')
      }
      // next()
    }
    // next()
  }
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

// router.onError(error => {
//   const pattern = /Loading chunk (\d)+ failed/g
//   const isChunkLoadFailed = error.message.match(pattern)
//   const targetPath = router.history.pending.fullPath
//   if (isChunkLoadFailed) {
//     router.replace(targetPath)
//   }
// })

export default router
