// import AsideMenu from '@/views/report/asideMenu.vue'
const dataAnalysis = {
  path: 'dataAnalysis',
  component: () => import('@/views/report/asideMenu.vue'),
  redirect: '/dataAnalysis/dataAggregation',
  children: [
    {
      path: 'dataAggregation', //数据汇总 sx
      name: '数据汇总',
      component: () =>
        import('@/views/data-analysis/data-aggregation/index.vue'),
      meta: {
        title: '数据汇总',
        routeType: 4,
        keepAlive: false
      }
    },
    // {
    //   path: 'sceneData', //场景数据 sx
    //   name: '场景数据',
    //   component: () => import('@/views/data-analysis/scene-data/index.vue'),
    //   meta: {
    //     title: '场景数据',
    //     routeType: 4,
    //     keepAlive: false
    //   }
    // },
    {
      path: 'dataDownload', //数据下载 sx
      name: '数据下载',
      component: () => import('@/views/data-analysis/data-download/index.vue'),
      meta: {
        title: '数据下载',
        routeType: 4,
        keepAlive: false
      }
    },
    {
      path: 'dataDroadcasting', //广电简表 sx
      name: '广电简表',
      component: () => import('@/views/data-analysis/data-broadcasting/index.vue'),
      meta: {
        title: '广电简表',
        routeType: 6,
        keepAlive: false
      }
    }
  ]
}
export default dataAnalysis
