// import AsideMenu from '@/views/report/asideMenu.vue'
const report = {
  path: 'dataMap',
  component: () => import('@/views/report/asideMenu.vue'),
  redirect: '/dataMap/homeMap',
  children: [
    {
      path: 'homeMap',
      name: '大屏',
      component: () => import('@/views/dataMap/indexDome.vue'),
      meta: {
        routeType: 5,
        keepAlive: true,
      }
    },
    {
      path: 'dataMapSet',
      name: '大屏设置',
      component: () => import('@/views/dataMap/dataMapSet.vue'),
      meta: {
        routeType: 5,
        keepAlive: true,
      }
    },
    {
      path: 'huaihuaMap',
      name: '怀化大屏',
      component: () => import('@/views/dataMap/huaihuaMap.vue'),
      meta: {
        routeType: 5,
        keepAlive: true,
      }
    },
  ]
}
export default report
