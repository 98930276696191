// import AsideMenu from '@/views/report/asideMenu.vue'
const report = {
  path: 'home',
  component: () => import('@/views/report/asideMenu.vue'),
  redirect: '/home/currentPage',
  children: [
    // {
    //   path: '/',
    //   redirect: 'currentPage' //默认 sx
    // },
    {
      path: 'currentPage', //当前报告 sx
      name: '当前报告',
      component: () => import('@/views/report/reportPage.vue'),
      meta: {
        routeType: 1,
        keepAlive: true,
      }
    },
    {
      path: 'historyPage', //历史报告 sx
      name: '历史报告',
      component: () => import('@/views/report/reportPage.vue'),
      meta: {
        routeType: 1,
        keepAlive: false,
      }
    },
    {
      path: 'dataDownload', //数据下载 sx
      name: '数据下载',
      component: () => import('@/views/report/dataDownload.vue'),
      meta: {
        routeType: 1,
        keepAlive: false,
      }
    },
    {
      path: 'customPage',
      name: '自定义场景',
      component: () => import('@/views/report/customPage.vue'),
      meta: {
        routeType: 3,
        keepAlive: false,
      }
    },
    {
      path: 'upload',
      name: '上传工参',
      component: () => import('@/views/report/uploadPage.vue'),
      meta: {
        routeType: 2
      }
    },
    {
      path: 'uploadBusinessHall',
      name: '上传营业厅',
      component: () => import('@/views/report/uploadBusinessHallPage.vue'),
      meta: {
        routeType: 2
      }
    },
    // 对标城市
    {
      path: 'contrastCity',
      name: '对标城市',
      component: () => import('@/views/report/contrastCity.vue'),
      meta: {
        routeType: 1
      }
    },
    // 基础配置
    {
      path: 'baseDeploy',
      name: '基础配置',
      component: () => import('@/views/report/baseDeploy.vue'),
      meta: {
        routeType: 1
      }
    },
    {
      path: 'clusterScene',
      name: '聚类场景',
      component: () => import('@/views/report/clusterScene.vue'),
      meta: {
        routeType: 3
      }
    },
    // 邮箱配置
    {
      path: 'emailDeploy',
      name: '邮箱配置',
      component: () => import('@/views/report/emailDeploy.vue'),
      meta: {
        routeType: 3
      }
    },
  ]
}
export default report
