const state = {
  list: [1],
  aboutList: 1,
  Authorization: localStorage.getItem('Authorization')
    ? localStorage.getItem('Authorization')
    : '',
  userInfoVo: localStorage.getItem('userInfoVo')
    ? JSON.parse(localStorage.getItem('userInfoVo'))
    : {},
  startDate: sessionStorage.getItem('startDate')
    ? sessionStorage.getItem('startDate')
    : '',
  reportList: sessionStorage.getItem('reportList')
    ? sessionStorage.getItem('reportList')
    : '',
  isCollapse: false,
  menuType: 1
}

const mutations = {
  getList(state, data) {
    state.list = []
    state.list = data
  },
  getAboutList(state, data) {
    state.aboutList = data
  },
  getAuthorization(state, data) {
    state.Authorization = data
    localStorage.setItem('Authorization', data)
  },
  getuserInfoVo(state, data) {
    state.userInfoVo = data
    localStorage.setItem('userInfoVo', JSON.stringify(data))
  },
  getStartDate(state, data) {
    state.startDate = data
    sessionStorage.setItem('startDate', data)
  },
  getReportList(state, data) {
    state.reportList = data
    sessionStorage.setItem('reportList', data)
  },
  setCollapse(state, data) {
    state.isCollapse = data
  },
  setMenuType(state, data) {
    state.menuType = data
  },
  setLocationAreaId({ commit }, data) {
    localStorage.setItem('directLocationAreaId', data)
  },
  setLocationConfigType({ commit }, data) {
    localStorage.setItem('directLocationConfigType', data)
  }
}

const actions = {
  getAboutList: state => {
    return state.aboutList
  },
  getList: state => {
    return state.list
  },
  getAuthorization: state => {
    return state.Authorization
  },
  getuserInfoVo: state => {
    return state.userInfoVo
  },
  getStartDate: state => {
    return state.startDate
  },
  getReportList: state => {
    return state.reportList
  },
  setCollapse({ commit }, data) {
    commit('setCollapse', data)
  },
  setMenuType({ commit }, data) {
    commit('setMenuType', data)
  },
  setLocationAreaId({ commit }, data) {
    localStorage.setItem('directLocationAreaId', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
