import request from '@/utils/request'
// 查询企业 / 营业厅数据简报弹框设置
export const getMessageModalConfig = data =>
  request({
    url: '/caict/companyEnterprise/getMessageModalConfig',
    method: 'post',
    data
  })

//设置企业/营业厅数据简报弹框
export const setMessageModalConfig = data =>
  request({
    url: '/caict/companyEnterprise/setMessageModalConfig',
    method: 'post',
    data
  })

//企业信息较上月数据对比
export const findCompanyChangeStatistic = data =>
  request({
    url: '/caict/companyEnterprise/findCompanyChangeStatistic',
    method: 'post',
    data
  })

//渠道选址较上月对比
export const findEnterpriseChangeStatistic = data =>
  request({
    url: '/caict/companyEnterprise/findEnterpriseChangeStatistic',
    method: 'post',
    data
  })

//企业列表全量查询
export const findCompanyList = data =>
  request({
    url: '/caict/companyEnterprise/findCompanyInfoListNew',
    method: 'post',
    data
  })

//根据企业id查询企业详情信息
export const getCompanyInfoDetailThird = data =>
  request({
    url: '/caict/companyEnterprise/getCompanyInfoDetailThird',
    method: 'post',
    data
  })
// //总览数据查询
// export const getCompanyInfoCount = data =>
//   request({
//     url: '/caict/companyEnterprise/getCompanyInfoCount',
//     method: 'post',
//     data
//   })

// //企业列表全量查询
// export const findCompanyList = data =>
//   request({
//     url: '/caict/companyEnterprise/queryAreaCompanyList',
//     method: 'post',
//     data
//   })
//总览数据查询
export const getCompanyInfoCount = data =>
  request({
    url: '/caict/companyEnterprise/queryAreaCompanyCount',
    method: 'post',
    data
  })

//渠道列表全量查询
export const findEnterpriseList = data =>
  request({
    url: '/caict/companyEnterprise/findEnterpriseList',
    method: 'post',
    data
  })

//根据渠道id查询渠道详情信息
export const getEnterpriseDetailById = data =>
  request({
    url: '/caict/companyEnterprise/getEnterpriseDetailById',
    method: 'post',
    data
  })

//企业信息下载
export const exportCompanyExcel = data =>
  request({
    url: '/caict/companyEnterprise/exportCompanyExcel',
    method: 'post',
    responseType: 'blob',
    data
  })

//企业信息下载
export const exportCompanyInfoListNew = data =>
  request({
    url: '/caict/companyEnterprise/exportCompanyInfoListNew',
    method: 'post',
    responseType: 'blob',
    data
  })
  
//渠道地址下载
export const exportBusinessExcel = data =>
  request({
    url: '/caict/companyEnterprise/exportBusinessExcel',
    method: 'post',
    responseType: 'blob',
    data
  })

export const findCompanyCategories = data =>
  request({
    url: '/caict/companyEnterprise/findCompanyCategories',
    method: 'post',
    data
  })

// 行业列表
export const queryIndustryCategories = data =>
  request({
    url: `/caict/companyEnterprise/queryIndustryCategories?levelType=${data}`,
    method: 'get',
  })
// 重点公司开关
export const updateCompanyInfo = data =>
  request({
    url: '/caict/companyEnterprise/updateCompanyInfo',
    method: 'post',
    data
  })
// 重点公司开关
export const getAreaPointList = data =>
  request({
    url: '/caict/companyEnterprise/getAreaPointList',
    method: 'post',
    data
  })
// 商企区域
export const getBusinessRegionList = data =>
    request({
        url: '/caict/companyEnterprise/queryCompanyLevelCount',
        method: 'post',
        data
    })
// 营业厅区域
export const queryBusinessHallLevelCount  = data =>
request({
    url: '/caict/companyEnterprise/queryBusinessHallLevelCount',
    method: 'post',
    data
})
// 商企楼宇区域
export const queryCompanySceneLevelCount  = data =>
    request({
        url: '/caict/companyEnterprise/queryCompanySceneLevelCount ',
        method: 'post',
        data
    })
// 沿街商铺边界
export const getCompanyBoundary = data =>
  request({
    url: '/caict/companyEnterprise/getCompanyBoundary',
    method: 'post',
    data
  })
// 企业信息tab
export const getCityCompanyTypeConfig = data =>
  request({
    url: '/caict/superAccountSetting/getCityCompanyTypeConfig',
    method: 'post',
    data
  })
// 小区详情弹框数量接口
export const getGridDataByCell = data =>
  request({
    url: '/caict/userAnalysisNew/getGridDataByCell',
    method: 'post',
    data
  })

// 行政村、微格着火灭火点位图
export const getFirePoint = data =>
request({
  url: '/caict/boundaryData/getFirePoint',
  method: 'post',
  data
})

// 场景着火灭火撒点
export const getAreaFirePont = data =>
request({
  url: '/caict/boundaryData/getAreaFirePontNew',
  method: 'get',
  data
})