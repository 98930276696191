import request from '@/utils/request'

// ------------------------------gis 地图数据
// 5*5栅格竞对
export const districtCompare = params =>
  request({
    url: '/caict/areaAnalysis/compare',
    method: 'get',
    params
  })
// 场景竞对
export const sceneCompare = params =>
  request({
    url: '/caict/sceneAnalysis/compare',
    method: 'get',
    params
  })
// 微格竞对
export const microCompare = params =>
  request({
    url: '/caict/microAnalysis/compare',
    method: 'get',
    params
  })
// gis经纬度距离分析获取全部基站
export const getAllEciDistance = params =>
  request({
    url: '/caict/gisAnalysis/getAllEciDistance',
    method: 'get',
    params
  })
// gis经纬度距离分析获取偏差基站
export const getEciDistance = params =>
  request({
    url: '/caict/gisAnalysis/getEciDistance',
    method: 'get',
    params
  })
// 方位角分析获取偏差小区
export const getEciAzi = params =>
  request({
    url: '/caict/gisAnalysis/getEciAzi',
    method: 'get',
    params
  })
// 获取微格
export const getBounding = params =>
  request({
    url: '/caict/gisAnalysis/getBoundary',
    method: 'get',
    params
  })

// 获取字典数据
export const getDictionary = params =>
  request({
    url: 'caict/areaAnalysis/getZone',
    method: 'get',
    params
  })
// -----------------------------------------------用户市场分析
// 用户占比分析
export const getUARatio = params =>
  request({
    url: '/caict/userAnalysis/getUserRatio',
    method: 'get',
    params
  })
// 分页查询：微格占比低列表
export const getUALowMicro = params =>
  request({
    url: '/caict/userAnalysis/lowMicro/page',
    method: 'get',
    params
  })
// 分页查询：场景占比低列表
export const getUALowScene = params =>
  request({
    url: '/caict/userAnalysis/lowScene/page',
    method: 'get',
    params
  })
// 查询：用户与网络关联分析
export const getUserAnalysis = params =>
  request({
    url: '/caict/userAnalysis/analysis',
    method: 'get',
    params
  })
// 用户占比趋势分析
export const getUARatioTrend = params =>
  request({
    url: '/caict/userAnalysis/getUserRatioTrend',
    method: 'get',
    params
  })
// -----------------------------------------------区县网格分析
// 获取无线资源小区数统计
export const getCountCell = params =>
  request({
    url: '/caict/areaAnalysis/countCell',
    method: 'get',
    params
  })
// 分页查询：弱覆盖小区列表
export const getWeakArea = params =>
  request({
    url: '/caict/areaAnalysis/weakArea/page',
    method: 'get',
    params
  })
// 查询：用户占比趋势分析
export const getUserRatioTrend = params =>
  request({
    url: '/caict/areaAnalysis/getUserRatioTrend',
    method: 'get',
    params
  })
// 查询：用户占比分析
export const getUserRatio = params =>
  request({
    url: '/caict/areaAnalysis/getUserRatio',
    method: 'get',
    params
  })

// ------------------------------------------------微格分析
// 微格名称模糊查询
export const getMicroName = params =>
  request({
    url: '/caict/microAnalysis/getMicroName',
    method: 'get',
    params
  })
// 查询：按ID查询指定微格覆盖率
export const findByTownId = params =>
  request({
    url: '/caict/microAnalysis/findByTownId',
    method: 'get',
    params
  })
// 分页查询：弱覆盖微格列表
export const getWeakMicro = params =>
  request({
    url: '/caict/microAnalysis/weakMicro/page',
    method: 'get',
    params
  })
// 查询：覆盖竞对分析
export const getCoverAnalysis = params =>
  request({
    url: '/caict/microAnalysis/coverAnalysis',
    method: 'get',
    params
  })
// 查询：覆盖竞对分析
export const getLowUserMicro = params =>
  request({
    url: '/caict/microAnalysis/lowUserMicro/page',
    method: 'get',
    params
  })
// 微格用户占比趋势分析
export const getMicroUserRatioTrend = params =>
  request({
    url: '/caict/microAnalysis/getUserRatioTrend',
    method: 'get',
    params
  })
// 微格用户占比分析
export const getMicroUserRatio = params =>
  request({
    url: '/caict/microAnalysis/getUserRatio',
    method: 'get',
    params
  })

// -------------------------------------------------场景分析
// // 查询：按ID查询指定场景覆盖率
// export const findSceneAnalysisByAreaId = params =>
//   request({
//     url: '/caict/sceneAnalysis/findSceneAnalysisByAreaId',
//     method: 'get',
//     params
//   })
// // 分页查询：弱覆盖场景列表
// export const getWeakScene = params =>
//   request({
//     url: '/caict/sceneAnalysis/weakScene/page',
//     method: 'get',
//     params
//   })
// // 查询：覆盖竞对分析
// export const getSceneAnalysis = params =>
//   request({
//     url: '/caict/sceneAnalysis/coverAnalysis',
//     method: 'get',
//     params
//   })
// // 分页查询：弱场景竞对分析列表
// export const getWeakSceneAnalysis = params =>
//   request({
//     url: '/caict/sceneAnalysis/weakSceneAnalysis/page',
//     method: 'get',
//     params
//   })
// // 场景用户趋势
// export const getSceneUserRatioTrend = params =>
//   request({
//     url: '/caict/sceneAnalysis/getUserRatioTrend',
//     method: 'get',
//     params
//   })
// // 微格用户占比分析
// export const getSceneUserRatio = params =>
//   request({
//     url: '/caict/sceneAnalysis/getUserRatio',
//     method: 'get',
//     params
//   })
// // 查询：竞对分析-弱场景总数统计
// export const getCountWeak = params =>
//   request({
//     url: '/caict/sceneAnalysis/countWeakSceneAnalysis',
//     method: 'get',
//     params
//   })
// ----------------------------------------方位角
// 方位角偏差分析
export const getAZAnalysis = params =>
  request({
    url: '/caict/netWork/getAZAnalysis',
    method: 'get',
    params
  })
// 经纬度分析
export const getLonAnalysis = params =>
  request({
    url: '/caict/netWork/getLonAnalysis',
    method: 'get',
    params
  })

// ---------------------  第二版数据分析模块  ----------------
// 用户占比趋势分析
// export const getDataAnalysis = data =>
//   request({
//     url: '/caict/userAnalysis/getDataAnalysis',
//     method: 'post',
//     data
//   })
// 用户占比趋势分析  新版本
export const getDataAnalysis = data =>
  request({
    url: '/caict/userAnalysis/getDataAnalysisNew',
    method: 'post',
    data
  })
// 详情弹框数量接口 新版本
export const getUserAnalysisDetailData = data =>
  request({
    url: '/caict/userAnalysisNew/getUserAnalysisDetailData',
    method: 'post',
    data
  })
// 广电用户占比趋势分析
export const getBroadcastInfoThread = data =>
  request({
    url: '/caict/boundaryData/getBroadcastInfoThread',
    method: 'post',
    data
  })
// 行政区
export const getUserNetWorkAnalysis = data =>
  request({
    url: '/caict/userAnalysis/getUserNetWorkAnalysis',
    method: 'post',
    data
  })

// // 栅格
// export const getGisLayerByStream = params =>
//   request({
//     url: '/caict/gisAnalysis/getGisLayerByStream',
//     method: 'get',
//     params
//   })
// type类型 1：车站 2：营业厅
export const getStation = data =>
  request({
    url: '/caict/reference/getStationBusinessHallList',
    method: 'post',
    data
  })

export const getCompareDataList = data =>
  request({
    url: '/caict/areaAnalysis/compareDataList',
    method: 'post',
    data
  })
  // 春促新增栅格撒点
export const getDwDaysRoaminginGrid = data =>
  request({
    url: '/caict/areaAnalysis/getDwDaysRoaminginGrid',
    method: 'post',
    data
  })
  // 春促新增点位撒点
export const geChuncuGrid = data =>
  request({
    url: '/caict/areaAnalysis/geChuncuGrid',
    method: 'post',
    data
  })
// 0319
// 用户占比table
export const getUserNetWorkAnalysisNew = data =>
  request({
    url: '/caict/userAnalysis/getUserNetWorkAnalysisNew',
    method: 'post',
    data
  })
// 关联分析 table
export const getCorrelationAnalysis = data =>
  request({
    url: '/caict/userAnalysis/getCorrelationAnalysis',
    method: 'post',
    data
  })

// 漫入漫出 数据面板数据
export const queryUserInAndOutAnalysis = data =>
  request({
    url: '/caict/slowInAndOut/queryUserInAndOutAnalysis',
    method: 'post',
    data
  })
// 春促 数据面板数据
export const getNewRoamingUserAnalysis = data =>
  request({
    url: '/caict/subjectInfo/getNewRoamingUserAnalysis',
    method: 'post',
    data
  })

// 获取地图全量数据
export const getRoaminginAndOut = data =>
  request({
    url: '/caict/slowInAndOut/getRoaminginAndOut',
    method: 'post',
    data
  })

// 获取多个边界
export const getAllBoundary = data =>
  request({
    url: '/caict/boundaryData/getAllBoundary',
    method: 'post',
    data
  })

// 获取边界页数
export const getAllBoundaryPageTotal  = data =>
request({
  url: '/caict/boundaryData/getAllBoundaryPageTotal ',
  method: 'post',
  data
})

// 页数获取边界
export const getAllBoundaryByPageNum   = data =>
request({
  url: '/caict/boundaryData/getAllBoundaryByPageNum ',
  method: 'post',
  data
})
// 获取 右侧筛选项列表
// export const getAttributeRespnse = data =>
//   request({
//     url: '/caict/boundaryData/getAttributeRespnse',
//     method: 'post',
//     data
//   })
// 新版本获取 右侧筛选项列表
export const getAttributeRespnse = data =>
  request({
    url: '/caict/boundaryData/getAttributeRespnseNew',
    method: 'post',
    data
  })

// 获取 右侧筛选项列表（商机）
export const getBusinessRespnse = data =>
  request({
    url: '/caict/boundaryData/getBusinessRespnse',
    method: 'post',
    data
  })
// 获取 右侧筛选项列表（场景预警）
export const getAreaWarning = data =>
  request({
    url: '/caict/boundaryData/areaWarning',
    method: 'post',
    data
  })
// 场景预警趋势图数据
export const areaWarningThrend = data =>
  request({
    url: '/caict/boundaryData/areaWarningThrend',
    method: 'post',
    data
  })
// 预警记录表
export const areaWarningData = data =>
  request({
    url: '/caict/boundaryData/areaWarningData',
    method: 'post',
    data
  })

// 获取 右侧筛选项列表显示项
export const queryAccountAttributeTable = data =>
  request({
    url: '/caict/boundaryData/queryAccountAttributeTable',
    method: 'post',
    data
  })

// 获取 右侧筛选项列表显示项
export const saveAccountAttributeTable = data =>
  request({
    url: '/caict/boundaryData/saveAccountAttributeTable',
    method: 'post',
    data
  })

// 获取 右侧筛选项列表 下载文件
// export const exportAttributeRespnse = data =>
//   request({
//     url: '/caict/boundaryData/exportAttributeRespnse',
//     method: 'post',
//     responseType: 'blob',
//     data
//   })
// 新版本 获取 右侧筛选项列表 下载文件
export const exportAttributeRespnse = data =>
  request({
    url: '/caict/boundaryData/exportAttributeRespnseNew',
    method: 'post',
    responseType: 'blob',
    data
  })

  export const exportCompanyInfoList = data =>
  request({
    url: '/caict/companyEnterprise/exportCompanyInfoList',
    method: 'post',
    responseType: 'blob',
    data
  })
/**
 * 获取春促gis图层
 * @cityId 地市id
 * @ptMonth 月份
 * @mnc 运营商  0：移动 1：联通 11：电信
 * @zoneId 区县id
 * @townId  乡镇街道id
 * @day
 * @type  春促栅格类型 11: 异地异网; 12: 漫入用户;13:异网用户; 14：5G用户； 15.总用户
 */
export const getGisGridStream = data =>
  request({
    url: '/caict/springPromotion/getGisGridStream',
    method: 'post',
    data
  })

/**
 * 查询用户趋势
 * @cityId 地市id
 * @townId  街道id
 * @type  12: 漫入用户; 14：5G用户； 15.总用户
 */
export const getUserTrendList = params =>
  request({
    url: '/caict/springPromotion/getUserTrendList',
    method: 'get',
    params
  })

/**
 * 获取日期横坐标
 */
export const getDayDetailList = () =>
  request({
    url: '/caict/springPromotion/getDayDetailList',
    method: 'get'
  })
/**
 * 线上发卡分析
 */
export const getSendCardOnline = data =>
  request({
    url: '/caict/boundaryData/getSendCardOnline',
    method: 'post',
    data
  })
/**
 * 日新增分析
 */
export const getDailyAddList = data =>
  request({
    url: '/caict/boundaryData/getDailyAddList',
    method: 'post',
    data
  })
/**
 * 查看日期限制
 */
export const getDayMinAndMax = data =>
  request({
    url: '/caict/boundaryData/getDayMinAndMax',
    method: 'post',
    data
  })
/**
 * 查询总页数
 */
export const getGisPageInfo = data =>
  request({
    url: '/caict/gisAnalysis/getGisPageInfo',
    method: 'post',
    data
  })
/**
 * 查询广电列表
 */
export const getBroadcastList = data =>
  request({
    url: '/caict/boundaryData/getBroadcastList',
    method: 'post',
    data
  })
/**
 * 查询广电份额
 */
export const getBroadcastInfo = data =>
  request({
    url: '/caict/boundaryData/getBroadcastInfo',
    method: 'post',
    data
  })
// 小区详情弹框数量接口
export const getGridDataByCell = data =>
    request({
        url: '/caict/userAnalysisNew/getGridDataByCell',
        method: 'post',
        data
    })

    //获取对比数据
export const queryCompareData = data =>
request({
 url: '/caict/userAnalysisNew/queryCompareData',
 method: 'post',
 data: data
})